<script setup lang="ts">
import { TextSectionPageData } from '@/page/domain/MetalPlanPageData';
import ParagraphWithIcon from '@/page/infrastructure/components/ParagraphWithIcon.vue';
import { computed } from 'vue';

const props = defineProps<{
  pageData: Partial<TextSectionPageData>;
}>();

const panelClass = computed(() => {
  switch (props.pageData.contentMode) {
    case 'twoColumns':
      return 'flex-col text-center';
    case 'right':
      return 'flex-col lg:flex-row';
    default:
      return 'flex-col items-center';
  }
});

const subPanelClass = computed(() => {
  switch (props.pageData.contentMode) {
    case 'twoColumns':
      return 'flex-col mt-6 space-y-4';
    case 'right':
      return 'flex-col space-y-10 mt-6 lg:mt-0';
    default:
      return 'flex-col lg:flex-row w-full space-y-10 lg:space-y-0 space-x-0 lg:space-x-10 mt-6';
  }
});

</script>
<template>
  <div class="w-full py-16" :class="pageData.backgroundColor ? pageData.backgroundColor : 'bg-slate-100'" >
    <div class="flex flex-1 max-w-7xl mx-auto px-6 lg:px-3" :class="panelClass">
      <div class="flex flex-1 flex-col space-y-2">
        <h3 class="font-bold" v-html="pageData.title"></h3>
        <div class="flex w-full flex-col" v-html="pageData.subtitle"></div>
        <div class="flex w-full flex-col" v-html="pageData.content"></div>
      </div>
      <div class="flex flex-1 flex-col lg:flex-row lg:space-x-10">
        <div class="flex flex-1" :class="subPanelClass">
          <ParagraphWithIcon v-for="(subContent, i) in pageData.subContent?.slice(0,3)" :key="i" :text="subContent" icon="chevron-right" />
        </div>
        <div v-if="pageData.contentMode === 'twoColumns'" class="flex flex-1" :class="subPanelClass">
          <ParagraphWithIcon v-for="(subContent, i) in pageData.subContent?.slice(3,7)" :key="i" :text="subContent" icon="chevron-right" />
        </div>
      </div>
    </div>
  </div>
</template>
