<script setup lang="ts">
import { ListContent } from '@/page/domain/CarrierPageData';
import { computed } from 'vue';

const props = defineProps<{
  listContent: Partial<ListContent>;
}>();

const slicedContent = computed(() => props.listContent.listCut && props.listContent.list ? cutInArray(props.listContent.list, props.listContent.listCut) : [props.listContent.list || []]);
const listWrap = computed(() => props.listContent.listNoWrapResponsive ? 'flex-row' : 'flex-col sm:flex-row');
const cutInArray = (array: Array<string>, size: number): Array<Array<string>> => {
  const result: Array<Array<string>> = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }

  return result;
};
</script>
<template>
  <div class="flex w-full" :class="listWrap">
    <div v-for="(column, index) in slicedContent" :key="index" class="flex flex-1 flex-col">
      <div v-for="(content, index) in column" :key="index" v-html="content" class="flex flex-1 flex-row"></div>
    </div>
  </div>
</template>
