<script setup lang="ts">
import { computed } from 'vue';
import { PrimaryButton } from '@hfc/ui-core';
import { ImageSection } from '@/page/domain/CarrierPageData';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl } = UseUrlHelper();

const props = defineProps<{
  content: Partial<ImageSection>;
}>();

const imageClass = computed(() => props.content.imageClass ? props.content.imageClass : 'min-w-[1000px] left-1/2 transform -translate-x-1/2');
</script>
<template>
  <div class="w-full overflow-hidden" :class="content.bgColor">
    <div v-if="content.imageUrl" class="relative">
      <img class="absolute" :class="imageClass" :src="getCdnUrl(content.imageUrl)">
    </div>
    <div class="relative flex w-full flex-col space-y-2 lg:space-y-6 px-4 sm:px-8 md:px-16 py-6 md:py-12 lg:py-20" :class="[content.justifyContent]">
      <slot name="content" :content="content">
        <div :class="[content.contentWidth, content.textDirection, content.extraSpace]">
          <h2 class="text-lg md:text-2xl lg:text-4xl" v-html="content.title" :class="content.titleColor"></h2>
          <div v-if="content.titleSeparator" class="flex my-5 h-1 w-20 rounded-full" :class="content.titleSeparatorColor ? content.titleSeparatorColor : 'bg-teal-400'"></div>
          <h2 v-if="content.content" class="mt-3 text-xs md:text-lg lg:text-xl" v-html="content.content" :class="content.contentColor"></h2>
          <div v-if="content.redirectButtonUrl" class="flex mt-10">
            <PrimaryButton class="text-sm lg:text-md" size="lg" :href="content.redirectButtonUrl">{{ content.redirectButtonText }}</PrimaryButton>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>
