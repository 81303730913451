<script setup lang="ts">
import { ref } from 'vue';
import { PopoverButton, PopoverPanel, Popover } from '@headlessui/vue';
import BrandingLogos from '@/shared/infrastructure/components/heading/BrandingLogos.vue';
import HeadingNavItem from '@/shared/infrastructure/components/heading/HeadingNavItem.vue';
import { MenuItem } from '@/shared/domain/MenuItem';

defineProps<{
  items: Array<MenuItem>;
}>();

const popoverOpen = ref(false);

const toggleMenuOpen = (): void => {
  const body = document.querySelector('body');
  body?.classList.toggle('heading-mobile-menu-opened', popoverOpen.value);
};

const toggleMenu = (): void => {
  popoverOpen.value = !popoverOpen.value;
  toggleMenuOpen();
};
</script>
<template>
  <Popover class="md:hidden pl-2 flex items-center">
    <div class="md:hidden">
      <PopoverButton @click="toggleMenuOpen" class="rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
        <span class="sr-only" @click="toggleMenu">Open menu</span>
        <hfc-icon class="flex text-3xl dark:text-white text-blue" @click="toggleMenu" icon="bars" />
      </PopoverButton>
    </div>
    <div v-show="popoverOpen">
      <PopoverPanel :static="true" focus as="div" class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-10 h-screen overflow-hidden">
        <div class="h-full rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-y-2 divide-gray-50 bg-gradient-to-r from-blue-900 to-blue-500 overflow-hidden">
          <div class="h-full pb-6 overflow-scroll px-5 pt-5">
            <div class="flex items-center justify-between">
              <BrandingLogos />
              <div class="-mr-2 -mt-2">
                <PopoverButton @click="toggleMenuOpen" class="rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span class="sr-only" @click="toggleMenu">Close menu</span>
                  <hfc-icon class="flex text-3xl text-white" @click="toggleMenu" icon="times" />
                </PopoverButton>
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid gap-y-8">
                <HeadingNavItem v-for="  menuItem   in   items  " :key="menuItem.label" :item="menuItem" :mobile="true" />
              </nav>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </div>
  </Popover>
</template>
