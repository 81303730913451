import { MetalPlanData } from '@/page/domain/MetalPlanData';
import { MetalPlanRepository } from '@/page/domain/MetalPlanRepository';
import { SfApiResponse } from '@hfc/ui-core';

export class MetalPlanMemory implements MetalPlanRepository {

  private readonly metalPlans: Array < MetalPlanData > = [
    {
      'key': 'bronze_60',
      'primary': true,
      'priceRating': 100,
      'popularityRating': 80,
      'tierNumber': 60,
      'tierName': 'Bronze',
      'coverage': 60,
      'deductible': '$5,800 Individual $11.600 Family',
      'doctorVisits': '$60 (PCP)',
      'genericDrugs': '$19',
      'hospital': '40% after deductible up to OOP Max',
      'icon': '/assets/img/plans/bronze.webp',
      'oopMax': '$8,850 Individual $17,700 Family',
      'medical': {
        'coverage': 70,
      }
    },
    {
      'key': 'bronze_60_hsa',
      'primary': false,
      'priceRating': 25,
      'popularityRating': 80,
      'tierSuffix': 'HSA',
      'tierNumber': 60,
      'tierName': 'Bronze',
      'coverage': 60,
      'deductible': '$6,650 Individual $13,300 Family',
      'doctorVisits': 'Preventative are FREE <br> All other services are full price until OOP max',
      'genericDrugs': 'Full price until OOP max',
      'hospital': 'Full price until OOP max',
      'icon': '/assets/img/plans/bronze.webp',
      'oopMax': '$6,650 Individual $13,300 Family',
      'medical': {
        'coverage': 70,
      }
    },
    {
      'key': 'silver_70',
      'primary': true,
      'priceRating': 100,
      'popularityRating': 100,
      'tierNumber': 70,
      'tierName': 'Silver',
      'coverage': 70,
      'deductible': '$5.400 Individual $10.800 Family',
      'doctorVisits': '$50 - $90',
      'genericDrugs': '$18',
      'hospital': '30% after deductible up to OOP Max',
      'icon': '/assets/img/plans/silver.webp',
      'oopMax': '$8.700 Individual $17.400 Family',
      'medical': {
        'coverage': 70,
      }
    },
    {
      'key': 'silver_73',
      'primary': false,
      'priceRating': 50,
      'popularityRating': 100,
      'tierNumber': 73,
      'tierName': 'Silver',
      'coverage': 70,
      'deductible': 'None',
      'doctorVisits': '$35 - $85',
      'genericDrugs': '$15',
      'hospital': '30% up to OOP Max after deductible',
      'icon': '/assets/img/plans/silver.webp',
      'oopMax': '$6.100 Individual $12.200 Family',
      'medical': {
        'coverage': 70,
      }
    },
    {
      'key': 'silver_87',
      'primary': false,
      'priceRating': 100,
      'popularityRating': 100,
      'tierNumber': 87,
      'tierName': 'Silver',
      'coverage': 70,
      'deductible': 'None',
      'doctorVisits': '$15 - $25',
      'genericDrugs': '$5',
      'hospital': '20% up to OOP Max after deductible',
      'icon': '/assets/img/plans/silver.webp',
      'oopMax': '$3.000 Individual $6.000 Family',
      'medical': {
        'coverage': 70,
      }
    },
    {
      'key': 'silver_94',
      'primary': false,
      'priceRating': 50,
      'popularityRating': 100,
      'tierNumber': 94,
      'tierName': 'Silver',
      'coverage': 70,
      'deductible': 'None',
      'doctorVisits': '$5 - $8',
      'genericDrugs': '$3',
      'hospital': '10%',
      'icon': '/assets/img/plans/silver.webp',
      'oopMax': '$1.150 Individual $2.300 Family',
      'medical': {
        'coverage': 70,
      }
    },
    {
      'key': 'gold_80',
      'primary': true,
      'priceRating': 100,
      'popularityRating': 20,
      'tierNumber': 80,
      'tierName': 'Gold',
      'coverage': 80,
      'deductible': 'None',
      'doctorVisits': '$35 - $65',
      'genericDrugs': '$15',
      'hospital': '$350 per day up to 5 days (most HMO\'s) 30% (PPO, EPO, and some HMO\'s)',
      'icon': '/assets/img/plans/gold.webp',
      'oopMax': '$8.700 Individual $17.400 Family',
      'medical': {
        'coverage': 80,
      }
    },
    {
      'key': 'platinum_90',
      'primary': true,
      'priceRating': 100,
      'popularityRating': 40,
      'tierNumber': 90,
      'tierName': 'Platinum',
      'coverage': 90,
      'deductible': 'None',
      'doctorVisits': '$15 - $30',
      'genericDrugs': '$7',
      'hospital': '$225 per day up to 5 days (most HMOs) 10% (PPO, EPO and some HMOs)',
      'icon': '/assets/img/plans/platinum.webp',
      'oopMax': '$4.500 Individual $9.000 Family',
      'medical': {
        'coverage': 90,
      }
    },
    {
      'key': 'catastrophic',
      'primary': false,
      'tierName': 'Catastrophic',
      'coverage': 60,
      'icon': '/assets/img/plans/catastrophic.webp',
    }
  ];

  public async getPlans (): Promise<SfApiResponse<Array<MetalPlanData>>> {
    return {
      success: true,
      data: this.metalPlans
    };
  }

}
