import { UseCase } from '@/shared/application/UseCase';
import { ServicesApiResponse } from '@hfc/ui-core';
import { FooterItem } from '@/shared/domain/FooterItem';
import { FooterRepository } from '@/shared/domain/FooterRepository';

export class GetFooterItemsUseCase implements UseCase<string, ServicesApiResponse<Array<FooterItem>>> {

  private repository: FooterRepository;

  public constructor (repository: FooterRepository) {
    this.repository = repository;
  }

  public async run (): Promise<ServicesApiResponse<Array<FooterItem>>> {
    return await this.repository.getFooterItems();
  }

}
