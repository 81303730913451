import { CarrierPage } from '@/page/domain/CarrierPage';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl } = UseUrlHelper();

export const blueShieldPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: 'Blue Shield <br> Health Insurance Coverage',
    content: 'Find the Lowest Prices Available on Blue Shield Insurance Plans or Get Discounts Through Our Covered California Options'
  },
  aboutInfo: {
    visible: true,
    title: 'About Blue Shield of California',
    content: `<p>Are you a California resident looking for an affordable but quality health care plan? Blue Shield of California offers individuals, families and businesses many coverage options and an extensive network of medical professionals and hospitals in California and beyond. In addition to the standard benefits, members can get supplemental Medicare, dental, vision and life insurance coverage.</p><br>
    <p>Health for California Insurance Center can help you find coverage that suits your needs. We will assess your situation and guide you through the process of getting a health care plan for yourself, your family or your employees. Learn more about the features and benefits of Blue Shield below. and <a class="underline" href="${getBaseUrl('/contact-us')}">contact our team</a> at Health for California for more information.</p>`
  },
  sectionInfo: {
    visible: true,
    title: 'Blue Shield California and National Coverage',
    textDirection: 'text-left',
    content: `<p>Blue Shield of California is a nonprofit health insurance provider founded in 1939 in San Francisco. The organization is now headquartered in Oakland, California, with over 4.8 million members. While focusing primarily on individuals, families and businesses within the state. Blue Shield is part of a broader network of Blue Cross Blue Shield (BCBS) companies operating nationwide. Through <a class="underline" href="https://www.blueshieldca.com/bsca/bsc/wcm/connect/provider/provider_content_en/guidelines_resources/bluecard" target="_blank">initiatives like the BlueCard Program</a>. the organization links Blue plans nationwide and abroad via an electronic network for reimbursement and claims processing.</p><br>
    <p>In 2017. Blue Shield introduced the <a class="underline" href="https://www.blueshieldca.com/en/home/shop-plans/trio-hmo-network" target="_blank">Trio Accountable Care Organization</a> (ACO) plans to Covered California. These ACO health maintenance organizations (HMOs) use an integrated network delivery model across specialties and hospitals to improve quality patient outcomes with overall efficiency. The Trio network extends throughout select California counties. Blue Shield believes every Californian deserves quality health care. comprehensive benefits and affordable plans.</p>`,
    imageUrl: '/assets/img/carriers/blueshield-doctor-and-old-man.webp'
  },
  extraSection1: {
    visible: true,
    title: 'What Are the Benefits of Blue Shield?',
    bgColor: 'bg-slate-100',
  },
  extraSection2: {
    visible: true,
    title: 'Blue Shield of California Health Insurance Plans',
    imageSection: {
      contentWidth: 'w-2/3 md:w-3/5 lg:w-2/5',
      justifyContent: 'items-end',
      textDirection: 'text-left',
      imageUrl: '/assets/img/carriers/blueshield-benefits-banner.webp',
    },
  },
  networkInfo: {
    visible: true,
    title: 'Additional Blue Shield Health Insurance Coverage',
    content: '<p>Beyond the standard health plans, Blue Shield members can obtain additional coverage for Medicare, vision, dental and life insurance:</p>',
    textDirection: 'text-left',
    subContent: [{
      title: 'Medicare Coverage',
      content: `<p>Medicare has limited coverage. If you have needs beyond what you can receive from Medicare, you may be able to access additional coverage with Blue Shield Medicare. Blue Shield offers multiple coverage options to help you select what works best.</p><br>
        <p>The Medicare prescription drug plans offered through Blue Shield cover a long list of medications, and the pharmacy network spans <a class="underline" href="https://www.blueshieldca.com/medicare" target="_blank">more than 6.000 pharmacies</a> in California at major chains and retailers. With a Medicare Supplement plan, you may also have access to wellness and fitness program discounts</p>`
    },
    {
      title: 'Vision Plans',
      content: `<p>Beyond eating healthy and exercising, you may also want a vision plan to ensure your eyes receive appropriate care. With Blue Shield, you'll have
        access to an extensive network of eye care professionals in California. A vision plan with Blue Shield also offers you access to online providers.
        major retail chains and eye care boutiques. You can choose from various plans to get proper care</p>`
    },
    {
      title: 'Dental Plans',
      content: '<p>Does your health care plan include dental benefits? If not. you may want to consider switching to Blue Shield. With a dental plan through Blue Shield, you\'ll receive access to some of the largest dental provider networks in California. Blue Shield also allows you to protect your smile with free X-rays and cleanings. For a dental plan you can depend on. Blue Shield can provide.</p>'
    },
    {
      title: 'Individual Term Life Insurance and AD&D Coverage',
      content: `<p>Life insurance is crucial for anyone with dependents or loved ones who rely on you financially. You can purchase a term life insurance plan with Blue Shield to protect your children or spouse from the unexpected. You can buy the life insurance with your medical plan or as a stand-alone policy.</p><br>
        <p>When you get life insurance through Blue Shield of California, you may also want to obtain accidental death and dismemberment (AD&D) coverage. This coverage can provide you with further protection during an accidental death or injury. You can <a class="underline" href="https://www.blueshieldca.com/en/broker/ifp/life" target="_blank">select from several coverage amounts</a> ranging from $10,000-$100,000 at low monthly rates.</p><br>
        <p>Nearly every Californian can find the health care plan they need with Blue Shield of California. With Bronze, Silver, Gold and Platinum plans to choose from, you can easily find one with the right price and coverage.</p>`
    },
    ],
    bgColor: 'bg-sky-50',
  },
  extraSection3: {
    visible: true,
    title: 'Blue Shield Insurance Quotes',
    content: '<p>Now that you know all the plans and benefits of Blue Shield of California, you may be considering obtaining a health care plan. But first, you want to know how much your health insurance will cost. With Health for California, you can get the information you need for free, including:</p><br>',
    contentDirection: 'right',
    textDirection: 'text-left',
    listContent: {
      list: [
        '<span class="text-blue-500 font-semibold mr-2">1.</span>The name of the carrier',
        '<span class="text-blue-500 font-semibold mr-2">2.</span>The plan type, such as Bronze, Silver, Gold or Platinum',
        '<span class="text-blue-500 font-semibold mr-2">3.</span>The plan category, such as HMO or PPO',
        '<span class="text-blue-500 font-semibold mr-2">4.</span>The cost of coverage',
      ],
    },
    imageUrl: '/assets/img/carriers/blueshield-doctor-and-old-woman.webp',
  },
  finalSection: {
    visible: true,
    contentDirection: 'right',
    title: 'Who Should Consider Blue Shield Coverage?',
    content: '<p>Californians in need of affordable health care should consider Blue Shield. Members can enjoy many benefits and the peace of mind of having essential, high-quality coverage they can depend on. Those seeking a health plan provider that gives back to the community should look no further than Blue Shield of California.</p>',
    imageSection: {
      title: 'Get Blue Shield Coverage Through Health for California',
      titleColor: 'text-teal-400',
      titleSeparator: true,
      content: 'Health for California helps individuals, families and businesses find the ideal Blue Shield coverage.',
      contentColor: 'text-white',
      contentWidth: 'w-2/3 md:w-1/2',
      justifyContent: 'items-start',
      textDirection: 'text-left',
      imageUrl: '/assets/img/carriers/blueshield-final-banner.webp',
    },
    secondContent: '<p>Health for California helps individuals, families and businesses find the ideal Blue Shield coverage. Our fast, easy and reliable <a class="underline" href="https://www.blueshieldca.com/en/broker/ifp/life" target="_blank">online platform allows you to get</a> free quotes and access to our trusted agents at no charge. We aim to assist as many people as possible in getting quality, affordable health care plans according to their needs. Contact us today for personalized solutions!</p>',
    bgColor: 'bg-slate-100',
  }
};
