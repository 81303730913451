<script lang="ts" setup>
import { inject } from 'vue';
import { BaseButton, usePlocState } from '@hfc/ui-core';
import HeroSection from '@/page/infrastructure/components/HeroSection.vue';
import CarriersCarousel from '@/page/infrastructure/components/CarriersCarousel.vue';
import BackgroundImage from '@/page/infrastructure/components/BackgroundImage.vue';
import LifeHappens from '@/page/infrastructure/components/LifeHappens.vue';
import CaliforniaHealthExchange from '@/page/infrastructure/components/CaliforniaHealthExchange.vue';
import QuestionsSection from '@/page/infrastructure/components/QuestionsSection.vue';
import GetQuoteSection from '@/page/infrastructure/components/GetQuoteSection.vue';
import ServicesSection from '@/page/infrastructure/components/ServicesSection.ce.vue';
import AdultComputerIcon from '@/page/infrastructure/components/AdultComputerIcon.vue';
import FamilyIcon from '@/page/infrastructure/components/FamilyIcon.vue';
import SeniorIcon from '@/page/infrastructure/components/SeniorIcon.vue';
import DownArrowCustom from '@/page/infrastructure/components/DownArrowCustom.vue';
import CertifiedBanner from '@/page/infrastructure/components/CertifiedBanner.vue';
import { PageStore } from '@/page/infrastructure/store/PageStore';
import '@slightlyoff/lite-vimeo';

const pageStore = inject<PageStore>('PageStore') as PageStore;
const pageState = usePlocState(pageStore);

const quoteButtons = [
  {
    text: 'INDIVIDUAL & FAMILY PLANS',
    href: '/individual-and-family-quote',
    component: FamilyIcon,
  },
  {
    text: 'SMALL BUSINESS PLANS',
    href: '/group-health-insurance',
    component: AdultComputerIcon,
  },
  {
    text: 'MEDICARE / SENIOR PLANS',
    href: '/senior-health-insurance-options',
    component: SeniorIcon,
  },
];

const learnMore = (): void => {
  const el = document.querySelector('#life-happens') as HTMLElement;

  if (el) {
    scroll({ top: el.offsetTop - 103, behavior: 'smooth' });
  }
};
</script>

<template>
  <div class="home">
    <HeroSection
      :img="{src: '/assets/img/bg/hfc_hero.webp', alt: 'HFC - Homepage'}"
      :pre-title="{text: 'COVERED CALIFORNIA CERTIFIED'}"
      :title="{text: 'Health Insurance<br>for California'}"
      :post-title="{text: 'What type of health plan are you looking for?', customClass: 'text-gray-300'}"
      >
      <div class="flex mt-5 sm:my-6 flex-col sm:flex-row">
        <BaseButton v-for="button in quoteButtons" :key="button.text" class="flex sm:flex-col items-center sm:mr-5 justify-evenly sm:justify-center bg-white hover:bg-yellow-500 w-full sm:h-36 lg:w-40 text-center mb-4 sm:mb-0 py-1 sm:py-2 lg:py-0 rounded-md" :ignore-styles="true" :href="button.href">
          <component :is="button.component" class="w-20 sm:w-auto ml-1 sm:ml-0"/>
          <span class="text-black px-2 lg:px-3 mt-2 sm:text-sm" v-html="button.text"></span>
        </BaseButton>
      </div>
      <div class="flex flex-col md:flex-row items-start md:items-center md:mt-5">
        <p class="text-white">Lowest prices. Simple process.</p>
        <BaseButton class="ml-2 md:ml-9 items-center hidden lg:flex" :ignore-styles="true" @click="learnMore">
          <span class="text-white whitespace-nowrap">LEARN MORE</span>
          <DownArrowCustom/>
        </BaseButton>
      </div>
    </HeroSection>
    <CarriersCarousel />
    <BackgroundImage img="/assets/img/bg/skating.webp" alt="Girl skating" width="960" height="717" id="life-happens">
      <LifeHappens/>
    </BackgroundImage>
    <div class="bg-blue text-white text-center">
      <div class="py-14 max-w-5xl mx-auto px-4 sm:px-6">
        <h3 class="text-white font-medium text-3xl mb-5">Affordable Health Insurance for California Consumers</h3>
        <p>We have approximately 39 million people in the state. California ranks as the most populous state in the United States. Keeping a diverse group of people healthy with affordable health care options has been California’s ongoing challenge. This is why the Marketplace for private and small group medical insurance in California plays a significant role in maintaining happy, healthy individuals.</p>
      </div>
    </div>
    <BackgroundImage img="/assets/img/bg/city.webp" alt="City" :reverse="true" width="960" height="569">
      <CaliforniaHealthExchange/>
    </BackgroundImage>
    <CertifiedBanner />
    <QuestionsSection/>
    <GetQuoteSection/>
    <div class="bg-white">
      <div class="max-w-6xl mx-auto flex flex-col lg:pb-16 px-5 xl:px-0">
        <div class="flex py-8 lg:py-16 flex-col lg:flex-row">
          <div class="flex-1 pb-8 lg:pb-0 lg:pl-0 flex items-center">
            <lite-vimeo videoid="820560787" playlabel="Health For California"></lite-vimeo>
          </div>
          <div class="flex-1 lg:pl-16 flex flex-col justify-center">
            <h3 class="text-gray-700 font-medium text-3xl mb-5">Let Us Help You Pick the Right Health Insurance Plan</h3>
            <p class="text-gray-500 pb-3">Simply put, you can’t get a better price anywhere.</p>
            <p class="text-gray-500 pb-3">We simplify what is normally a difficult process! <a class="text-blue" href="/blog/choosing-health-insurance-thats-right-for-you">Finding the right health plan</a> can be stressful and time-consuming. It's hard to sift through all the health insurance options and wrestle with which plan is best for your situation. That's where we come in.</p>
            <p class="text-gray-500 pb-3">We make applying for health insurance feasible, fast, and free! And, what’s the best part? We have a wide variety of plans. Getting a quote is very easy. Give us 30 seconds, and we'll give you the most affordable rates available from multiple providers in your area. When you use us, you can rest assured that you’re getting the best prices and the most streamlined process.</p>
          </div>
        </div>
        <ServicesSection/>
      </div>
    </div>
    <get-quote-banner v-if="pageState.appsEnabled" :applications="JSON.stringify(pageState.appsEnabled)"></get-quote-banner>
  </div>
</template>
