import { AxiosError } from 'axios';
import { SfApiResponse, RawSfError, CarrierList } from '@hfc/ui-core';
import { CarrierPage } from '@/page/domain/CarrierPage';
import { CarrierRepository } from '@/page/domain/CarrierRepository';
import { laCarePageData } from '@/page/infrastructure/dataSource/carriers/LaCarePageData';
import { aetnaPageData } from '@/page/infrastructure/dataSource/carriers/AetnaPageData';
import { anthemPageData } from '@/page/infrastructure/dataSource/carriers/AnthemPageData';
import { blueShieldPageData } from '@/page/infrastructure/dataSource/carriers/BlueShieldPageData';
import { cchpPageData } from '@/page/infrastructure/dataSource/carriers/CCHPPageData';
import { healthNetPageData } from '@/page/infrastructure/dataSource/carriers/HealthNetPageData';
import { iehpPageData } from '@/page/infrastructure/dataSource/carriers/IEHPPageData';
import { kaiserPageData } from '@/page/infrastructure/dataSource/carriers/KaiserPageData';
import { molinaPageData } from '@/page/infrastructure/dataSource/carriers/MolinaPageData';
import { sharpPageData } from '@/page/infrastructure/dataSource/carriers/SharpPageData';
import { valleyPageData } from '@/page/infrastructure/dataSource/carriers/ValleyPageData';
import { westernPageData } from '@/page/infrastructure/dataSource/carriers/WesternPageData';
import { medicalPageData } from '@/page/infrastructure/dataSource/carriers/MedicalPageData';

export class CarrierMemory implements CarrierRepository {

  public async getCarrierData (carrierId: string): Promise<SfApiResponse<Partial<CarrierPage>>> {
    switch (carrierId) {
      case CarrierList.LACare:
        return {
          success: true,
          data: laCarePageData
        };
      case CarrierList.Aetna:
        return {
          success: true,
          data: aetnaPageData
        };
      case CarrierList.Anthem:
        return {
          success: true,
          data: anthemPageData
        };
      case CarrierList.BlueShield:
        return {
          success: true,
          data: blueShieldPageData
        };
      case CarrierList.CCHP:
        return {
          success: true,
          data: cchpPageData
        };
      case CarrierList.HealthNet:
        return {
          success: true,
          data: healthNetPageData
        };
      case CarrierList.IEHP:
        return {
          success: true,
          data: iehpPageData
        };
      case CarrierList.Kaiser:
        return {
          success: true,
          data: kaiserPageData
        };
      case CarrierList.Molina:
        return {
          success: true,
          data: molinaPageData
        };
      case CarrierList.Sharp:
        return {
          success: true,
          data: sharpPageData
        };
      case CarrierList.Valley:
        return {
          success: true,
          data: valleyPageData
        };
      case CarrierList.Western:
        return {
          success: true,
          data: westernPageData
        };
      case CarrierList.Medical:
        return {
          success: true,
          data: medicalPageData
        };
      default:
        return {
          success: false,
          error: {
            message: 'Carrier not found',
            status: 404
          } as AxiosError<RawSfError>
        };
    }
  }

}
