import { CarrierPage } from '@/page/domain/CarrierPage';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getQuoteUrl, getBaseUrl } = UseUrlHelper();

export const iehpPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: 'IEHP Insurance',
    content: 'Find the Lowest Prices Available on IEHP Insurance Plans or Get Discounts Through Our Covered California Options',
  },
  aboutInfo: {
    visible: true,
    title: 'About Inland Empire Health Plan (IEHP)',
    content: `<p>Inland Empire Health Plan (IEHP) is a wellness-focused, Medi-Cal managed care plan serving residents in the Inland Empire (IE) region. It was <a class="underline" href="https://www.iehp.org/en/our-organization/who-we-are/history" target="_blank">established in 1994</a> as the region's Medi-Cal plan. Today, IEHP <a class="underline" href="${getBaseUrl('/covered-california/plans#')}">also offers metallic plans</a>, including a low-cost silver plan, through Covered California for individuals who aren't eligible for Medi-Cal.</p>`
  },
  sectionInfo: {
    visible: true,
    title: 'Why Choose IEHP?',
    content: '<p>IEHP is known as a health insurance provider that cares about its community and improving access to quality health care. It provides zero-premium or low-cost plans to eligible individuals. IEHP <a class="underline" href="https://www.iehp.org/en/home" target="_blank">offers advantages such as</a>:</p><br>',
    listContent: {
      list: [
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><div>Local customer support team</div></div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><div>Care coordinators to help members plan appointments</div></div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><div>Free 24/7 nurse advice line</div></div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><div>More than 8,000 providers and over 90 urgent care centers in its network</div></div>',
      ],
    },
    imageUrl: '/assets/img/carriers/iehp-woman-and-doctor-sit.webp',
    textDirection: 'text-left',
  },
  networkInfo: {
    visible: true,
    title: 'What Kind of Insurance Is Inland Empire Health Plan?',
    content: `<p>IEHP is <a class="underline" href="https://www.iehp.org/en/our-organization/who-we-are" target="_blank">one of the largest Medicaid health plans</a> in the country. It also offers Covered California plans.</p><br>
    <p>Currently, IEHP is only available to residents in San Bernardino and Riverside counties. Residents of these counties can consider three different types of IEHP coverage:</p>`,
    imageSection: {
      title: 'Three Different Types of IEHP Coverage',
      imageUrl: '/assets/img/carriers/molina-network-background.webp',
      bgColor: 'bg-white',
      redirectButtonUrl: getQuoteUrl(),
      redirectButtonText: 'Get A Free Quote',
      boxContent: [
        {
          title: 'IEHP Medi-Cal',
          content: 'This plan is an option for those who qualify for Medi-Cal.',
          boxData: {
            imageUrl: '/assets/img/carriers/iehp-network-image-1.webp',
            imageStyles: 'w-full rounded-lg shadow-lg max-w-[240px]',
            boxColor: 'bg-transparent',
          }
        },
        {
          title: 'IEHP DualChoice',
          content: 'DualChoice combines Medi-Cal and Medicare benefits in a single plan.',
          boxData: {
            imageUrl: '/assets/img/carriers/iehp-network-image-2.webp',
            imageStyles: 'w-full rounded-lg shadow-lg max-w-[240px]',
            boxColor: 'bg-transparent',
          }
        },
        {
          title: 'EHP Covered',
          content: 'This is IEHP\'s Covered California option for those not eligible for Medi-Cal.',
          boxData: {
            imageUrl: '/assets/img/carriers/iehp-network-image-3.webp',
            imageStyles: 'w-full rounded-lg shadow-lg max-w-[240px]',
            boxColor: 'bg-transparent',
          }
        },
      ],
    },
    bgColor: 'bg-slate-100',
  },
  extraSection1: {
    visible: true,
    title: 'Is IEHP the Same as Medi-Cal?',
    content: `<p>IEHP is not the same as Medi-Cal - but they work together. Medi-Cal is <a class="underline" href="${getBaseUrl('/covered-california/health-insurance-companies/medi-cal')}">California's Medicaid program</a> that provides services and benefits through different counties' managed care plans. IEHP is one of those managed care plans for residents in Riverside and San Bernardino counties.</p>`
  },
  extraSection2: {
    visible: true,
    title: 'What Are Inland Empire Health Plan Services and Benefits?',
    content: `<p>Like all Medi-Cal and Covered California plans, IEHP <a class="underline" href="${getBaseUrl('/affordable-care-act/essential-health-benefits')}">provides essential benefits</a> such as covering emergency services, preventive care and doctor appointments. It also offers vision services and free wellness classes to community members at its resource centers in Riverside, San Bernardino and Victorville.</p>`,
    imageUrl: '/assets/img/carriers/iehp-doctor-and-woman-tablet.webp',
    contentDirection: 'right',
    textDirection: 'text-left',
    redirectButtonText: 'GET MY FREE QUOTE NOW!',
    redirectUrl: getQuoteUrl(),
    bgColor: 'bg-sky-50',
  },
  extraSection3: {
    visible: true,
    title: 'How Do You Join IEHP in California?',
    content: `<p>You can apply for health insurance and enroll in an IEHP plan through Covered California — the state's marketplace. However, you don't have to go through the state's complicated application process. Instead, you can apply for an IEHP plan right here on our site.</p><br>
    <p>We make it easy to enroll in a Medi-Cal or Covered California health plan. When you <a class="underline" href="${getBaseUrl('/individual-and-family-quote')}">request a quick and simple quote</a> on our site, our system will automatically determine your eligibility for a Medi-Cal plan like IEHP Medi-Cal and guide you to the next step.</p><br>
    <p>If you don't qualify for Medi-Cal, you can use our application process to shop for an affordable IEHP Covered California plan that meets your needs.</p>`,
  },
  finalSection: {
    visible: true,
    contentDirection: 'left',
    title: 'Choose a Great Health Plan Today',
    content: `IEHP is an excellent choice for lE residents seeking no-cost or low-cost health insurance - but IEHP is just one option for great health care coverage. Complete our fast application today and discover various health plans for your budget and health care goals. If you run into any questions, know that our agents are always happy to help.</p><br>
    <p>Contact us today <a class="underline" href="${getBaseUrl('/contact-us')}">to find out more</a> about IEHP or any other California health plan.`,
    imageUrl: '/assets/img/carriers/iehp-man-and-doctor-clipboard.webp',
    imageSection: {
      title: ' Choose a <b>Great Health Plan</b> Today',
      extraSpace: 'py-24',
      justifyContent: 'items-end',
      contentWidth: 'w-2/5',
      imageUrl: '/assets/img/carriers/iehp-final-banner.webp',
      redirectButtonText: 'Contact Us',
      redirectButtonUrl: getBaseUrl('/contact-us'),
    },
    bgColor: 'bg-slate-100',
  }
};
