import { MetalPlanPageSection } from '@/page/domain/MetalPlanPageSection';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl, getQuoteUrl } = UseUrlHelper();
export const bronze60PageData: Partial < MetalPlanPageSection > = {
  initialBanner: {
    visible: true,
    title: 'Covered California',
    subtitle: 'Peace of Mind for Unexpected High Medical Bills',
    subtitleColor: 'text-orange-300',
  },
  highlightSection: {
    visible: true,
    content: `<p>Covered California’s Bronze Plan <b>covers 60% of your annual medical services</b> on average, and is the <b>least expensive plan available</b> that qualifies for premium assistance.</p><br>
    <p>This plan offers a low monthly premium. Also, it gives you the peace of mind of knowing you have coverage in the case of a “catastrophic event”.  Without coverage, you could end up with a medical bill in the tens or hundreds of thousands of dollars, but with this plan, your highest medical bills in any one year would cap out at the out-of-pocket maximum (OOP Max). See details for each plan.</p><br>
    <p>However, with lower premiums come <b>higher out-of-pocket costs</b> when you need to use medical services like the emergency room, etc. Most services are subject to the deductible. This means that you will be responsible to pay full price for your services until the deductible is met. For example, you will pay full price for x-rays and emergency room visits until you have reached your deductible.</p>`,
  },
  textSection: {
    visible: true,
    title: 'Highlights of the Bronze Plan',
    contentMode: 'centered',
    subContent: [
      '<p>The standard Bronze Plan gives primary doctor, mental health therapy, labs, urgent care, and specialist visits at a flat copay ranging from $40-$95. Some services have a limit at the discounted rate. Note: The HSA Bronze Plan does not include this benefit.</p>',
      '<p>The Bronze plans (like all of the plans) includes free preventative care such as annual physicals (including mammograms and colonoscopies), well-baby checkups, and immunizations.</p>',
      '<p>Basic pediatric vision and dental preventative services are included at no charge for children 18 and under.</p>',
    ],
  },
  metalPlanTier: {
    visible: false,
    title: 'A Sister Plan with Tax Benefits',
    subtitle: '<p>Covered California also offers the Bronze 60 HDHP/HSA "Sister Plan". HSA is an abbreviation for a bank account called a Health Savings Account. With this plan, you have the option of setting up a separate Health Savings Account with a bank of your choice, which is tax deductible at the Federal level.</p>',
  },
  metalTierHighlight: {
    visible: false,
    title: 'Is the Bronze 60 Plan Right for Me?',
    subtitle: 'This plan may be right for you if:',
    contentMode: 'twoColumns',
    subContent: [
      '<p>You want a low monthly premium.</p>',
      '<p>You are comfortable with a high deductible, and you mainly want a catastrophic plan.</p>',
      '<p>Your goal is to comply with the Individual Mandate to have health insurance verses having the richest benefits.</p>',
      '<p>You are in good health and typically use very few medical services in a year.</p>',
      '<p>You are not regularly taking costly prescription drugs.</p>',
      `<p>Your income qualifies you for a subsidy but you do not qualify for <a href="${getBaseUrl('/covered-california/plans/silver')}">Enhanced Silver Plan</a>.</p>`,
      '<p>Your income does not qualify you for a subsidy and you are age 50 or over. Note: At this age the cost difference between the Bronze and the Silver plans is often greater than $100.</p>',
    ],
  },
  metalPlansSection: {
    visible: true,
    title: 'What Types of Health Plans are Available',
    redirectUrl: '/covered-california/plans'
  },
  googleReviewsSection: {
    visible: true,
    title: 'Customer Reviews',
    imageUrl: '/assets/img/bg/guys_working.webp'
  },
  certifiedBanner: {
    visible: true,
  },
  twoColumnSection: {
    visible: true,
    title: 'A Little More to Think About',
    subContent: [
      '<p>If your income does not qualify you for a premium tax credit and you are under age 30, consider the Minimum Coverage Plan.</p>',
      `<p>If your income qualifies you for an <a href="${getBaseUrl('/covered-california/plans/silver')}">Enhanced Silver Plan</a>, consider this plan and weigh the added out-of-pocket savings to the premium savings of the Bronze plan.</p>`,
      `<p>If you typically use a lot of prescriptions, doctor visits, or other medical services, you may want to consider a <a href="${getBaseUrl('/covered-california/plans/silver')}">Silver</a>, <a href="${getBaseUrl('/covered-california/plans/gold-80')}">Gold</a> or <a href="${getBaseUrl('/covered-california/plans/platinum-90')}">Platinum</a>.</p>`,
    ],
    imageUrl: '/assets/img/bg/bronze_60_a.png',
    redirectUrl: getQuoteUrl(),
    redirectText: 'Get a Free Plan Quote',
    contentDirection: 'right',
  },
  finalBanner: {
    visible: true,
    title: 'A LITTLE MORE TO THINK ABOUT',
    imageUrl: '/assets/img/bg/woman_and_doctor_fadedx.png',
    redirectUrl: '/contact-us'
  }
};
