import { Page } from '@/page/domain/Page';
import { PageRepository } from '@/page/domain/PageRepository';
import { ServicesApiResponse } from '@hfc/ui-core';

export class PageMemory implements PageRepository {

  public async getBySlug (slug: string): Promise<ServicesApiResponse<Page>> {
    await this.sleep(1000);

    return {
      success: true,
      data: new Page({ title: slug, content: 'blabla' })
    };
  }

  private sleep (ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
