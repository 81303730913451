<script setup lang="ts">
import { computed } from 'vue';
import { BaseButton } from '@hfc/ui-core';
import HeadingNavSubItemSecondLevel from '@/shared/infrastructure/components/heading/HeadingNavSubItemSecondLevel.vue';
import { MenuItem } from '@/shared/domain/MenuItem';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const props = defineProps<{
  item: MenuItem
}>();

const { getBaseUrl } = UseUrlHelper();

const itemHref = computed(() => props.item.href ? getBaseUrl(props.item.href) : undefined);

</script>
<template>
  <div>
    <BaseButton :href="itemHref" :ignore-styles="true" class="block w-full text-left pb-2 md:pb-5 rounded-none text-blue px-3 md:px-7 lg:min-w-[300px]">
      <p class="text-lg font-bold md:whitespace-nowrap ml-5 md:ml-0 uppercase">
        {{ item.label }}
      </p>
      <p class="mt-1 text-sm" v-if="item.description">
        {{ item.description }}
      </p>
    </BaseButton>
    <component v-if="item.customComponent" :is="item.customComponent"></component>
    <div v-else-if="item.subMenu.length || item.subMenuSideComponent" class="flex">
      <HeadingNavSubItemSecondLevel v-if="item.subMenu.length" :items="item.subMenu" class="ml-0" :class="{ 'flex-grow': !item.subMenuSideComponent }" />
      <component v-if="item.subMenuSideComponent" :is="item.subMenuSideComponent"></component>
    </div>
  </div>
</template>
