<script setup lang="ts">
import { computed, inject, onBeforeMount } from 'vue';
import ServicesSection from '@/page/infrastructure/components/ServicesSection.ce.vue';
import LicenseContent from '@/page/infrastructure/components/LicenseContent.vue';
import { PageStore } from '@/page/infrastructure/store/PageStore';
import { usePlocState } from '@hfc/ui-core';
import { MetalPlanData } from '@/page/domain/MetalPlanData';
import { MetalPlanPageSection } from '@/page/domain/MetalPlanPageSection';
import MetalPlanBanner from '@/page/infrastructure/components/metalPlans/MetalPlanBanner.vue';
import MetalPlanHighlight from '@/page/infrastructure/components/metalPlans/MetalPlanHighlight.vue';
import InfoBanner from '@/page/infrastructure/components/metalPlans/InfoBanner.vue';
import MetalPlansSection from '@/page/infrastructure/components/metalPlans/MetalPlansSection.vue';
import TextSection from '@/page/infrastructure/components/metalPlans/TextSection.vue';
import GoogleReviews from '@/page/infrastructure/components/metalPlans/GoogleReviews.vue';
import TwoColumnSection from '@/page/infrastructure/components/metalPlans/TwoColumnSection.vue';
import FinalBanner from '@/page/infrastructure/components/metalPlans/FinalBanner.vue';
import CertifiedBanner from '@/page/infrastructure/components/CertifiedBanner.vue';

const props = defineProps<{
  metalPlanPageSections: Partial<MetalPlanPageSection>;
  planName: string;
}>();

const pageStore = inject<PageStore>('PageStore') as PageStore;
const pageState = usePlocState(pageStore);

const metalPlans = computed(() => pageState.value.metalPlans as Array<MetalPlanData>);
const primaryMetalPlans = computed(() => pageState.value.metalPlans.filter(plan => plan.primary === true) as Array<MetalPlanData>);
const highlightedPlan = computed(() => metalPlans.value.find(plan => plan.key.includes(props.planName)) as MetalPlanData);
const nonHighlightedPlan = computed(() => primaryMetalPlans.value.filter(plan => !plan.tierName.includes(highlightedPlan.value.tierName)) as Array<MetalPlanData>);

onBeforeMount(() => {
  const script = document.createElement('script');
  script.src = 'https://static.elfsight.com/platform/platform.js';
  document.head.appendChild(script);
});
</script>
<template>
<div id="metalPlans" class="flex flex-1 flex-col bg-white items-center">

  <div class="w-full">
    <slot name="InitialBanner" :page-data="metalPlanPageSections.initialBanner" :plan="highlightedPlan">
      <MetalPlanBanner v-if="metalPlanPageSections.initialBanner && metalPlanPageSections.initialBanner.visible" :pageData="metalPlanPageSections.initialBanner" :plan="highlightedPlan"></MetalPlanBanner>
    </slot>
    <slot name="afterInitialBanner" :page-data="metalPlanPageSections" :plan="highlightedPlan"></slot>

    <slot name="HighlightSection" :page-data="metalPlanPageSections.highlightSection" :plan="highlightedPlan">
      <MetalPlanHighlight v-if="metalPlanPageSections.highlightSection && metalPlanPageSections.highlightSection.visible" :pageData="metalPlanPageSections.highlightSection" :plan="highlightedPlan"></MetalPlanHighlight>
    </slot>
    <slot name="afterHighlightSection" :page-data="metalPlanPageSections" :plan="highlightedPlan"></slot>

    <slot name="InfoBanner" :page-data="metalPlanPageSections.infoBanner">
      <InfoBanner v-if="metalPlanPageSections.infoBanner && metalPlanPageSections.infoBanner.visible" :pageData="metalPlanPageSections.infoBanner"></InfoBanner>
    </slot>
    <slot name="afterInfoBanner" :page-data="metalPlanPageSections" :plan="highlightedPlan"></slot>

    <slot name="TextSection" :page-data="metalPlanPageSections.textSection">
      <TextSection v-if="metalPlanPageSections.textSection && metalPlanPageSections.textSection.visible" :pageData="metalPlanPageSections.textSection"></TextSection>
    </slot>
    <slot name="afterTextSection" :page-data="metalPlanPageSections" :plan="highlightedPlan"></slot>

    <slot name="MetalPlansSection" :page-data="metalPlanPageSections.metalPlansSection" :plans="nonHighlightedPlan">
      <MetalPlansSection v-if="metalPlanPageSections.metalPlansSection && metalPlanPageSections.metalPlansSection.visible" :pageData="metalPlanPageSections.metalPlansSection" :plans="nonHighlightedPlan"></MetalPlansSection>
    </slot>
    <slot name="afterMetalPlansSection" :page-data="metalPlanPageSections" :plan="highlightedPlan"></slot>

    <slot name="GoogleReviewsSection">
      <GoogleReviews v-if="metalPlanPageSections.googleReviewsSection && metalPlanPageSections.googleReviewsSection.visible"></GoogleReviews>
    </slot>
    <slot name="afterGoogleReviewsSection" :page-data="metalPlanPageSections" :plan="highlightedPlan"></slot>

    <slot name="CertifiedBanner" :page-data="metalPlanPageSections.certifiedBanner">
      <CertifiedBanner v-if="metalPlanPageSections.certifiedBanner && metalPlanPageSections.certifiedBanner.visible"></CertifiedBanner>
    </slot>
    <slot name="afterCertifiedBanner" :page-data="metalPlanPageSections" :plan="highlightedPlan"></slot>

    <slot name="TwoColumnSection" :page-data="metalPlanPageSections.twoColumnSection">
      <TwoColumnSection v-if="metalPlanPageSections.twoColumnSection && metalPlanPageSections.twoColumnSection.visible" :pageData="metalPlanPageSections.twoColumnSection"></TwoColumnSection>
    </slot>
    <slot name="afterTwoColumnSection" :page-data="metalPlanPageSections" :plan="highlightedPlan"></slot>

    <slot name="FinalBanner" :page-data="metalPlanPageSections.finalBanner">
      <FinalBanner v-if="metalPlanPageSections.finalBanner && metalPlanPageSections.finalBanner.visible" :pageData="metalPlanPageSections.finalBanner"></FinalBanner>
    </slot>
    <slot name="afterFinalBanner" :page-data="metalPlanPageSections" :plan="highlightedPlan"></slot>
  </div>

  <div class="w-full bg-sky-50">
    <div class="flex flex-col max-w-7xl mx-auto px-3 py-4">
      <ServicesSection />
    </div>
  </div>

  <div class="flex flex-col max-w-7xl mx-auto px-3">
    <LicenseContent />
  </div>

  <get-quote-banner class="w-full" v-if="pageState.appsEnabled" :applications="JSON.stringify(pageState.appsEnabled)"></get-quote-banner>
</div>
</template>
