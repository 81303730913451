<script setup lang="ts">
import { UseCarrierDataStore } from '@/page/infrastructure/composition/UseCarrierDataStore';
import CarrierPlan from '@/page/infrastructure/views/CarrierPlan.vue';
import SectionInfo from '@/page/infrastructure/components/carrier/SectionInfo.vue';
import { CarrierList } from '@hfc/ui-core';

const props = defineProps<{
  carrierId: CarrierList;
}>();

const { carrierData } = UseCarrierDataStore(props.carrierId);
</script>
<template>
  <CarrierPlan v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId">
    <template v-slot:NetworkInfo="{ carrierData, carrierId }">
      <SectionInfo v-if="carrierData" :carrier-data="carrierData" />
    </template>
    <template v-slot:afterNetworkInfo="{ pageData }">
      <div v-if="pageData.extraSection1" class="w-full -mt-10 pb-16" :class="pageData.extraSection1.bgColor">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <div :class="pageData.extraSection1.textDirection ? pageData.extraSection1.textDirection : 'text-center'" v-html="pageData.extraSection1.content"></div>
        </div>
      </div>
      <div v-if="pageData.extraSection2" class="w-full py-6" :class="pageData.extraSection2.bgColor">
        <SectionInfo v-if="pageData.extraSection2" :carrier-data="pageData.extraSection2" />
      </div>
      <div v-if="pageData.extraSection3" class="w-full py-6" :class="pageData.extraSection3.bgColor">
        <SectionInfo v-if="pageData.extraSection3" :carrier-data="pageData.extraSection3" />
      </div>
    </template>
  </CarrierPlan>
</template>
