import { CarrierPage } from '@/page/domain/CarrierPage';
import CarpetIcon from '@/page/infrastructure/components/carrier/CarpetIcon.vue';
import ClipboardIcon from '@/page/infrastructure/components/carrier/ClipboardIcon.vue';
import PaperIcon from '@/page/infrastructure/components/carrier/PaperIcon.vue';
import PhoneIcon from '@/page/infrastructure/components/carrier/PhoneIcon.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl, getQuoteUrl } = UseUrlHelper();

export const molinaPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: 'Molina Health Insurance Coverage',
    content: 'Find the Lowest Prices Available on Molina Medical Insurance Plans or Get Discounts Through Our Covered California Options'
  },
  aboutInfo: {
    visible: true,
    title: 'About Molina Healthcare',
    content: `<p>Quality health care is crucial, and as a resident of California, you have many options. Molina health insurance provides individuals and families with affordable, comprehensive coverage and access to a large network of medical professionals and facilities.</p><br>
    <p>This guide breaks down the coverage options and benefits of joining Molina Healthcare. We will also discuss the enrollment process. Read on to learn more, or contact one of our trusted agents for free assistance.</p>`
  },
  sectionInfo: {
    visible: true,
    title: 'Molina Health Insurance California',
    content: `<p>In 1980, Dr. C. David Molina <a class="underline" href="https://www.molinahealthcare.com/members/common/en-US/abtmolina/compinfo/aboutus.aspx" target="_blank">founded Molina HealthCare</a> to provide health services to low-income families in Southern California. It started as one clinic in Long Beach. California, and has since grown into 19 health plans nationwide. Molina has over 5.1 million members, including individuals and families who receive care through Medicaid, Medicare and various government-funded programs.</p><br>
    <p>Molina Healthcare aims to provide quality health care services to individuals and families, particularly those with financial challenges. The organization provides health plans, medical facilities and health information management solutions, making it unique. The service areas under Molina include:</p><br>`,
    listContent: {
      list: [
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Sacramento</div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Yolo</div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Marin</div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Sonoma</div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Napa</div>',
        '<div class="flex flex-row">And part of:</div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Solano</div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Placer</div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>El Dorado</div',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Colusa</div>',
      ],
      listCut: 5,
      listNoWrapResponsive: true,
    },
    secondContent: '<p>Molina has an extensive network of doctors, hospitals and pharmacies. Members can find providers and facilities online and access other valuable information. One helpful tool is the cost estimator, which helps members estimate how much they would pay for a treatment or service.</p>',
    textDirection: 'text-left',
    imageUrl: '/assets/img/carriers/doctors-stairs.webp'
  },
  networkInfo: {
    visible: true,
    title: 'How to Get Molina Health Care Coverage',
    imageSection: {
      title: 'Steps to Getting Molina Health Care Coverage in California',
      imageUrl: '/assets/img/carriers/molina-network-background.webp',
      bgColor: 'bg-white',
      redirectButtonUrl: getQuoteUrl(),
      redirectButtonText: 'Contact Us',
      boxContent: [
        {
          title: 'Medi-Cal',
          content: 'Free or low-cost health coverage to eligible low-income individuals and families.',
          boxData: {
            imageUrl: '/assets/img/carriers/network-1.webp',
            imageStyles: 'w-full rounded-lg shadow-lg max-w-[240px]',
            boxColor: 'bg-transparent',
          }
        },
        {
          title: 'Medicare',
          content: 'The Federal health insurance for people ages 65 and older. Individual with certain disabilities or conditions may also qualify.',
          boxData: {
            imageUrl: '/assets/img/carriers/network-2.webp',
            imageStyles: 'w-full rounded-lg shadow-lg max-w-[240px]',
            boxColor: 'bg-transparent',
          }
        },
        {
          title: 'Marketplace Plans',
          content: 'Individuals and family health insurance plans through Covered California.',
          boxData: {
            imageUrl: '/assets/img/carriers/network-3.webp',
            imageStyles: 'w-full rounded-lg shadow-lg max-w-[240px]',
            boxColor: 'bg-transparent',
          }
        },
        {
          title: 'Dual Eligible Special Needs Plans (D-SNPs)',
          content: 'Individuals who qualify for both Medicare and Medicaid.',
          boxData: {
            imageUrl: '/assets/img/carriers/network-4.webp',
            imageStyles: 'w-full rounded-lg shadow-lg max-w-[240px]',
            boxColor: 'bg-transparent',
          }
        },
        {
          title: 'Long-term Services and Support (LTSS)',
          content: 'Medi-Cal benefits to assist eligible members with ongoing personal care needs.',
          boxData: {
            imageUrl: '/assets/img/carriers/network-5.webp',
            imageStyles: 'w-full rounded-lg shadow-lg max-w-[240px]',
            boxColor: 'bg-transparent',
          }
        },
      ],
    },
    content: '<p>The steps to getting Molina health care coverage in California are straightforward. The following can guide you:</p><br>',
    subContent: [
      {
        title: '1. Medi-Cal',
        content: '<p>Medi-Cal is California\'s Medicaid program. Medicaid provides free or low-cost health coverage to eligible low-income individuals and families. Medi-Cal is supported by state and federal taxes, and eligible persons do not pay any monthly premiums. You get comprehensive coverage and access to a wide network of providers.</p>',
      },
      {
        title: '2. Medicare',
        content: '<p>Medicare is the federal health insurance for people ages 65 and older. Individuals with certain disabilities or conditions may also qualify. Molina members can get care from in-network providers, but out-network care is limited to emergencies. Coverage also includes prescription drugs in certain instances.</p>',
      },
      {
        title: '3. Marketplace Plans',
        content: '<p>Molina provides individual and family health insurance plans through Covered California, the state\'s health insurance marketplace. You can choose from various plan levels catering to different needs and budgets. Eligible persons may get subsidies to help reduce monthly premiums and out-of-pocket costs.</p>',
      },
      {
        title: '4. Dual Eligible Special Needs Plans (D-SNPs)',
        content: '<p>D-SNP is tailored for individuals who qualify for both Medicare and Medicaid. It offers care coordination and wrap-around services. In other words. members get comprehensive coverage that may include additional services that either program alone does not typically cover.</p>',
      },
      {
        title: '5. Long-term Services and Support (LTSS)',
        content: `<p>These are <a class="underline" href="https://www.molinahealthcare.com/providers/ca/medicaid/resource/ltss.aspx" target="_blank">Medi-Cal benefits to assist eligible members</a> with ongoing personal care needs. The services include:</p>
        <div class="flex flex-col w-full">
          <div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><div><b>In-home supportive services (IHSS):</b> &nbsp;IHSS helps pay for services that allow members to live safely in their homes.</div></div>
          <div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><div><b>Long-term nursing home care:</b> &nbsp;This program places members who cannot safely live in their residences due to a health condition requiring ongoing care</div></div>
          <div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><div><b>Community based adult services (CBAS):</b> &nbsp;This community-based day health program is designed for adults and older adults with chronic illnesses and disabilities at risk of needing institutional care.</div></div>
          <div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><div><b>Multi-purpose senior services program (MSSP):</b> &nbsp;This program provides social and health care management for frail elders eligible to be placed in nursing facilities but who prefer to remain in the community.</div></div>
        </div>
        `,
      },
    ],
    secondContent: '<p>These plans and services provide unique benefits to Californian residents.</p>',
  },
  extraSection1: {
    visible: true,
    textDirection: 'text-left',
    title: 'Benefits of Molina Health Care Plans',
    bgColor: 'bg-sky-50',
    imageSection: {
      title: 'Molina provides <b>extensive coverage</b>, including services such as:',
      titleColor: 'text-white',
      contentWidth: 'w-2/3 md:w-1/2',
      justifyContent: 'items-start',
      textDirection: 'text-left',
      imageUrl: '/assets/img/carriers/molina-banner-background.webp',
    },
  },
  finalSection: {
    visible: true,
    textDirection: 'text-left',
    title: 'How to Get Molina Health Care Coverage',
    content: '<p>The steps to getting Molina health care coverage in California are straightforward. The following can guide you:</p>',
    subContent: [
      {
        title: '1. Determine Eligibility',
        content: '<p>Determine your eligibility for the various Molina health insurance plans. You can choose Medi-Cal, Medicare or the marketplace option with the applicable variations.</p>',
        componentIcon: ClipboardIcon,
      },
      {
        title: '2. Gather Required Information',
        content: '<p>Gather the necessary documents, such as proof of identification, social security number and income information. Molina will use this information to determine whether you qualify for the plan.</p>',
        componentIcon: PaperIcon,
      },
      {
        title: '3. Choose Your Application Method',
        content: '<p>Molina allows you to apply through various methods. For example, you can enroll directly on their website, by mail or by visiting a local office. Call Molina Healthcare\'s customer service if you need assistance.</p>',
        componentIcon: PhoneIcon,
      },
      {
        title: '4. Complete the Application',
        content: '<p>Fill out the application form, attaching all the required documents. Remember to review for accuracy before submitting. You must complete the process during the open enrollment periods and select your desired coverage options based on your location. Molina will send you a letter acknowledging receipt of your enrollment form and a welcome kit once the process is complete. Lastly, pay your premium as required.</p>',
        componentIcon: CarpetIcon,
      },
    ],
    bgColor: 'bg-sky-50',
  },
  extraSection2: {
    visible: true,
    textDirection: 'text-center',
    title: 'Get Free Help From Health for California',
    content: `<p>Need help choosing and enrolling in the ideal coverage? Health for California is ready to assist. We provide free services to California residents searching for quality, affordable health care. With years of experience and extensive resources, our team can guide you to determine and apply for your desired coverage.</p><br>
    <p>Molina health insurance in California is helping individuals and families get the care they deserve, and we can help make that happen for you. <a class="underline" href="${getQuoteUrl()}">Get a free quote now</a>, or <a class="underline" href="${getBaseUrl('/contact-us')}">contact us</a> today with your questions.</p>`,
    bgColor: 'bg-slate-100',
    imageSection: {
      title: 'Get Free Help From <b>Health for California</b>',
      contentWidth: 'w-2/3 md:w-1/2',
      justifyContent: 'items-start',
      textDirection: 'text-left',
      redirectButtonText: 'Contact Us',
      redirectButtonUrl: getQuoteUrl(),
      imageUrl: '/assets/img/carriers/molina-final-banner.webp',
    },
  },
};
