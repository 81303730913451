import { CarrierPage } from '@/page/domain/CarrierPage';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl, getQuoteUrl } = UseUrlHelper();

export const westernPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: 'Western Health <br> Advantage Health <br> Insurance Coverage',
    content: 'Find the Lowest Prices Available on Western Health Advantage Medical Insurance Plans or Get Discounts Through Our Covered California Options'
  },
  aboutInfo: {
    visible: true,
    title: 'About Western Health Advantage',
    content: `<b>Type of Plan:</b> HMO<br>
    <b>Pro’s:</b> Great Service, High Quality Physician Network, Competitive Pricing<br>
    <b>Con’s:</b> Limited geographically to only some parts of Northern California, Limited to the approved network<br><br>

    <b>Top Competitor:</b> Kaiser Permanente`,
  },
  extraSection1: {
    visible: true,
    content: `<p>"We treat individual and Family coverage the same as Group coverage. Members have access to the same network."</p>
    <p>- Representative from Western Health Advantage</p>`,
  },
  networkInfo: {
    visible: true,
    title: 'Western Health Advantage ( WHA Insurance ) is offered in 9 counties in Northern California',
    content: '<p>They refer to themselves as the "Little Carrier that Could". They have:</p>',
    subContent: [{
      title: '160',
      content: 'Employees (working for the health plan)'
    },
    {
      title: '118,000',
      content: 'Members'
    },
    {
      title: '3,200',
      content: 'Doctors and Specialists'
    },
    {
      title: '6',
      content: 'Medical Groups'
    },
    {
      title: '14',
      content: 'Hospitals'
    }
    ]
  },
  extraSection2: {
    visible: true,
    title: 'Offered in the following Northern California Counties:',
    contentWidth: 'w-full lg:w-1/2',
    listContent: {
      list: [
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Sacramento</div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Yolo</div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Marin</div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Sonoma</div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Napa</div>',
        '<div class="flex flex-row">And part of:</div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Solano</div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Placer</div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>El Dorado</div',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Colusa</div>',
      ],
      listCut: 5,
      listNoWrapResponsive: true,
    },
    secondContent: '<p>"WHA Insurance is only licensed in one city in Colusa County, the city of Arbuckle."</p>',
    imageUrl: '/assets/img/carriers/sacramento-map.webp',
    contentDirection: 'right',
    textDirection: 'text-left',
  },
  extraSection3: {
    visible: true,
    content: '<p><b>Note:</b> <a class="underline" href="https://www.westernhealth.com/" target="_blank">Western Health Advantage</a> has 1.5 Stars on <a class="underline" href="https://www.yelp.com/biz/western-health-advantage-sacramento-2" target="_blank">Yelp</a> with only 9 reviews. However, keep in mind that when it comes to health plans, often those who comment on Yelp are exclusively those who have had a bad experience and often it is those who have had a bad experience in one situation with a single physician. To keep this in perspective, Blue Shield of California has only 1 Star on Yelp, but Kaiser Permanente has 3 Stars.</p>',
  },
  extraSection4: {
    visible: true,
    title: 'WHA Insurance Member Services',
    listContent: {
      list: [
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Call:&nbsp;<a class="underline" href="tel:916-563-2250">916-563-2250</a></div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Fax:&nbsp;<a class="underline" href="tel:916-568-0126">916-568-0126</a></div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Email:&nbsp;<a class="underline" href="mailto:memberservices@westernhealth.com">memberservices@westernhealth.com</a></div>',
      ],
      listCut: 1,
    },
    secondContent: '<p>Toll free at &nbsp;<a class="underline" href="tel:888-563-2250">888-563-2250</a></p>',
    textDirection: 'text-left',
    redirectUrl: getQuoteUrl(),
    redirectButtonText: 'GET MY FREE QUOTE NOW!',
    bgColor: 'bg-sky-50',
  },
  finalSection: {
    visible: true,
    contentDirection: 'left',
    contentWidth: 'w-full lg:w-1/2',
    title: 'Western Health Advantage Health Plan and Its Benefits',
    listContent: {
      list: [
        `<div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>&nbsp;<a class="underline" href="${getBaseUrl('/covered-california/health-insurance-companies/western-health-advantage/history')}">History</a>`,
        `<div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>&nbsp;<a class="underline" href="${getBaseUrl('/covered-california/health-insurance-companies/western-health-advantage/network')}">The Network</a>`,
        `<div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>&nbsp;<a class="underline" href="${getBaseUrl('/covered-california/health-insurance-companies/western-health-advantage/customer-service')}">WHA Customer Service</a>`,
        `<div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>&nbsp;<a class="underline" href="${getBaseUrl('/covered-california/health-insurance-companies/western-health-advantage/support')}">Call a Doctor or a Nurse</a>`,
        `<div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>&nbsp;<a class="underline" href="${getBaseUrl('/covered-california/health-insurance-companies/western-health-advantage/account')}">MyWHA Online Account</a>`,
        `<div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>&nbsp;<a class="underline" href="${getBaseUrl('/covered-california/health-insurance-companies/western-health-advantage/life-events')}">Life Events</a>`,
        `<div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>&nbsp;<a class="underline" href="${getBaseUrl('/covered-california/health-insurance-companies/western-health-advantage/mobile-app')}">Mobile App</a>`,
        `<div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>&nbsp;<a class="underline" href="${getBaseUrl('/covered-california/health-insurance-companies/western-health-advantage/small-business-ppo')}">PPO Coverage</a>`,
        `<div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>&nbsp;<a class="underline" href="${getBaseUrl('/covered-california/health-insurance-companies/western-health-advantage/pharmacy-coverage')}">Express Scripts</a>`,
        '<div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>&nbsp;<a class="underline" href="https://www.assistamerica.com/" target="_blank">Assist America</a>',
        `<div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>&nbsp;<a class="underline" href="${getBaseUrl('/covered-california/health-insurance-companies/western-health-advantage/magellan')}">Magellan Health Services</a>`,
        `<div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>&nbsp;<a class="underline" href="${getBaseUrl('/covered-california/health-insurance-companies/western-health-advantage/ucsf')}">UCFS</a>`,
      ],
      listCut: 6,
    },
    imageUrl: '/assets/img/carriers/woman-and-doctor.webp'
  },
  extraSection5: {
    visible: true,
    imageSection: {
      title: 'Contact <b>Health for California</b> to Learn More About the <b>Western Health Advantage</b> Medical Insurance Plan',
      titleSeparator: true,
      titleSeparatorColor: 'bg-teal-400',
      justifyContent: 'items-end',
      imageUrl: '/assets/img/carriers/western-banner.webp',
      redirectButtonText: 'Contact Us',
      redirectButtonUrl: getBaseUrl('/contact-us'),
      contentWidth: 'w-1/2',
    },
  },
};
