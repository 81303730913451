import { AxiosError } from 'axios';
import { SfApiResponse, RawSfError } from '@hfc/ui-core';
import { MetalPlanPageSection } from '@/page/domain/MetalPlanPageSection';
import { MetalPlanPageRepository } from '@/page/domain/MetalPlanPageRepository';
import { platinum90PageData } from '@/page/infrastructure/dataSource/metalPlans/Platinum90PageData';
import { gold80PageData } from '@/page/infrastructure/dataSource/metalPlans/Gold80PageData';
import { silverPageData } from '@/page/infrastructure/dataSource/metalPlans/SilverPageData';
import { silver70PageData } from '@/page/infrastructure/dataSource/metalPlans/Silver70PageData';
import { silver73PageData } from '@/page/infrastructure/dataSource/metalPlans/Silver73PageData';
import { silver87PageData } from '@/page/infrastructure/dataSource/metalPlans/Silver87PageData';
import { silver94PageData } from '@/page/infrastructure/dataSource/metalPlans/Silver94PageData';
import { bronze60PageData } from '@/page/infrastructure/dataSource/metalPlans/Bronze60PageData';
import { bronze60HsaPageData } from '@/page/infrastructure/dataSource/metalPlans/Bronze60HsaPageData';

export class MetalPlanPageMemory implements MetalPlanPageRepository {

  public async getMetalPlanPageSections (planName: string): Promise<SfApiResponse<Partial<MetalPlanPageSection>>> {
    switch (planName) {
      case 'bronze_60':
        return {
          success: true,
          data: bronze60PageData
        };
      case 'bronze_60_hsa':
        return {
          success: true,
          data: bronze60HsaPageData
        };
      case 'silver':
        return {
          success: true,
          data: silverPageData
        };
      case 'silver_70':
        return {
          success: true,
          data: silver70PageData
        };
      case 'silver_73':
        return {
          success: true,
          data: silver73PageData
        };
      case 'silver_87':
        return {
          success: true,
          data: silver87PageData
        };
      case 'silver_94':
        return {
          success: true,
          data: silver94PageData
        };
      case 'gold_80':
        return {
          success: true,
          data: gold80PageData
        };
      case 'platinum_90':
        return {
          success: true,
          data: platinum90PageData
        };
      default:
        return {
          success: false,
          error: {
            message: 'MetalPlan not found',
            status: 404
          } as AxiosError<RawSfError>
        };
    }
  }

}
