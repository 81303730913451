interface UseUrlHelperInterface {
  getCdnUrl: (url: string) => string;
  getBaseUrl: (url: string) => string;
  getEnrollmentAppUri: (url: string) => string;
  getQuoteUrl: () => string;
  getMediCalQuoteUrl: () => string;
}

export const UseUrlHelper = (): UseUrlHelperInterface => {
  const getBaseUrl = (url: string): string => {
    return `${import.meta.env.VITE_APP_BASE_URL}${url}`;
  };

  const getCdnUrl = (url: string): string => {
    return `${import.meta.env.VITE_APP_CDN_URL}${url}`;
  };

  function getEnrollmentAppUri (relativePath: string): string {
    return `${import.meta.env.VITE_ENROLLMENT_APP_URL}${relativePath}`;
  }

  const getQuoteUrl = (): string => {
    return `${import.meta.env.VITE_APP_BASE_URL}/individual-and-family-quote`;
  };

  const getMediCalQuoteUrl = (): string => {
    return `${import.meta.env.VITE_APP_BASE_URL}/medical-quote`;
  };

  return {
    getBaseUrl,
    getCdnUrl,
    getEnrollmentAppUri,
    getQuoteUrl,
    getMediCalQuoteUrl,
  };
};
