<script setup lang="ts">
import { UseCarrierDataStore } from '@/page/infrastructure/composition/UseCarrierDataStore';
import CarrierPlan from '@/page/infrastructure/views/CarrierPlan.vue';
import SectionInfo from '@/page/infrastructure/components/carrier/SectionInfo.vue';
import { CarrierList } from '@hfc/ui-core';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import ImageSection from '@/page/infrastructure/components/carrier/ImageSection.vue';

const { getCdnUrl, getQuoteUrl } = UseUrlHelper();

const props = defineProps<{
  carrierId: CarrierList;
}>();

const { carrierData } = UseCarrierDataStore(props.carrierId);
</script>
<template>
  <CarrierPlan v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId">
    <template v-slot:afterSectionInfo="{ pageData, carrierId }">
      <div class="w-full">
        <SectionInfo v-if="pageData.extraSection1" :carrier-data="pageData.extraSection1" :carrier-id="carrierId" ></SectionInfo>
      </div>
    </template>
    <template v-slot:afterNetworkInfo="{ pageData, carrierId }">
      <div class="w-full">
        <SectionInfo v-if="pageData.extraSection2" :carrier-data="pageData.extraSection2" :carrier-id="carrierId" ></SectionInfo>
        <div v-if="pageData.extraSection3" class="w-full pb-16 -mt-6">
          <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
            <div class="text-center" v-html="pageData.extraSection3.content"></div>
          </div>
        </div>
        <SectionInfo v-if="pageData.extraSection4" :carrier-data="pageData.extraSection4" :carrier-id="carrierId" ></SectionInfo>
      </div>
    </template>
    <template v-slot:afterFinalSection="{ pageData, carrierId }">
      <div class="w-full" v-if="pageData.extraSection5">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <ImageSection v-if="pageData.extraSection5.imageSection" :content="pageData.extraSection5.imageSection" :carrier-id="carrierId" ></ImageSection>
        </div>
      </div>
    </template>
  </CarrierPlan>
</template>
