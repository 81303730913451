<script setup lang="ts">
import { CarrierList, PrimaryButton } from '@hfc/ui-core';
import { UseCarrierDataStore } from '@/page/infrastructure/composition/UseCarrierDataStore';
import CarrierPlan from '@/page/infrastructure/views/CarrierPlan.vue';
import BannerCarrier from '@/page/infrastructure/components/carrier/BannerCarrier.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import SectionInfo from '@/page/infrastructure/components/carrier/SectionInfo.vue';
import ImageSection from '@/page/infrastructure/components/carrier/ImageSection.vue';

const { getCdnUrl } = UseUrlHelper();

const props = defineProps<{
  carrierId: CarrierList;
}>();

const { carrierData } = UseCarrierDataStore(props.carrierId);
</script>
<template>
  <CarrierPlan v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId">
    <template v-slot:afterSectionInfo="{ pageData, carrierId }">
      <div v-if="pageData.extraSection1" class="w-full py-16 bg-slate-100">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-6" v-html="pageData.extraSection1.title"></h2>
          <div :class="pageData.extraSection1.textDirection ? pageData.extraSection1.textDirection : 'text-center'" v-html="pageData.extraSection1.content"></div>
          <BannerCarrier v-if="pageData.extraSection1.imageSection" :content="pageData.extraSection1.imageSection"></BannerCarrier>
          <div>
            <div v-for="(item, index) in pageData.extraSection1.subContent" class="flex flex-row">
              <h3 class="mb-2">{{ index + 1 }}.&nbsp;</h3>
              <div class="flex flex-1 flex-col">
                <h3 class="mb-2" v-html="item.title"></h3>
                <div class="mb-6" v-html="item.content"></div>
              </div>
            </div>
          </div>
          <div :class="pageData.extraSection1.textDirection ? pageData.extraSection1.textDirection : 'text-center'" v-html="pageData.extraSection1.secondContent"></div>
        </div>
      </div>
      <div v-if="pageData.extraSection2" class="w-full py-16">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-6" v-html="pageData.extraSection2.title"></h2>
          <div :class="pageData.extraSection2.textDirection ? pageData.extraSection2.textDirection : 'text-center'" v-html="pageData.extraSection2.content"></div>
          <div v-if="pageData.extraSection2.imageSection" class="w-full overflow-hidden">
            <div v-if="pageData.extraSection2.imageSection.imageUrl" class="relative">
              <img class="absolute min-w-[1000px] left-1/2 transform -translate-x-1/2" :src="getCdnUrl(pageData.extraSection2.imageSection.imageUrl)">
            </div>
            <div class="relative flex flex-col sm:flex-row">
              <div class="flex flex-col px-8 md:px-16 py-8 justify-center">
                <div v-if="pageData.extraSection2.imageSection.title" class="flex flex-col space-y-6">
                  <span class="font-semibold text-2xl sm:text-2xl lg:text-4xl text-white" v-html="pageData.extraSection2.imageSection.title"></span>
                </div>
                <div v-if="pageData.extraSection2.imageSection.titleSeparator" class="w-full lg:bg-transparent pt-4 pb-6">
                  <div class="h-1 w-24 rounded-full bg-yellow"></div>
                </div>
              </div>
              <div class="flex flex-1 flex-col px-8 py-8 bg-white sm:bg-transparent">
                <div v-for="(item, index) in pageData.extraSection2.imageSection.subContent" class="flex flex-row ml-3 space-y-2 items-center">
                  <div class="w-6 h-6 md:w-8 md:h-9 bg-yellow pl-2 mr-2">
                    <span class="text-md md:text-2xl lg:text-2xl font-bold text-blue-500 mb-2">{{ index + 1 }}.&nbsp;</span>
                  </div>
                  <div class="flex flex-1 flex-col">
                    <span class="mb-2 text-md md:text-2xl lg:text-2xl text-blue-500 sm:text-white" v-html="item.title"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div v-for="(item, index) in pageData.extraSection2.subContent" class="flex flex-row">
              <span class="text-blue-500 font-bold">{{ index + 1 }}.&nbsp;</span>
              <div class="flex flex-1 flex-col">
                <div class="mb-6" v-html="item.content"></div>
              </div>
            </div>
          </div>
          <div :class="pageData.extraSection2.textDirection ? pageData.extraSection2.textDirection : 'text-center'" v-html="pageData.extraSection2.secondContent"></div>
        </div>
      </div>
    </template>
    <template v-slot:NetworkInfo="{ carrierData, carrierId }">
      <SectionInfo v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId"></SectionInfo>
    </template>
    <template v-slot:afterNetworkInfo="{ pageData }">
      <div v-if="pageData.extraSection3" class="w-full py-16">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-6" v-html="pageData.extraSection3.title"></h2>
          <div :class="pageData.extraSection3.textDirection ? pageData.extraSection3.textDirection : 'text-center'" v-html="pageData.extraSection3.content"></div>
          <div>
            <div v-for="item in pageData.extraSection3.subContent" class="flex flex-col ml-3">
              <div class="flex flex-1 flex-col">
                <h3 class="mb-2" v-html="item.title"></h3>
              </div>
              <div v-for="data in item.list" class="flex flex-row">
                <div class="relative mt-3 md:mt-2 mr-2 rounded-full bg-yellow-500 w-2 h-2 md:w-3 md:h-3"></div>
                <div class="flex flex-1 flex-col">
                  <div class="mb-2" v-html="data"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:afterFinalSection="{ pageData }">
      <div v-if="pageData.extraSection4" class="w-full py-16">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-6" v-html="pageData.extraSection4.title"></h2>
          <div :class="pageData.extraSection4.textDirection ? pageData.extraSection4.textDirection : 'text-center'" v-html="pageData.extraSection4.content"></div>
          <div>
            <div v-for="item in pageData.extraSection4.subContent" class="flex flex-row ml-3">
              <div class="relative mt-3 md:mt-2 mr-2 rounded-full bg-yellow-500 w-2 h-2 md:w-3 md:h-3"></div>
              <div class="flex flex-1 flex-col">
                <h3 class="mb-2" v-html="item.title"></h3>
                <div class="mb-6" v-html="item.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="pageData.extraSection5" class="w-full py-16 bg-slate-100">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-6" v-html="pageData.extraSection5.title"></h2>
          <div :class="pageData.extraSection5.textDirection ? pageData.extraSection5.textDirection : 'text-center'" v-html="pageData.extraSection5.content"></div>
          <ImageSection v-if="pageData.extraSection5.imageSection" :content="pageData.extraSection5.imageSection">
            <template #content="{ content }">
              <div :class="[content.contentWidth, content.textDirection]">
                <img class="w-40 md:w-52 lg:w-72" :src="getCdnUrl('/assets/img/logos/hfc.png')" alt="Health For California"/>
                <h2 class="mt-4 text-lg md:text-2xl lg:text-4xl" v-html="content.title"></h2>
                <div v-if="content.redirectButtonUrl" class="flex mt-6">
                  <PrimaryButton class="text-sm lg:text-md uppercase" size="lg" :href="content.redirectButtonUrl">{{ content.redirectButtonText }}</PrimaryButton>
                </div>
              </div>
            </template>
          </ImageSection>
          <div :class="pageData.extraSection5.textDirection ? pageData.extraSection5.textDirection : 'text-center'" v-html="pageData.extraSection5.secondContent"></div>
        </div>
      </div>
    </template>
  </CarrierPlan>
</template>
