import { CarrierPage } from '@/page/domain/CarrierPage';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getQuoteUrl } = UseUrlHelper();

export const cchpPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: 'Balance by Chinese <br> Community Health Plan',
    content: 'Find the Lowest Prices Available on Chinese Community Medical Insurance Plans or Get Discounts Through Our Covered California Options'
  },
  aboutInfo: {
    visible: true,
    title: 'About Chinese Community Health Insurance',
    content: '<p>Chinese Community Health Plan is diverse as it is unique. They are a non-profit health plan with an integrated delivery system with over 27,000 members. Their mission is to “…improve the health of our community by delivering high-quality, affordable healthcare through culturally competent and linguistically appropriate services.” The Chinese Community Health Plan took root over 30 years ago serving the Chinese-American community in San Francisco. Today they serve all consumers. They are proud to be one of five carriers within the San Francisco and San Mateo counties that offer coverage through the California State Exchange: Covered California. Over 60 percent of CCHP’s members enrolled through Covered California identify themselves as Chinese.</p>'
  },
  sectionInfo: {
    visible: true,
    title: 'Take Note',
    textDirection: 'text-left',
    content: `<p>The <a class="underline" href="https://www.dmhc.ca.gov/" target="_blank">California Department of Managed Health Care</a> (DMHC) in 2014 awarded Chinese Community Health Plan the Bronze Award for the prevention of heart attacks, strokes and diabetic complications.</p><br>
    <p>Chinese Community Health Plan successfully completed an <a class="underline" href="https://www.ncqa.org/ReportCards/HealthPlans/HealthInsurancePlanRatings/NCQAHealthInsurancePlanRatings2015.aspx" target="_blank">NCQA</a> Certified HEDIS compliance audit in 2013.</p>`,
    imageUrl: '/assets/img/carriers/cchp-doctor-and-old-woman.webp'
  },
  networkInfo: {
    visible: true,
    title: 'Network',
    contentDirection: 'right',
    textDirection: 'text-left',
    content: `<p>CCHP partners with 10 hospitals, many clinics, and over 300 neighborhood doctors and specialists within the Bay Area.</p><br>
    <p>The network of hospitals include:</p><br>`,
    listContent: {
      list: [
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><a class="underline" href="https://cchphealthplan.com/provider-details/1871731810-848/?zip_code=94117" target="_blank">California Pacific Medical Center - California Campus</a></div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><a class="underline" href="https://cchphealthplan.com/provider-details/1316061997-2222/?zip_code=94117" target="_blank">St. Francis Memorial Hospital</a></div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><a class="underline" href="https://www.sutterhealth.org/mills" target="_blank">Mills-Peninsula Medical Center</a></div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><a class="underline" href="https://cchphealthplan.com/provider-details/1871731810-848/?zip_code=94117" target="_blank">California Pacific Medical Center - California West Campus</a></div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><a class="underline" href="https://cchphealthplan.com/provider-details/1154428688-1728/?zip_code=94115" target="_blank">Seton Medical Center - Coastside</a></div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><a class="underline" href="https://cchphealthplan.com/provider-details/1992898837-483/" target="_blank">Chinese Hospital</a></div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><a class="underline" href="https://cchphealthplan.com/provider-details/1659439834-2071/?zip_code=94117" target="_blank">California Pacific Medical Center - Davies Campus</a></div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><a class="underline" href="https://cchphealthplan.com/provider-details/1174667158-141/?zip_code=94117" target="_blank">St. Mary\'s Medical Center</a></div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><a class="underline" href="https://cchphealthplan.com/provider-details/1154428688-1728/?zip_code=94115" target="_blank">Seton Medical Center</a></div>',
        '<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>California Pacific Medical Center - St. Luke\'s Campus</div>',
      ],
    },
    secondContent: '<p>To view a list of providers, please click <a class="underline" href="https://cchphealthplan.com/provider-search/" target="_blank">here</a>.</p>',
    imageUrl: '/assets/img/carriers/cchp-clipboard.webp',
    imageWidth: 'w-48 lg:w-full',
    bgColor: 'bg-slate-100',
  },
  finalSection: {
    visible: true,
    contentDirection: 'right',
    title: 'The Future is Here',
    content: `<p>Following suite with health care of today, CCHP offers a 24/7 nurse advice phone number so members can receive professional help when a doctor is not available.</p><br>
    <p>Language assistance is offered to members whose need assistance in traditional Chinese, Cantonese and Mandarin. Through community fairs and events CCHP provides informational seminars in English, English-Chinese, (Cantonese and Mandarin), and English-Spanish. You can find more information on this by viewing their <a class="underline" href="https://www.cchrchealth.org/" target="_blank">Health Resource Center</a>.</p>`,
    imageSection: {
      title: 'Contact <b>Health for California</b> to Receive A Quote for the Chinese Community Health Plan',
      contentWidth: 'w-2/3 sm:w-1/2',
      justifyContent: 'items-start',
      textDirection: 'text-left',
      redirectButtonText: 'Get A Quote',
      redirectButtonUrl: getQuoteUrl(),
      imageUrl: '/assets/img/carriers/cchp-final-banner.webp',
    },
  }
};
