import { MetalPlanPageSection } from '@/page/domain/MetalPlanPageSection';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl } = UseUrlHelper();
export const silver70PageData: Partial < MetalPlanPageSection > = {
  initialBanner: {
    visible: true,
    title: 'Covered California',
    subtitleColor: 'text-slate-400',
  },
  highlightSection: {
    visible: true,
    content: `<p>The Covered California Silver 70 Plan offers a <b>middle-of-the-road option</b> for people who want affordable health insurance as well as some good benefits. Under this plan, popular services like office visits, generic drugs, x-rays and labs are covered at a flat fee (copay). A small <b>drug deductible</b> will apply for brand name, non-preferred brand name and specialty drugs. On this plan there is a significant medical deductible, but it only applies to the higher cost and less commonly-needed services, like hospitalization. To know your price for the Silver 70 Plan and other metal plans, click <a href="${getBaseUrl('')}">Affordable Health Insurance for California</a>.<p>`,
    imageUrl: '/assets/img/bg/silver_70_a.webp',
    redirectText: 'See All Silver Plans',
    redirectUrl: getBaseUrl('/covered-california/plans/silver')
  },
  infoBanner: {
    visible: true,
    title: 'Bronze Plan vs Silver 70 Plan',
    content: `<p>The <a class="text-white underline" href="${getBaseUrl('/covered-california/plans/bronze')}">Bronze Plan</a> has the lowest premium of all the metal plans with the exception of the <a class="text-white underline" href="${getBaseUrl('/covered-california/plans/minimum-coverage')}">Minimum Coverage Plan</a>. The price is right. But with a larger deductible for many services, it might not be the best plan if you frequently go in for doctor visits or particularly if you want copays right from the start for services such as x-rays, imaging, or emergency room visits. If you like immediate discounts for these services, or if you use many specialists or brand name drugs, it may be more cost-effective to sign up for a Silver 70 Plan where your cumulative savings for out-of-pocket costs could be worth it to pay for the more expensive plan. So in the long run, you could save money on a Silver 70 Plan. To verify, do the math. <b>Compare the premiums and the out-of-pocket costs</b> for the Bronze and Silver 70 plans to determine which best-fits your budget and health care needs.</p>`,
  },
  textSection: {
    visible: true,
    title: 'Is the Silver 70 Right for me?',
    subtitle: 'This plan may be a good fit if:',
    contentMode: 'right',
    subContent: [
      '<p>You are relatively healthy.</p>',
      '<p>If you want copays (a set fee) right away without having to pay a deductible for common services such as doctors, labs, generic drugs, x-rays, imaging, and even the emergency room.</p>',
      '<p>You want more coverage than the Bronze 60 plan, but want a lower premium per month than the Gold Plan.</p>'
    ],
  },
  metalPlansSection: {
    visible: true,
    title: 'What Types of Health Plans are Available',
    redirectUrl: '/covered-california/plans'
  },
  googleReviewsSection: {
    visible: true,
    title: 'Customer Reviews',
    imageUrl: '/assets/img/bg/guys_working.webp'
  },
  certifiedBanner: {
    visible: true,
  },
  twoColumnSection: {
    visible: true,
    title: 'A Little More to Think About',
    content: '<p>The Silver 70 Plan is by far one of the most popular plans that Covered California offers. This is because it has competitively priced monthly premiums and upfront copays with no deductibles required for many, many services. It is definitely worth your time considering this plan as well as checking to see if you might qualify for one of the income based Enhanced Silver Plans which have even lower out-of- pocket costs. Check out the link below for more information.',
    imageUrl: '/assets/img/bg/silver_70_b.png',
    contentDirection: 'right'
  },
  finalBanner: {
    visible: true,
    title: 'A LITTLE MORE TO THINK ABOUT',
    imageUrl: '/assets/img/bg/doctor_and_patient_faded.png',
    redirectUrl: '/covered-california/plans/silver'
  }
};
