<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
    divClass?: string;
    textClass?: string;
    imageClass?: string;
    contentDirection?: 'left' | 'right';
  }>(), {
  textClass: 'flex flex-col flex-1',
  imageClass: 'flex lg:w-2/5',
  contentDirection: 'left',
});

const textSection = computed(() => props.contentDirection === 'left' ? 'order-2 lg:order-1 lg:pr-10' : 'order-2 lg:order-2 lg:pl-10');
const imageSection = computed(() => props.contentDirection === 'left' ? 'order-1 lg:order-2' : 'order-1 lg:order-1');
</script>

<template>
  <div class="flex w-full flex-col lg:flex-row max-w-7xl mx-auto relative py-16" :class="divClass">
    <div :class="[textClass, textSection]">
      <slot name="text"></slot>
    </div>
    <div v-if="$slots.image" :class="[imageClass, imageSection]">
      <slot name="image"></slot>
    </div>
  </div>
</template>
