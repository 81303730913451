
<script setup lang="ts">
import { AltPrimaryButton, LinkButton, scroll, WCEvents } from '@hfc/ui-core';
import { ref, onBeforeMount, computed, inject, provide } from 'vue';
import { PopoverGroup } from '@headlessui/vue';
import { CustomQuoteButtonConfig } from '@/page/domain/CustomQuoteButtonConfig';
import BrandingLogos from '@/shared/infrastructure/components/heading/BrandingLogos.vue';
import HeadingMobileMenu from '@/shared/infrastructure/components/heading/HeadingMobileMenu.vue';
import HeadingNavItem from '@/shared/infrastructure/components/heading/HeadingNavItem.vue';
import CallUsButton from '@/shared/infrastructure/components/heading/CallUsButton.vue';
import SearchBox from '@/shared/infrastructure/components/heading/SearchBox.vue';
import OverlayBackdrop from '@/shared/infrastructure/components/heading/OverlayBackdrop.vue';
import GetNewQuoteButton from '@/shared/infrastructure/components/heading/GetNewQuoteButton.vue';
import { MenuItem } from '@/shared/domain/MenuItem';
import { AppStore } from '@/shared/infrastructure/store/AppStore';
import { CarrierInterface } from '@/page/domain/CarrierInterface';
import { MenuPresets } from '@/shared/domain/MenuPresets';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

let appStore = inject<AppStore>('AppStore') as AppStore;
if (!appStore) {
  appStore = new AppStore();
  provide('AppStore', appStore);
}

const { getBaseUrl } = UseUrlHelper();
const { emitVanillaJSCompatible } = WCEvents();

const props = defineProps<{
  title?: string,
  preset?: string,
  subTitle?: string,
  slotContent?: boolean,
  slotAfterHeader?: boolean,
  hideNavigation?: boolean,
  hideQuoteButton?: boolean,
  hideSearch?: boolean,
  phoneForCurrentRoute: string,
  forceSmallHeight?: boolean,
  customQuoteLink?: string,
  carrier?: string,
}>();

const headingElement = ref();
const customLinkConfig: CustomQuoteButtonConfig = props.customQuoteLink ? JSON.parse(props.customQuoteLink) : {};
const getQuoteText = customLinkConfig.label ?? 'Shop & compare';

const carrier = computed(() => (props.carrier) ? JSON.parse(props.carrier) as CarrierInterface : null);

const { scrollValue } = scroll();
const isScrolling = computed(() => {
  return scrollValue.value > 120;
});

const showHomeButton = computed(() => props.preset === MenuPresets.MEDICARE);

const menuItems = ref([] as Array<MenuItem>);
const navItem = ref([]);
function closeAll (): void {
  const items: Array<typeof HeadingNavItem> = navItem.value;
  for (let i = 0; i < items.length; i++) {
    const element = items[i];
    element.onClickOutside();
  }
}

const triggerCustomEvent = (): void => {
  emitVanillaJSCompatible('shopAndCompare', headingElement.value, customLinkConfig);
};

onBeforeMount(async () => {
  const menuPreset = props.preset || '';
  const response = await appStore.getHeaderItems(menuPreset);
  if (response.success && response.data) {
    menuItems.value = response.data;
  }
});

defineExpose({
  closeAll
});
</script>
<template>
  <div>
    <div class="shadow-sm dark:shadow-none" ref="headingElement">
      <div class="sticky z-50 top-0 bg-white dark:bg-gradient-to-r dark:from-blue-900 dark:to-blue-500">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 py-0">
          <div class="flex justify-between md:justify-start heading md:py-0 transition-all duration-500" :class="{ 'h-[60px] md:h-[80px]': isScrolling || forceSmallHeight, 'h-[112px]': !(isScrolling || forceSmallHeight) }">
            <BrandingLogos :carrier-id="carrier?.codename" class="items-center" />
            <slot name="content">
              <PopoverGroup as="nav" class="hidden md:flex md:justify-end md:m-0 md:flex-1" v-if="!hideNavigation">
                <HeadingNavItem ref="navItem" @closeOthers="closeAll" v-for="menuItem in menuItems" :key="menuItem.label" :item="menuItem" :small-height="isScrolling || forceSmallHeight" :class="{ 'item-small-height': isScrolling || forceSmallHeight }" />
              </PopoverGroup>
            </slot>
            <div v-if="!hideSearch || phoneForCurrentRoute || !hideQuoteButton || showHomeButton" class="flex items-center justify-end flex-grow  overflow-visible mt-[-2px]" :class="{ 'md:flex-grow-0': !hideNavigation || slotContent }">
              <OverlayBackdrop v-if="!hideSearch">
                <template v-slot:trigger>
                  <div class="ml-3 flex">
                    <hfc-icon class="text-2xl hover:text-blue-300 dark:text-white text-blue flex w-6 h-6" icon="search" />
                  </div>
                </template>
                <template v-slot:content>
                  <SearchBox />
                </template>
              </OverlayBackdrop>
              <div class="ml-3 hidden md:flex" v-if="showHomeButton">
                <LinkButton :href="getBaseUrl('/')">
                  <hfc-icon class="text-2xl hover:text-blue-300 dark:text-white text-blue flex w-7" icon="house" />
                </LinkButton>
              </div>
              <div class="ml-3 flex" v-if="phoneForCurrentRoute">
                <CallUsButton :phone-number="phoneForCurrentRoute" class="w-6" />
              </div>
              <div class="hidden md:flex ml-3" v-if="!hideQuoteButton">
                <GetNewQuoteButton v-if="!customLinkConfig.modalEvent" :url="customLinkConfig.url" :targetBlank="customLinkConfig.blank" :variant="true" :outline="true" class="w-full whitespace-nowrap text-base">
                  {{ getQuoteText }} <hfc-icon icon="arrow-right" class="ml-1 lg:ml-3 w-4 flex" />
                </GetNewQuoteButton>
                <AltPrimaryButton v-else @click="triggerCustomEvent" :variant="true" :outline="true" class="w-full whitespace-nowrap text-base">
                  {{ getQuoteText }} <hfc-icon icon="arrow-right" class="ml-1 lg:ml-3 w-4 flex" />
                </AltPrimaryButton>
              </div>
            </div>
            <slot name="title" class="ml-5">
              <div v-if="title || subTitle" class="flex items-end flex-grow flex-col">
                <h1 class="dark:text-white text-blue">{{ title }}</h1>
                <h2 class="dark:text-white text-blue">{{ subTitle }}</h2>
              </div>
            </slot>
            <HeadingMobileMenu :items="menuItems" v-if="!hideNavigation" />
          </div>
        </div>
        <div class="bg-blue-100 h-14 md:h-11 w-full" v-if="slotAfterHeader">
          <div class="max-w-7xl mx-auto px-4 sm:px-6">
            <slot name="after-header"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
