import { MetalPlanPageSection } from '@/page/domain/MetalPlanPageSection';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl, getQuoteUrl } = UseUrlHelper();
export const silver87PageData: Partial<MetalPlanPageSection> = {
  initialBanner: {
    visible: true,
    title: 'Covered California',
    subtitle: 'Enhanced Plan',
    subtitleColor: 'text-slate-400',
  },
  highlightSection: {
    visible: true,
    content: `<p>The Silver 87 Plan is a health plan that <b>gives qualified members more coverage at lower prices</b>. Someone on a Silver 87 Plan will pay less for medical services than someone who is enrolled on a top-of-the-line <a href="${getBaseUrl('/covered-california/plans/platinum-90')}">Platinum Plan</a>. It gives individuals and families a chance to have affordable health insurance without sacrificing good benefits. To see if you qualify, click <a href="${getQuoteUrl()}">Affordable Health Insurance for California</a> to receive a free quote.</p><br> <p>There is no deductible on this plan, so common services like doctors, labs and prescriptions have low copays right from the start. For higher cost services, like hospitalizations, a member would pay a small percentage (coinsurance) of the bill. More importantly, they would never pay over their out-of-pocket maximum in any one year for in-network covered services.</p>`,
    redirectText: 'See All Silver Plans',
    redirectUrl: '/covered-california/plans/silver'
  },
  textSection: {
    visible: true,
    title: 'Is the Silver 87 Right for me?',
    subtitle: `<p>To qualify for an Enhanced Silver 87 Plan, <a href="${getBaseUrl('/apply-for-health-insurance')}">the application must be submitted through Covered California</a> and the individual must meet <a href="${getBaseUrl('/covered-california/income-limits')}">Covered California income limits</a>. To understand better, review the <a href="${getBaseUrl('/federal-poverty-level')}">Federal Poverty Level Chart</a>. <b>Children 18 and under are not eligible</b> for an enhanced Silver 87 Plan and may qualify for Medi-Cal or can enroll on a plan at the full price.</p>`,
    contentMode: 'right',
    subContent: [
      '<p>More often than not, if you qualify for the Silver 87, it will provide you the best coverage for the price.</p>',
      '<p>Since the service fees and deductible are low, <b>people with serious or chronic health conditions</b> may wish to consider enrolling on a Silver 87 Plan, if they are eligible.</p>',
    ],
  },
  metalPlansSection: {
    visible: true,
    title: 'What Types of Health Plans are Available',
    redirectUrl: '/covered-california/plans'
  },
  googleReviewsSection: {
    visible: true,
    title: 'Customer Reviews',
    imageUrl: '/assets/img/bg/guys_working.webp'
  },
  certifiedBanner: {
    visible: true,
  },
  twoColumnSection: {
    visible: true,
    title: 'A Little More to Think About',
    content: '<p>Your income is a big factor in qualifying for the Silver 87 Plan. If you qualify, take advantage of the great benefits, but realize if your income increases you may have to select a new plan with different benefits that don\'t compare. So if you are putting off a surgery or procedure that you need, <b>do it now while you qualify</b>.</p><br> <p>Note that the coverage benefits may be different when a doctor or provider is not part of the insurance carrier\'s network. For details, consumers are encouraged to check with the carrier or insurance agent for details.</p>',
    imageUrl: '/assets/img/bg/silver_87.png',
    contentDirection: 'right',
  },
  finalBanner: {
    visible: true,
    title: 'A LITTLE MORE TO THINK ABOUT',
    imageUrl: '/assets/img/bg/doctor_and_patient_faded.png',
    redirectUrl: '/contact-us'
  }
};
