<script setup lang="ts">
import { CarrierList, PrimaryButton } from '@hfc/ui-core';
import { UseCarrierDataStore } from '@/page/infrastructure/composition/UseCarrierDataStore';
import CarrierPlan from '@/page/infrastructure/views/CarrierPlan.vue';
import BannerCarrier from '@/page/infrastructure/components/carrier/BannerCarrier.vue';
import SectionInfo from '@/page/infrastructure/components/carrier/SectionInfo.vue';
import TextAndImage from '@/page/infrastructure/components/TextAndImage.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import ImageSection from '@/page/infrastructure/components/carrier/ImageSection.vue';

const { getCdnUrl, getQuoteUrl } = UseUrlHelper();

const props = defineProps<{
  carrierId: CarrierList;
}>();

const { carrierData } = UseCarrierDataStore(props.carrierId);
</script>
<template>
  <CarrierPlan v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId">
    <template v-slot:NetworkInfo="{ carrierData }">
      <div v-if="carrierData" class="w-full py-16" :class="carrierData.bgColor">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 v-html="carrierData.title"></h2>
          <div class="w-full" :class="carrierData.textDirection ? carrierData.textDirection : 'w-full text-center'" v-html="carrierData.content"></div>
          <BannerCarrier v-if="carrierData.imageSection" :content="carrierData.imageSection"></BannerCarrier>
          <div class="flex flex-col w-full">
            <div class="text-blue-500 font-bold text-xl">1. IEHP Medi-Cal</div>
            <p class="ml-5 mt-2">This plan is an option for those who qualify for Medi-Cal. Members <a class="underline" href="https://www.iehp.org/en/browse-plans/medi-cal#plan-overview" target="_blank">do not have to pay a monthly premium</a> and will receive benefits like free
              doctor visits and hospital stays.</p>
          </div>
          <div class="flex flex-col w-full">
            <div class="text-blue-500 font-bold text-xl">2. IEHP DualChoice</div>
            <p class="ml-5 mt-2">DualChoice <a class="underline" href="https://www.iehp.org/en/browse-plans/dualchoice#benefits" target="_blank">combines Medi-Cal and Medicare benefits</a> in a single plan. It's a zero-cost, comprehensive health plan available to qualified residents.</p>
          </div>
          <div class="flex flex-col w-full">
            <div class="text-blue-500 font-bold text-xl">3. EHP Covered</div>
            <p class="ml-5 mt-2">This is IEHP's Covered California option for those not eligible for Medi-Cal. IEHP Covered <a class="underline" href="https://www.iehp.org/en/browse-plans/covered-california#plan-overview" target="_blank">offers low-cost silver plans</a> for qualified individuals,
              some with zero-dollar monthly premiums. It also provides bronze, gold and platinum plans.</p>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:afterNetworkInfo="{ pageData }">
      <div v-if="pageData.extraSection1" class="w-full">
        <SectionInfo v-if="pageData.extraSection1" :carrier-data="pageData.extraSection1" :carrier-id="carrierId"></SectionInfo>
      </div>
      <div v-if="pageData.extraSection2" class="w-full">
        <SectionInfo v-if="pageData.extraSection2" :carrier-data="pageData.extraSection2" :carrier-id="carrierId"></SectionInfo>
      </div>
      <div v-if="pageData.extraSection3" class="w-full">
        <SectionInfo v-if="pageData.extraSection3" :carrier-data="pageData.extraSection3" :carrier-id="carrierId"></SectionInfo>
      </div>
    </template>
    <template v-slot:FinalSection="{ carrierData }">
      <div v-if="carrierData" class="w-full" :class="carrierData.bgColor">
        <TextAndImage div-class="px-6" :content-direction="carrierData.contentDirection" :text-class="!carrierData.imageUrl ? 'w-full' : 'flex flex-col lg:w-3/5'">
          <template #text>
            <div class="flex flex-col h-full items-start justify-center mt-10 lg:mt-0" :text-class="!carrierData.imageUrl ? 'flex-1' : ''">
              <h2 class="mb-6" v-html="carrierData.title"></h2>
              <div :class="carrierData.textDirection ? carrierData.textDirection : 'text-center'" v-html="carrierData.content"></div>
              <PrimaryButton v-if="carrierData.redirectUrl" class="text-sm mt-6 lg:text-md uppercase" size="lg" :href="getQuoteUrl()">{{ carrierData.redirectButtonText }}</PrimaryButton>
            </div>
          </template>
          <template #image v-if="carrierData.imageUrl">
            <div class="flex flex-1 justify-center">
              <img class="flex max-h-96 rounded" :src="getCdnUrl(carrierData.imageUrl)">
            </div>
          </template>
        </TextAndImage>
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative px-6">
          <ImageSection v-if="carrierData.imageSection" class="-mt-6 mb-16" :content="carrierData.imageSection"></ImageSection>
        </div>
      </div>
    </template>
  </CarrierPlan>
</template>
