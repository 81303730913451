import { usePlocState } from '@hfc/ui-core';
import { inject, onMounted, ref, provide, Ref, computed, ComputedRef } from 'vue';
import { PageStore } from '@/page/infrastructure/store/PageStore';
import { MetalPlanPageSection } from '@/page/domain/MetalPlanPageSection';
import { MetalPlanData } from '@/page/domain/MetalPlanData';

interface UseMetallicPlansStoreInterface {
  metalPlans: ComputedRef<Array<MetalPlanData>>;
  metalPlanPageSections: Ref<Partial<MetalPlanPageSection>>;
}

export const UseMetallicPlansStore = (planName: string): UseMetallicPlansStoreInterface => {
  let pageStore = inject<PageStore>('PageStore') as PageStore;

  if (!pageStore) {
    pageStore = new PageStore();
    provide('PageStore', pageStore);
  }
  const pageState = usePlocState(pageStore);

  const metalPlanPageSections = ref<Partial<MetalPlanPageSection>>({});

  const metalPlans = computed(() => pageState.value.metalPlans as Array<MetalPlanData>);

  onMounted(async () => {
    await pageStore.getMetalPlans();
    const response = await pageStore.getMetalPlanPageSections(planName);
    if (response.success && response.data) {
      metalPlanPageSections.value = response.data;
    }
  });

  return {
    metalPlans,
    metalPlanPageSections
  };
};