<script setup lang="ts">
import { UseCarrierDataStore } from '@/page/infrastructure/composition/UseCarrierDataStore';
import CarrierPlan from '@/page/infrastructure/views/CarrierPlan.vue';
import ImageSection from '@/page/infrastructure/components/carrier/ImageSection.vue';
import BoxContent from '@/page/infrastructure/components/carrier/BoxContent.vue';
import { CarrierList, PrimaryButton } from '@hfc/ui-core';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl, getQuoteUrl, getCdnUrl } = UseUrlHelper();

const props = defineProps<{
  carrierId: CarrierList;
}>();

const { carrierData } = UseCarrierDataStore(props.carrierId);
</script>
<template>
  <CarrierPlan v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId">
    <template v-slot:NetworkInfo="{ carrierData, carrierId }">
      <div v-if="carrierData" class="w-full py-16 bg-slate-100">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-6" v-html="carrierData.title"></h2>
          <div :class="carrierData.textDirection ? carrierData.textDirection : 'text-center'" v-html="carrierData.content"></div>
          <ImageSection v-if="carrierData.imageSection" :content="carrierData.imageSection">
            <template #content="{ content }">
              <div class="text-center" :class="[content.contentWidth, content.textDirection]">
                <span class="text-white text-lg md:text-2xl lg:text-4xl" v-html="content.title"></span>
                <div class="flex flex-col mt-4 lg:flex-row w-full px-12 pt-6 lg:pt-0">
                  <div v-for="boxContent in content.multipleBoxContent" class="flex w-full items-center flex-col px-4 lg:first:border-r">
                    <h5 class="text-white py-4" v-html="boxContent.title"></h5>
                    <div class="flex flex-wrap w-full lg:flex-row justify-center">
                      <div v-for="boxData in boxContent.boxData" class="flex w-full sm:w-1/3">
                        <BoxContent :content="boxData"></BoxContent>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="content.redirectButtonUrl" class="flex w-full justify-center mt-6">
                  <PrimaryButton class="text-sm lg:text-md" size="lg" :href="content.redirectButtonUrl">{{ content.redirectButtonText }}</PrimaryButton>
                </div>
              </div>
            </template>
          </ImageSection>
          <div :class="carrierData.textDirection ? carrierData.textDirection : 'text-center'" v-html="carrierData.secondContent"></div>
        </div>
      </div>
    </template>
    <template v-slot:afterFinalSection="{ pageData }">
      <div v-if="pageData.extraSection1" class="w-full py-16">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <ImageSection v-if="pageData.extraSection1.imageSection" :content="pageData.extraSection1.imageSection"></ImageSection>
        </div>
      </div>
    </template>
  </CarrierPlan>
</template>
