<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
    backgroundClass?: string;
    contentClass?: string;
    textClass?: string;
    imageClass?: string;
    contentDirection?: 'left' | 'right';
  }>();

const textSection = computed(() => props.contentDirection === 'left' ? 'order-1 lg:order-1' : 'order-2 lg:order-2');
const imageSection = computed(() => props.contentDirection === 'left' ? 'order-2 lg:order-2' : 'order-2 lg:order-1');
</script>

<template>
  <div class="flex w-full" :class="backgroundClass">
    <div class="flex flex-1 flex-col lg:flex-row max-w-7xl mx-auto px-6 relative" :class="contentClass">
      <div v-if="$slots.text" class="flex" :class="[textClass, textSection]">
        <slot name="text"></slot>
      </div>
      <div class="flex" :class="[imageClass, imageSection]">
        <slot name="image"></slot>
      </div>
    </div>
  </div>
</template>
