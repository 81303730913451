import { PhoneSetting } from '@/page/domain/PhoneSetting';
import { ApplicationSetting } from '@/page/domain/ApplicationSetting';

export interface GlobalSetting {
  feedback: {
    globalFeedbackButton: {
      enabled: boolean
    }
  },
  phone: {
    [PHONE_TYPES.OFFICE]: PhoneSetting,
    [PHONE_TYPES.SENIOR]: PhoneSetting,
    [PHONE_TYPES.PRIORITY]: PhoneSetting,
  },
  applications: {
    [APPLICATION_TYPES.INDIVIDUAL_AND_FAMILY]: ApplicationSetting,
    [APPLICATION_TYPES.CHILD_ONLY]: ApplicationSetting,
    [APPLICATION_TYPES.SENIOR]: ApplicationSetting,
    [APPLICATION_TYPES.SMALL_GROUP]: ApplicationSetting,
    [APPLICATION_TYPES.VISION]: ApplicationSetting,
    [APPLICATION_TYPES.DENTAL]: ApplicationSetting,
    [APPLICATION_TYPES.MEDI_CAL]: ApplicationSetting,
  }
}

export enum APPLICATION_TYPES {
  INDIVIDUAL_AND_FAMILY = 'individualAndFamily',
  CHILD_ONLY = 'childOnly',
  SENIOR = 'senior',
  SMALL_GROUP = 'smallGroup',
  VISION = 'vision',
  DENTAL = 'dental',
  MEDI_CAL = 'mediCal',
}

export enum PHONE_TYPES {
  OFFICE = 'office',
  SENIOR = 'senior',
  PRIORITY = 'priority',
}
