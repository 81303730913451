<script setup lang="ts">
import { PrimaryButton } from '@hfc/ui-core';
import { ImageSection } from '@/page/domain/CarrierPageData';
import BoxContainer from '@/page/infrastructure/components/carrier/BoxContainer.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl, getQuoteUrl } = UseUrlHelper();

defineProps<{
  content: Partial<ImageSection>
}>();
</script>
<template>
  <div class="w-full overflow-hidden">
    <div v-if="content.imageUrl" class="relative">
      <img class="absolute min-w-[1000px] left-1/2 transform -translate-x-1/2" :src="getCdnUrl(content.imageUrl)">
    </div>
    <div class="relative flex flex-col">
      <div v-if="content.title" class="flex flex-col text-center space-y-6 px-8 py-8" :class="[content.textDirection, content.justifyContent]">
        <h1 v-html="content.title" :class="content.titleColor"></h1>
      </div>
      <div v-if="content.titleSeparator" class="w-full bg-white lg:bg-transparent pb-6">
        <div class="h-1 w-16 rounded-full mx-auto" :class="content.titleSeparatorColor"></div>
      </div>
      <div class="flex flex-col lg:flex-row px-12 bg-white lg:bg-transparent pt-6 lg:pt-0">
        <BoxContainer v-for="item in content.boxContent" :content="item"></BoxContainer>
      </div>
      <div v-if="content.redirectButtonText" class="flex justify-center mt-4 mb-8">
        <PrimaryButton class="text-sm lg:text-md" size="lg" :href="getQuoteUrl()">{{ content.redirectButtonText }}</PrimaryButton>
      </div>
    </div>
  </div>
</template>