<script setup lang="ts">
import { CarrierList } from '@hfc/ui-core';
import { UseCarrierDataStore } from '@/page/infrastructure/composition/UseCarrierDataStore';
import CarrierPlan from '@/page/infrastructure/views/CarrierPlan.vue';
import ImageSection from '@/page/infrastructure/components/carrier/ImageSection.vue';
import SectionInfo from '@/page/infrastructure/components/carrier/SectionInfo.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import DollarAlertIcon from '@/page/infrastructure/components/carrier/DollarAlertIcon.vue';
import HandOnHeartIcon from '@/page/infrastructure/components/carrier/HandOnHeartIcon.vue';
import PersonOnCircleIcon from '@/page/infrastructure/components/carrier/PersonOnCircleIcon.vue';
import HeartIcon from '@/page/infrastructure/components/carrier/HeartIcon.vue';
import PercentageDownIcon from '@/page/infrastructure/components/carrier/PercentageDownIcon.vue';
import StethoscopeIcon from '@/page/infrastructure/components/carrier/StethoscopeIcon.vue';
import PersonsCircleIcon from '@/page/infrastructure/components/carrier/PersonsCircleIcon.vue';
import DoctorCrossIcon from '@/page/infrastructure/components/carrier/DoctorCrossIcon.vue';
import DollarOnHandIcon from '@/page/infrastructure/components/carrier/DollarOnHandIcon.vue';

const { getCdnUrl, getBaseUrl } = UseUrlHelper();

const props = defineProps<{
  carrierId: CarrierList;
}>();

const { carrierData } = UseCarrierDataStore(props.carrierId);

const benefitsBackgroundImage = {
  imageUrl: '/assets/img/carriers/blueshield-benefits-background.webp',
};

const benefitsContent = [
  { title: 'Improve Your Health With Wellvolution', IconComponent: HeartIcon },
  { title: 'Enjoy Discount Programs', IconComponent: PercentageDownIcon },
  { title: 'Contact Nurses or Doctors Anytime', IconComponent: StethoscopeIcon },
  { title: 'Access a Large Network', IconComponent: PersonsCircleIcon },
  { title: 'Use Preventive Care', IconComponent: DoctorCrossIcon },
  { title: 'Get an Affordable Plan', IconComponent: DollarOnHandIcon },
];

const networkBannerImage = {
  imageUrl: '/assets/img/carriers/blueshield-network-banner.webp',
};

</script>
<template>
  <CarrierPlan v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId">
    <template v-slot:afterSectionInfo="{ pageData }">
      <div v-if="pageData.extraSection1" class="w-full py-16" :class="pageData.extraSection1.bgColor">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-2 text-2xl lg:text-3xl" v-html="pageData.extraSection1.title"></h2>
          <div class="w-full" :class="pageData.extraSection1.textDirection ? pageData.extraSection1.textDirection : 'w-full text-center'">
            You can access the following perks and features as a Blue Shield member.
          </div>
          <div class="mt-8 lg:px-28">
            <ImageSection class="bg-white" :content="benefitsBackgroundImage">
              <template #content>
                <div class="flex flex-col lg:px-10 pt-16">
                  <h2 class="text-lg md:text-2xl lg:text-4xl font-semibold mt-96">What Are the Benefits of Blue Shield?</h2>
                  <div class="flex my-8 h-1 w-20 bg-teal-400 rounded-full"></div>
                  <div class="flex flex-wrap w-full mt-4 lg:pt-0">
                    <div v-for="item in benefitsContent" class="flex flex-row w-full md:w-1/2 items-center py-0 md:py-4">
                      <div class="flex flex-row items-center">
                        <component :is="item.IconComponent" class="w-8 sm:w-12 lg:w-16" />
                        <span class="text-sm sm:text-md md:text-lg lg:text-xl ml-2 text-slate-500">{{ item.title }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </ImageSection>
          </div>

          <div class="flex flex-col">
            <div class="text-blue-500 font-bold text-xl">1. Improve Your Health With Wellvolution</div>
            <div class="ml-5 mt-2">
              <p>Wellvolution is a <a class="underline" href="https://wellvolution.com/faq/#:~:text=A%3A%20Wellvolution%20is%20a%20Blue,that%20is%20right%20for%20you." target="_blank">Blue Shield of California health platform</a> that provides a selection of lifestyle tools, programs and apps. This feature helps you be proactive about your health. It can help <a class="underline" href="https://www.blueshieldca.com/wellness/member-exclusives/discounts-rewards" target="_blank">prevent and reverse disease</a> through a lifestyle-based approach. Improving aspects of your lifestyle, such as diet, social support, stress reduction and exercise, can allow you to stay healthy and become the best version of yourself. The core of Wellvolution is that lifestyle can be your medicine.</p><br>
              <p>With Wellvolution, you can:</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p>Reverse existing conditions.</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p>Eat better.</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p>Manage stress.</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p>Lose weight.</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p>Become more active.</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p>Improve sleep habits.</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p>Quit smoking.</p>
            </div>
            <div class="ml-5 mt-2">
              <p>Join the hundreds of thousands of Wellvolution members to enjoy these benefits.</p>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="text-blue-500 font-bold text-xl">2. Contact Nurses or Doctors Anytime</div>
            <div class="ml-5 mt-2">
              <p>Blue Shield members can access telemedicine services through NurseHelp 24/7 and Teladoc. NurseHelp 24/7 allows you to contact a registered nurse 24/7 with your questions and receive advice about your current symptoms. With Teladoc, you can consult doctors anytime from virtually anywhere. As soon as you feel unwell, you can get professional medical advice. With these services, members enjoy the convenience of getting medical resources and advice at any time of the day or night.</p>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="text-blue-500 font-bold text-xl">3. Use Preventive Care</div>
            <div class="ml-5 mt-2">
              <p>Stay up to date on your <a class="underline" href="https://www.blueshieldca.com/preventive-care/home.html?WT.mc_id=otc-cal-preventive-2176" target="_blank">preventive care by receiving</a> recommended tests and screenings for your gender, age, current health and medical history. By keeping up with preventive care, you can maintain your body's line of defense against illness and diseases or treat them before you even start experiencing symptoms.</p><br>
              <p>Cost savings are another noteworthy benefit. Preventive care can spare both your health and wallet, as you'll be able to tackle medical issues before they become more severe and costly.</p>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="text-blue-500 font-bold text-xl">4. Enjoy Discount Programs</div>
            <div class="ml-5 mt-2">
              <p>Blue Shield has wellness, vision and alternative care discount programs that help members care for themselves and save money. Fitness enables you to stay healthy, increases your energy levels and improves your mood. Staying active and building muscle means you'll burn more calories even while resting. One of the fitness programs offered through Blue Shield is Fitness Your Way, which gives members access to thousands of fitness centers for $19 each month.</p><br>
              <p>Blue Shield members can <a class="underline" href="https://www.blueshieldca.com/bsca/bsc/public/member/mp/contentpages/!ut/p/z1/rVHJbsIwEP0VOOQYeXBC7BwDZYtKkVgK-BJlcYirxA7BDeXvayraW0GV6tNY82bmLYihHWIybsUh1kLJuDT_PfMiTGd0CtALF4ABAgrD8MX3KSUEvSKGWJ2KDO0dvx_nmHg2Tnxuu6QHNiWZa2O_n_XBo34ekys6lbrWBdpXvEp4E6VKai51xKUFt7rTKKUtSHh05mVpQSla3il4XOriYkEmTql6l9puxcmwRNtHHJlpwy8vABQ-AhgTcDMfzg9GaawLW8hcoV2tUnOafQ3_HJ-shw4Ek1HgjOnGoa57A9zZvjf0SRRS8GaDBTzDihIInkbLQTDGGCY9tG0FP6ONVE1lEln90fDpt8A7BhmB4u14ZIEJ55rAh0a7_0qnrirqXGyWeEt6XudFeeh2PwGQuQKH/dz/d5/L2dBISEvZ0FBIS9nQSEh/" target="_blank">save up to 20%</a> on vision costs from participating providers. Some of the vision care includes the following products and services:</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p>Frames and lenses</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p>An extra pair of glasses</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p>Hard contact lenses</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p>Nonprescription sunglasses</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p>Routine eye examinations</p>
            </div>
            <div class="ml-5 mt-2">
              <p>Blue Shield members searching for <a class="underline" href="https://www.blueshieldca.com/en/home/be-well/live-healthy/alternative-care-discounts" target="_blank">alternative care can also get discounts</a> on the following services:</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p>Acupuncture</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p>Therapeutic massage</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p>Chiropractic services</p>
            </div>
            <div class="ml-5 mt-2">
              <p>These discounts make health care more affordable and accessible to Blue Shield of California members.</p>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="text-blue-500 font-bold text-xl">5. Access a Large Network</div>
            <div class="ml-5 mt-2">
              <p>Blue Shield's networks are among the largest in California. Through HMO plans and preferred provider organization (PPO) plans, <a class="underline" href="https://www.blueshieldca.com/en/broker/large-groups/medical/network" target="_blank">members car access thousands</a> of hospitals, doctors and specialists in the state. The HMO network has over 325 hospitals and 44,000 physicians, while the PPO network has over 380 hospitals and 75.000 physicians. Regarding dental and vision plans, there are 37.000 and 6,900 providers, respectively, in California alone. With many providers, members can choose the best one for them.</p><br>
              <p>Blue Shield also coordinates access to one of the largest national networks. Outside the country, the Blue Cross Blue Shield Global Care program allows members to access health care coverage internationally. The Blue Cross Blue Shield Association has relationships with over 400,000 providers worldwide.</p>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="text-blue-500 font-bold text-xl">6. Get an Affordable Plan</div>
            <div class="ml-5 mt-2">
              <p>With Blue Shield, you can find coverage at a price that suits your budget. Blue Shield also <a class="underline" href="https://www.blueshieldca.com/en/ifp/members/billing-financial-help/financial-assistance" target="_blank">offers financial assistance</a> to aid qualified members
                in paying for their coverage. There are two types of financial help:</p>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <div class="flex flex-1">
                <p><b>Cost-sharing reductions (CSRs):</b>&nbsp; These subsidies are offered through Covered California. Eligible individuals pay less out-of-pocket costs,
                  such as copayments, deductibles and coinsurance. CSR is available to those with an Enhanced Silver plan through Covered California.</p>
              </div>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <div class="flex flex-1">
                <p><b>Premium help:</b>&nbsp; This federal program lowers your monthly premium. A sliding scale determines the premium you qualify for, considering factors such as your age, household income, household size and the cost of affordable health care coverage in your geographic region. Premium help is only available for plans purchased through Covered California.</p>
              </div>
            </div>
            <div class="ml-5 mt-2">
              <p>These benefits make Blue Shield the ideal plan provider for most Californians.</p>
            </div>
          </div>
          <ImageSection class="mt-8" :content="networkBannerImage">
            <template #content="{ content }">
              <div class="flex flex-row w-full">
                <div class="flex flex-1 flex-col justify-end">
                  <h2 class="text-lg md:text-2xl lg:text-4xl">Get an Affordable Plan</h2>
                  <div class="mt-2 text-md md:text-xl lg:text-2xl">
                    There are two types of financial help:
                  </div>
                  <div class="flex mt-5 h-1 w-20 bg-teal-400 rounded-full"></div>
                </div>
                <div class="flex flex-1 flex-col ml-14 md:ml-20 lg:ml-28">
                  <div>
                    <h2 class="text-xs md:text-xl lg:text-2xl text-teal-400">Cost-sharing reductions (CSRs)</h2>
                    <div class="mt-2 text-white text-xs md:text-lg lg:text-xl">
                      Eligible individuals pay less out-of-pocket costs, such as copayments, deductibles and coinsurance.
                    </div>
                  </div>
                  <div class="mt-3 md:mt-8">
                    <h2 class="text-xs md:text-xl lg:text-2xl text-teal-400">Premium help</h2>
                    <div class="mt-2 text-white text-xs md:text-lg lg:text-xl">
                      A sliding scale determines the premium
                      you qualify for.
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </ImageSection>
        </div>
      </div>
      <div v-if="pageData.extraSection2" class="w-full py-16" :class="pageData.extraSection2.bgColor">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-2 text-2xl lg:text-3xl" v-html="pageData.extraSection2.title"></h2>
          <div class="w-full">
            Blue Shield of California offers diverse plans to individuals, families and businesses. It is essential to learn the available coverage options to make informed decisions. Below is an overview of the plan categories:
          </div>
          <div class="flex flex-col">
            <div class="text-blue-500 font-bold text-xl">1. Individual and Family Insurance Plans</div>
            <div class="ml-5 mt-2">
              <p>Blue Shield of California provides health insurance plans that cover essential health benefits, including preventive services, emergency care. hospitalization and prescription drugs. These plans are designed for individuals and families without access to employer-sponsored insurance or government programs like Medicare and Medi-Cal. You can choose an HMO or a PPO based on preference:</p><br>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <div class="flex flex-1">
                <p><b>HMO plan:</b>&nbsp; This plan requires you to select a primary care physician (PCP) and obtain a referral before visiting a specialist. Generally, the
                  premiums and out-of-pocket costs are lower, but there is less flexibility in selecting providers.</p>
              </div>
            </div>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <div class="flex flex-1">
                <p><b>PPO plan:</b>&nbsp; This health care plan allows members to see any provider in the PPO network without a referral. You can also choose non-network
                  providers but must pay a higher share of the cost.</p>
              </div>
            </div>
            <div class="ml-5 mt-2">
              <p>Members can access health care services virtually and enjoy wellness programs. The wellness programs aim to promote healthy lifestyles.
                including wellness activities and gym memberships.</p>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="text-blue-500 font-bold text-xl">2. Employer Plans</div>
            <div class="ml-5 mt-2">
              <p>Blue Shield of California provides health insurance solutions for small to large employers, focusing on affordability, quality care and accessibility. The employer can offer an HMO, a PPO or both, and employees can choose their desired plan during the annual open enrollment period, when they start a new job or after experiencing a significant life change, such as divorce or the birth of a baby.</p><br>
              <p>There are some crucial questions to ask when choosing a plan. For example:</p><br>
            </div>
            <div class="flex flex-col ml-5">
              <div><span class="text-xl text-blue-500 font-semibold mr-2">1.</span>Are you healty?</div>
              <div><span class="text-xl text-blue-500 font-semibold mr-2">2.</span>Are you dependents healthy?</div>
              <div><span class="text-xl text-blue-500 font-semibold mr-2">3.</span>Do you want access to specific hospitals, doctors or specialists?</div>
              <div><span class="text-xl text-blue-500 font-semibold mr-2">4.</span>Do you travel frequently?</div>
              <div><span class="text-xl text-blue-500 font-semibold mr-2">5.</span>How important is it that you have access to as many hospitals and specialists in California as possible?</div>
              <div><span class="text-xl text-blue-500 font-semibold mr-2">6.</span>Do you visit your doctor only for annual checkups?</div>
              <div><span class="text-xl text-blue-500 font-semibold mr-2">7.</span>Do you anticipate needing special medical services next year, such as surgery or pregnancy?</div>
              <div><span class="text-xl text-blue-500 font-semibold mr-2">8.</span>Do you take prescription drugs regularly?</div>
              <div><span class="text-xl text-blue-500 font-semibold mr-2">9.</span>Do your dependents take prescription drugs regularly?</div>
              <div><span class="text-xl text-blue-500 font-semibold mr-2">10.</span>Do you have any chronic conditions, such as diabetes, cancer or asthma?</div>
              <div><span class="text-xl text-blue-500 font-semibold mr-2">11.</span>Do your dependents have any chronic conditions?</div>
            </div>
            <div class="ml-5 mt-2">
              <p>Knowing your answers to most of these questions can help you determine the right health care plan for you and your family. Blue Shield's <a class="underline" href="https://www.blueshieldca.com/en/home/shop-plans/your-employer-coverage" target="_blank">employer coverage page provides valuable information</a>, but you can also ask your employer for more specific details.</p>
            </div>
          </div>
          <ImageSection v-if="pageData.extraSection2.imageSection" :content="pageData.extraSection2.imageSection">
            <template #content="{ content }">
              <div :class="[content.contentWidth, content.textDirection]">
                <h2 class="mt-4 text-lg md:text-2xl lg:text-4xl">Employer Plans</h2>
                <div class="flex mt-5 h-1 w-20 bg-teal-400 rounded-full"></div>
                <div class="w-full mt-4 text-xs md:text-md lg:text-xl" :class="content.textDirection ? content.textDirection : 'w-full text-center'">
                  Blue Shield of California provides health insurance solutions for small to large employers, focusing:
                </div>
                <div class="flex flex-col mt-4 space-y-4">
                  <div class="flex flex-row items-center">
                    <div class="flex mr-4">
                      <DollarAlertIcon class="w-10" />
                    </div>
                    <div class="flex flex-1 flex-col">
                      <span class="text-xs md:text-md lg:text-xl">Affordability</span>
                    </div>
                  </div>
                  <div class="flex flex-row items-center">
                    <div class="flex mr-4">
                      <HandOnHeartIcon class="w-10" />
                    </div>
                    <div class="flex flex-1 flex-col">
                      <span class="text-xs md:text-md lg:text-xl">Quality care</span>
                    </div>
                  </div>
                  <div class="flex flex-row items-center">
                    <div class="flex mr-4">
                      <PersonOnCircleIcon class="w-10" />
                    </div>
                    <div class="flex flex-1 flex-col">
                      <span class="text-xs md:text-md lg:text-xl">Accessibility</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </ImageSection>
        </div>
      </div>
    </template>
    <template v-slot:NetworkInfo="{ carrierData }">
      <div v-if="carrierData" class="w-full py-16" :class="carrierData.bgColor">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-2 text-2xl lg:text-3xl" v-html="carrierData.title"></h2>
          <div class="w-full" :class="carrierData.textDirection ? carrierData.textDirection : 'w-full text-center'" v-html="carrierData.content"></div>
          <div v-for="(item, index) in carrierData.subContent" class="flex flex-row">
            <h3 class="mb-2">{{ index + 1 }}.&nbsp;</h3>
            <div class="flex flex-1 flex-col">
              <h3 class="mb-2" v-html="item.title"></h3>
              <div class="mb-6" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:afterNetworkInfo="{ pageData }">
      <SectionInfo v-if="pageData.extraSection3" :carrier-data="pageData.extraSection3" :carrier-id="carrierId"></SectionInfo>
    </template>
  </CarrierPlan>
</template>
