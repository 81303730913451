import { MetalPlanPageSection } from '@/page/domain/MetalPlanPageSection';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl } = UseUrlHelper();
export const bronze60HsaPageData: Partial<MetalPlanPageSection> = {
  initialBanner: {
    visible: true,
    title: 'Covered California',
    subtitle: 'The Sister Plan with Tax Benefits',
    subtitleColor: 'text-orange-300',
  },
  highlightSection: {
    visible: true,
    content: '<p>We like to refer to the Bronze 60 HSA as the "Sister Plan" to the standard Bronze 60 plan, but <b>with tax benefits</b>. The Bronze 60 HSA can oftentimes be a few dollars cheaper than the standard Bronze 60. HSA is an abbreviation for a specific bank account called <b>a Health Savings Account</b>. With this health plan you have the option of setting up a separate Health Savings Account with a bank of your choice, and saving money designated for medical expenses. This money is tax deductible at the Federal level. For more details on how this works, review this <a href="https://www.irs.gov/publications/p969" target="blank_">HSA information from the IRS</a>.</p><br> <p>Keep in mind that the 2025 Bronze 60 HSA offers slightly different benefits than its sister plan, the Bronze 60. Here are some brief highlights:</p>',
    subContent: [
      '<p>This plan includes <a href="https://www.healthcare.gov/coverage/preventive-care-benefits/" target="blank_">free preventative care</a> like annual physicals, well-woman checkups, well-baby checkups and basic vision and dental services for children under the age of 19.</p>',
      '<p>The Bronze 60 HSA has a higher deductible than the standard Bronze 60. This means that you will be responsible for all services at full charge until your deductible is met (the exception would be for preventative services).</p>',
      '<p>The deductible and the out-of-pocket maximum are the same for this plan. So if you meet your deductible, the insurance company will then pay for 100% of all your in-network covered medical services for the rest of the calendar year. So this plan protects you from high medical bills that go over your OOP Max. The deductible or OOP Max is per person or doubled for a family.</p>',
    ]
  },
  infoBanner: {
    visible: true,
    title: 'Bronze 60 vs. Bronze 60 HSA',
    subtitle: `<p>Unless you, an employer or family member plans on contributing money into a Health Savings Account, then it makes little sense to go with a Bronze 60 HSA in comparison to the <a class="text-white underline" href="${getBaseUrl('/covered-california/plans/bronze-60')}">standard Bronze 60</a>. Here are some reasons why the Bronze 60 is better than the Bronze 60 HSA.</p>`,
    content: '<p>Only when the advantages of having a Health Savings Account linked to your insurance plan outweigh the above benefits, do we advise a Bronze 60 HSA plan.</p>',
    subContent: [
      '<p>The premium price is usually very similar.</p>',
      '<p>The deductible is waived for urgent care, PCP doctor visits, mental health therapy visits, generic drugs, and labs on the Bronze 60 plan.</p>',
      '<p>The Bronze 60 plan has a separate brand/specialty drug deductible of $450 after which the plan gives you coinsurance of 40% up to $500 per script.</p>',
      '<p>If you need to see a specialist, the deductible is waived for the first 3 visits a year, and you would only need to pay a manageable copay on this standard Bronze 60 plan.</p>',
    ]
  },
  textSection: {
    visible: true,
    title: 'Is the 2025 Bronze 60 HSA Plan Right for Me?',
    subtitle: 'While the Bronze 60 HSA is not right for everyone, it can be the perfect fit for some. It may be a fit for you if:',
    contentMode: 'twoColumns',
    subContent: [
      '<p>You want the tax benefits this type of plan offers.</p>',
      '<p>You have extra money to contribute to an HSA.</p>',
      '<p>You like the idea of saving money now and paying more later if medical needs arise.</p>',
      '<p>You need a plan compatible with a Health Savings Account you want to continue using and contributing to.</p>',
      '<p>Your financial advisor has recommended this type of plan for you.</p>',
      '<p>You are comfortable with a high deductible plan and mainly want protection for that unexpected event.</p>',
    ],
  },
  metalPlansSection: {
    visible: true,
    title: 'What Types of Health Plans are Available',
    redirectUrl: '/covered-california/plans'
  },
  googleReviewsSection: {
    visible: true,
    title: 'Customer Reviews',
    imageUrl: '/assets/img/bg/guys_working.webp'
  },
  certifiedBanner: {
    visible: true,
  },
  twoColumnSection: {
    visible: true,
    title: 'A Little More to Think About',
    content: '<p>You are not required to open a Health Savings Account with this plan. You can always purchase the plan and then open the Health Saving Account with the bank when you are ready, or not open a bank account at all. The money in your HSA bank account <b>cannot be used to pay your health plan\'s monthly premium</b>. It can only be used to pay for qualified medical and dental expenses. To find out which medical expenses are qualified click <a href="https://www.irs.gov/pub/irs-pdf/p502.pdf" target="blank_">here</a>. Any money you save is yours to keep and <b>rolls over from year to year</b>. For more information about the limits for HSA contributions, or for other questions, please consult your bank, financial advisor, or tax preparer.</p>',
    imageUrl: '/assets/img/bg/bronze_60_hsa.png'
  },
  finalBanner: {
    visible: true,
    title: 'A LITTLE MORE TO THINK ABOUT',
    imageUrl: '/assets/img/bg/woman_and_doctor_fadedx.png',
    redirectUrl: '/contact-us'
  }
};
