<script setup lang="ts">
import { PrimaryButton, viewportSize } from '@hfc/ui-core';
import SectionInfo from '@/page/infrastructure/components/carrier/SectionInfo.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import { computed } from 'vue';
const { viewport } = viewportSize();

const { getCdnUrl } = UseUrlHelper();

const buttonSize = computed(() => {
  if (viewport.value.width < 1000) {
    return 'sm';
  } if (viewport.value.width >= 1000 && viewport.value.width < 1400) {
    return 'lg';
  }

  return 'xl';
});
</script>
<template>
  <div class="bg-white">
    <div class="w-full overflow-hidden">
      <div class="relative">
        <img class="absolute min-w-[1000px] lg:min-w-[1700px] left-3/4 md:left-1/2 transform -translate-x-3/4 md:-translate-x-1/2" :src="getCdnUrl('/assets/img/eHealth/eHealth-bg.webp')">
      </div>
      <div class="relative flex w-full h-72 md:h-80 lg:h-[550px] flex-col items-left max-w-7xl mx-auto space-y-6 px-6">
        <div class="my-auto py-8 md:py-14">
          <h2 class="text-lg md:text-2xl lg:text-6xl text-white">Get started today</h2>
          <h4 class="text-xs md:text-lg lg:text-2xl text-white font-light mt-4">Your budget. Your priorities. Compare health<br> plans in your area. Enrollment time is now!</h4>
          <div class="flex mt-6">
            <PrimaryButton class="text-sm lg:text-md" :size="buttonSize" href="https://www.ehealth.com/HealthForCAIFP" target="_blank">Compare Plans</PrimaryButton>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center bg-sky-50 md:py-10 xl:py-0 h-full">
      <SectionInfo :carrier-data="{
      title: 'Let Us Help You Pick the Right Health Insurance Plan',
      content: `We simplify what is normally a difficult process! Finding the right health plan can be stressful and time-consuming. It’s hard to sift through all the health insurance options and wrestle with which plan is best for your situation. That’s where we come in.`,
      contentDirection: 'right',
      textDirection: 'text-left',
      imageUrl: '/assets/img/eHealth/eHealth-lady-computer.webp',
      bgColor: 'bg-sky-50',
      redirectButtonText: 'Call (888) 969-3389',
      redirectUrl: 'tel:8889693389',
      }" ></SectionInfo>
    </div>
  </div>
</template>
