<script setup lang="ts">
import { inject, onMounted, ref } from 'vue';
import { RouterView, useRoute, usePlocState } from '@hfc/ui-core';
import { PageStore } from '@/page/infrastructure/store/PageStore';
import BreadcrumbGenerator from '@/page/infrastructure/components/BreadcrumbGenerator.vue';
import TheFooter from '@/shared/infrastructure/components/footer/TheFooter.ce.vue';
import { Breadcrumb } from '@/page/domain/Breadcrumb';
import TheHeading from '@/shared/infrastructure/components/heading/TheHeading.ce.vue';

const route = useRoute();
const pageStore = inject<PageStore>('PageStore') as PageStore;
const pageState = usePlocState(pageStore);
const breadcrumbs = ref<Array<Breadcrumb>>(route.meta.breadcrumbs as Array<Breadcrumb>);

onMounted(() => {
  pageStore.setPhoneEnabledForRoute(route.path);
});
</script>

<template>
  <div v-if="pageState.globalSettings?.feedback?.globalFeedbackButton.enabled" class="floating-feedback-button-container hide-when-mobile-menu-opened">
    <feedback-modal></feedback-modal>
  </div>
  <div class="min-h-screen bg-gray-100 flex flex-col">
    <div class="sticky z-50 top-0 min-h-[112px]">
      <TheHeading :class="{'dark': !route.meta.theme || route.meta.theme === 'dark'}" :slot-after-header="!!route.meta.quoteBanner" :phone-for-current-route="pageState.phoneForCurrentRoute">
        <template #after-header v-if="route.meta.quoteBanner">
          <div class="relative">
            <get-quote-banner floating="true" :pre-selected="route.meta.selectedApplication" v-if="pageState.appsEnabled" :applications="JSON.stringify(pageState.appsEnabled)"></get-quote-banner>
          </div>
        </template>
      </TheHeading>
    </div>
    <div v-if="breadcrumbs && breadcrumbs.length > 1" class="hidden md:block bg-white py-2">
      <div class="flex w-full max-w-7xl mx-auto px-6">
        <BreadcrumbGenerator :breadcrumbs="breadcrumbs" />
      </div>
    </div>
    <div class="flex-grow min-h-[calc(100vh-112px)]">
      <router-view />
    </div>
    <Suspense>
      <TheFooter />
    </Suspense>
  </div>
</template>
