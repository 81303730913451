<script setup lang="ts">
import { PrimaryButton } from '@hfc/ui-core';
import { CarrierPageData } from '@/page/domain/CarrierPageData';
import TextAndImage from '@/page/infrastructure/components/TextAndImage.vue';
import ImageSection from '@/page/infrastructure/components/carrier/ImageSection.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import ListContent from '@/page/infrastructure/components/carrier/ListContent.vue';

const { getCdnUrl, getQuoteUrl } = UseUrlHelper();

defineProps<{
  carrierData: Partial<CarrierPageData>;
}>();
</script>

<template>
  <div class="w-full" :class="carrierData.bgColor">
    <TextAndImage div-class="px-6" :content-direction="carrierData.contentDirection" :image-class="carrierData.contentWidth">
      <template #text>
        <div class="flex flex-col h-full items-center justify-center lg:mt-0" :class="{ 'mt-10' : carrierData.imageUrl }">
          <h2 class="mb-6 text-2xl lg:text-3xl" v-html="carrierData.title"></h2>
          <div class="w-full" :class="carrierData.textDirection ? carrierData.textDirection : 'w-full text-center'" v-html="carrierData.content"></div>
          <ListContent v-if="carrierData.listContent" :list-content="carrierData.listContent"></ListContent>
          <ImageSection v-if="carrierData.imageSection" class="mt-6" :content="carrierData.imageSection"></ImageSection>
          <div class="w-full mt-4" v-if="carrierData.secondContent" :class="carrierData.textDirection ? carrierData.textDirection : 'w-full text-center'" v-html="carrierData.secondContent"></div>
          <PrimaryButton v-if="carrierData.redirectUrl" class="text-sm mt-6 lg:text-md uppercase" size="lg" :href="carrierData.redirectUrl">{{ carrierData.redirectButtonText }}</PrimaryButton>
        </div>
      </template>
      <template #image v-if="carrierData.imageUrl">
        <div class="flex flex-1 justify-center">
          <img class="flex rounded" :class="carrierData.imageWidth" :src="getCdnUrl(carrierData.imageUrl)">
        </div>
      </template>
    </TextAndImage>
  </div>
</template>