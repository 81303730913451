<script setup lang="ts">
import { UseCarrierDataStore } from '@/page/infrastructure/composition/UseCarrierDataStore';
import CarrierPlan from '@/page/infrastructure/views/CarrierPlan.vue';
import BannerCarrier from '@/page/infrastructure/components/carrier/BannerCarrier.vue';
import { CarrierList } from '@hfc/ui-core';
import ImageSection from '@/page/infrastructure/components/carrier/ImageSection.vue';
import TextAndImage from '@/page/infrastructure/components/TextAndImage.vue';
import CheckIcon from '@/page/infrastructure/components/carrier/CheckIcon.vue';
import SectionInfo from '@/page/infrastructure/components/carrier/SectionInfo.vue';

const props = defineProps<{
  carrierId: CarrierList;
}>();

const imageChecks = [
  'Primary care',
  'Mental health',
  'Hospitalization',
  'Prescription drugs',
  'Preventive care',
  'Vision',
  'Emergency services',
];

const { carrierData } = UseCarrierDataStore(props.carrierId);
</script>
<template>
  <CarrierPlan v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId">
    <template v-slot:NetworkInfo="{ carrierData }">
      <div v-if="carrierData" class="w-full py-16 bg-slate-100">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-6" v-html="carrierData.title"></h2>
          <BannerCarrier v-if="carrierData.imageSection" :content="carrierData.imageSection"></BannerCarrier>
          <div :class="carrierData.textDirection ? carrierData.textDirection : 'text-center'" v-html="carrierData.content"></div>
          <div v-for="(item, index) in carrierData.subContent" :key="index" class="flex flex-col">
            <div class="text-blue-500 font-bold text-xl" v-html="item.title"></div>
            <div class="ml-5 mb-2" v-html="item.content"></div>
          </div>
          <div :class="carrierData.textDirection ? carrierData.textDirection : 'text-center'" v-html="carrierData.secondContent"></div>
        </div>
      </div>
    </template>
    <template v-slot:afterNetworkInfo="{ pageData }">
      <div v-if="pageData.extraSection1" class="w-full py-16">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 v-html="pageData.extraSection1.title"></h2>
          <div class="flex flex-col">
            <div class="text-blue-500 font-bold text-xl">1. Comprehensive Coverage</div>
            <p class="ml-5 mt-2">Molina healthcare of California offers a range of benefits to members. The plans are designed to provide comprehensive health care coverage, particularly for low-income individuals and families:</p>
          </div>
          <ImageSection v-if="pageData.extraSection1.imageSection" :content="pageData.extraSection1.imageSection">
            <template #content="{ content }">
              <div class="text-left" :class="[content.contentWidth, content.textDirection]">
                <span class="text-white text-lg md:text-xl lg:text-2xl" v-html="content.title"></span>
                <div class="flex flex-wrap w-full mt-4 lg:pt-0">
                  <div v-for="item in imageChecks" class="flex flex-row w-full md:w-1/2 items-center py-0 md:py-2">
                    <div class="flex flex-row items-center"><CheckIcon class="w-4 sm:w-6 lg:w-8" /><span class="text-sm sm:text-md md:text-lg lg:text-xl ml-2 text-white">{{ item }}</span></div>
                  </div>
                </div>
              </div>
            </template>
          </ImageSection>
          <div class="flex flex-col w-full">
            <p class="ml-5 mt-2">Molina provides extensive coverage, including services such as:</p>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p><b>Primary care:&nbsp;</b>You get treatment from medical professionals for illnesses, well visits, vaccinations and many more.</p>
            </div>
            <div class="flex flex-row ml-5 mt-1">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p><b>Hospitalization:&nbsp;</b>Molina covers inpatient hospital stays, surgeries and related care.</p>
            </div>
            <div class="flex flex-row ml-5 mt-1">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p><b>Preventive care:&nbsp;</b>You get coverage for routine check-ups, vaccinations, screenings and other preventive services.</p>
            </div>
            <div class="flex flex-row ml-5 mt-1">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p><b>Emergency services:&nbsp;</b>Members can access emergency care without prior authorization.</p>
            </div>
            <div class="flex flex-row ml-5 mt-1">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p><b>Mental health:&nbsp;</b>Molina covers <a class="underline" href="https://www.molinahealthcare.com/members/ca/en-us/hp/medicaid/medical/coverd/mtlhlth.aspx" target="_blank">outpatient mental health services</a> with limited exceptions</p>
            </div>
            <div class="flex flex-row ml-5 mt-1">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p><b>Prescription drugs:&nbsp;</b>Members <a class="underline" href="https://www.molinahealthcare.com/members/ca/en-us/hp/medicaid/medical/coverd/presdrugs.aspx" target="_blank">get coverage for medications</a> through Medi-Cal RX.</p>
            </div>
            <div class="flex flex-row ml-5 mt-1">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p><b>Vision:&nbsp;</b>Members can <a class="underline" href="https://www.molinahealthcare.com/members/ca/en-us/hp/medicaid/medical/coverd/vision.aspx" target="_blank">get coverage for routine</a> eye examinations and prescription eyeglasses.</p>
            </div>
            <p class="ml-5 mt-2">While dental is excluded, <a class="underline" href="https://www.molinahealthcare.com/members/ca/en-us/hp/medicaid/medical/coverd/dental.aspx" target="_blank">members can get coverage</a> through regular Medi-Cal.</p>
          </div>
          <div class="flex flex-col w-full">
            <div class="text-blue-500 font-bold text-xl">2. Large Network</div>
            <p class="ml-5 mt-2">Members can access a broad network of doctors, specialists, clinics and hospitals throughout California, allowing them to choose from various
              health care providers. With the help of online tools, you can easily find in-network providers based on your plan and location.</p>
          </div>
          <div class="flex flex-col w-full">
            <div class="text-blue-500 font-bold text-xl">3. Affordability</div>
            <p class="ml-5 mt-2">Molina aims to keep premiums low and affordable, making health insurance accessible to a broader audience. Due to cost-sharing reductions,
              eligible persons pay less out-of-pocket costs for medical services.</p>
          </div>
          <div class="flex flex-col w-full">
            <div class="text-blue-500 font-bold text-xl">4. Specialized Programs</div>
            <p class="ml-5 mt-2">Molina healthcare <a class="underline" href="https://www.molinahealthcare.com/members/ca/en-us/hp/medicaid/medical/coverd/hm/hm.aspx" target="_blank">offers specialized programs</a>, including:</p>
            <div class="flex flex-row ml-5 mt-2">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p><b>Case management:&nbsp;</b>The Molina staff can help eligible members coordinate care without any extra charge.</p>
            </div>
            <div class="flex flex-row ml-5 mt-1">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p><b>Disease management:&nbsp;</b>Members with chronic illnesses, such as asthma and diabetes, can get advice and care tips for free.</p>
            </div>
            <div class="flex flex-row ml-5 mt-1">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p><b>Health and wellness:&nbsp;</b>Members can receive added services, such as reminders to see providers and resources to help them stay healthy.</p>
            </div>
            <div class="flex flex-row ml-5 mt-1">
              <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>
              <p><b>Education programs:&nbsp;</b>Molina has programs that help people lose weight, stop smoking and improve their well-being.</p>
            </div>
            <p class="ml-5 mt-2">These specialized programs enable members to get holistic health care coverage.</p>
          </div>
          <div class="flex flex-col w-full">
            <div class="text-blue-500 font-bold text-xl">5. Convenience</div>
            <p class="ml-5 mt-2">Molina Healthcare aims to make health care accessible and convenient. Members can access telehealth options that allow them to consult with health care providers remotely for non-emergency issues. You have convenient access to care from home or other locations. Additionally, the quick online tools let you perform functions like changing your doctor, requesting a new ID card, and viewing personal health records.</p>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:FinalSection="{ carrierData }">
      <div v-if="carrierData" class="w-full" :class="carrierData.bgColor">
        <TextAndImage div-class="px-6" :content-direction="carrierData.contentDirection" :image-class="carrierData.contentWidth">
          <template #text>
            <div class="flex flex-col h-full items-center justify-center lg:mt-0" :class="{ 'mt-10' : carrierData.imageUrl }">
              <h2 class="mb-6 text-2xl lg:text-3xl" v-html="carrierData.title"></h2>
              <div class="w-full" :class="carrierData.textDirection ? carrierData.textDirection : 'w-full text-center'" v-html="carrierData.content"></div>
              <div v-for="(item, index) in carrierData.subContent" :key="index" class="flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left mt-6">
                <div class="flex justify-center w-20 h-20 bg-white rounded-full mr-4">
                  <component class="w-10 " :is="item.componentIcon"></component>
                </div>
                <div class="flex flex-1 flex-col mt-4 sm:mt-0">
                  <h3 class="mb-2" v-html="item.title"></h3>
                  <div class="mb-6" v-html="item.content"></div>
                </div>
              </div>
            </div>
          </template>
        </TextAndImage>
      </div>
    </template>
    <template v-slot:afterFinalSection="{ pageData }">
      <div v-if="pageData.extraSection2" class="w-full">
        <SectionInfo v-if="pageData.extraSection2" :carrier-data="pageData.extraSection2" :carrier-id="carrierId"></SectionInfo>
      </div>
    </template>
  </CarrierPlan>
</template>
