import { MetalPlanPageSection } from '@/page/domain/MetalPlanPageSection';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl, getQuoteUrl } = UseUrlHelper();
export const silverPageData: Partial < MetalPlanPageSection > = {
  initialBanner: {
    visible: true,
    title: 'Covered California',
    subtitle: 'Popular Middle Ground',
    subtitleColor: 'text-slate-400',
    hidePlanTier: true,
    showPlansSuffix: true,
  },
  infoBanner: {
    visible: true,
    content: `<p>Covered California Silver Plans are a popular choice for many people. More often than not, they provide the <b>"best bang for your buck"</b>. The affordable monthly premiums, mid-size deductibles and discounted fees for commonly-needed medical services make Silver Plans an attractive option for those who are trying to balance cost with benefits. If you have not yet received a quote, click <a href="${getQuoteUrl()}">Affordable Health Insurance for California</a>.</p>`,
  },
  metalPlanTier: {
    visible: false,
    title: '4 Types of Silver Plans',
    subtitle: `<p class="lg:px-10">There are four levels of Silver Plans: Silver 70, Silver 73, Silver 84, and Silver 94. The Silver 70 Plan covers standard benefits. The other silver plans are called "Enhanced Silver Plans". They offer extra discounts so members pay even less for medical services. Not everyone qualifies for enhanced silver plans. <b>Eligibility is based on income, age, family size, and zip code</b>. Generally, adults with <b>an income of just above 138% to 250%</b> of the <a class="text-white underline" href="${getBaseUrl('/federal-poverty-level')}">Federal Poverty Level</a> may qualify for enhanced benefits.</p>`,
    redirectUrl: getQuoteUrl(),
    redirectText: 'Get a Free Plan Quote',
  },
  textSection: {
    visible: true,
    title: 'Is The Silver Plan Right for me?',
    subtitle: 'The Silver Plan is best-suited for:',
    content: '<b>The Silver Plan may not be the best fit for an individual or family in the following scenarios:</b>',
    subContent: [
      '<p>An individual or family who mainly goes in for doctor visits, lab work or x-rays, or who takes generic drugs.</p>',
      '<p>Someone who is relatively healthy.</p>',
      '<p>An individual or family who wants reasonable copayments for common services.</p>',
      `<p>The Silver Plan rate is too expensive. If this is the case, consider enrolling on a <a href="${getBaseUrl('/covered-california/plans/bronze-60')}">Bronze Plan</a>.</p>`,
      '<p>A hospital stay or another expensive procedure is already anticipated. Then, it may make more sense to enroll on a health plan that doesn\'t have a deductible, like the Gold or Platinum Plans.</p>'
    ],
    imageUrl: '/assets/img/bg/silver_a.webp',
    contentMode: 'right',
  },
  metalPlansSection: {
    visible: true,
    title: 'What Types of Health Plans are Available',
    redirectUrl: '/covered-california/plans'
  },
  googleReviewsSection: {
    visible: true,
    title: 'Customer Reviews',
    imageUrl: '/assets/img/bg/guys_working.webp'
  },
  certifiedBanner: {
    visible: true,
  },
  twoColumnSection: {
    visible: true,
    title: 'A Little More to Think About',
    content: '<p>If your income qualifies you for any of the Silver Enhanced Plans which are the Silver 73. Silver 87. and Silver 94 then look carefully at the plan benefits. You will notice lower deductibles and copayments which. oftentimes compared with the other metal plans, prove to be the best deal out there. And who doesn\'t want to take advantage of a good deal?</p><br> <p>If you are considering a PPO Silver plan it is extremely important to note that when the doctor or other provider is not part of an insurance company\'s network, the member\'s <b>cost for services will be higher</b>. For additional information, please contact the carrier.</p>',
    imageUrl: '/assets/img/bg/silver_b.webp',
  },
  finalBanner: {
    visible: true,
    title: 'A LITTLE MORE TO THINK ABOUT',
    imageUrl: '/assets/img/bg/doctor_and_patient_faded.png',
    redirectUrl: '/covered-california/plans/hmo-or-ppo'
  }
};
