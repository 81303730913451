<script setup lang="ts">
import { CarrierList, PrimaryButton } from '@hfc/ui-core';
import { UseCarrierDataStore } from '@/page/infrastructure/composition/UseCarrierDataStore';
import SectionInfo from '@/page/infrastructure/components/carrier/SectionInfo.vue';
import CarrierPlan from '@/page/infrastructure/views/CarrierPlan.vue';
import ImageSection from '@/page/infrastructure/components/carrier/ImageSection.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getQuoteUrl } = UseUrlHelper();

const props = defineProps<{
  carrierId: CarrierList;
}>();

const { carrierData } = UseCarrierDataStore(props.carrierId);
</script>
<template>
  <CarrierPlan v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId">
    <template v-slot:afterAboutInfo="{ pageData }">
      <SectionInfo v-if="pageData.extraSection1" :carrier-data="pageData.extraSection1" :carrier-id="carrierId"></SectionInfo>
    </template>
    <template v-slot:NetworkInfo="{ carrierData, carrierId }">
      <div v-if="carrierData" class="w-full py-16" :class="carrierData.bgColor">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-2 text-2xl lg:text-3xl" v-html="carrierData.title"></h2>
          <div class="w-full" :class="carrierData.textDirection ? carrierData.textDirection : 'w-full text-center'" v-html="carrierData.content"></div>
          <ImageSection v-if="carrierData.imageSection" class="mt-8" :content="carrierData.imageSection">
            <template #content="{ content }">
              <div :class="[content.contentWidth, content.textDirection]">
                <h2 class="text-lg md:text-xl lg:text-2xl" v-html="content.title"></h2>
                <div class="flex mt-2 h-1 w-20 bg-teal-400 rounded-full"></div>
              </div>
            </template>
          </ImageSection>
        </div>
      </div>
    </template>
    <template v-slot:afterNetworkInfo="{ pageData }">
      <SectionInfo v-if="pageData.extraSection2" :carrier-data="pageData.extraSection2" :carrier-id="carrierId"></SectionInfo>
      <SectionInfo v-if="pageData.extraSection3" :carrier-data="pageData.extraSection3" :carrier-id="carrierId"></SectionInfo>
    </template>
    <template v-slot:FinalSection="{ carrierData, carrierId }">
      <div v-if="carrierData" class="w-full py-16" :class="carrierData.bgColor">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-2 text-2xl lg:text-3xl" v-html="carrierData.title"></h2>
          <div v-for="content in carrierData.subContent" class="w-full">
            <div class="flex text-xl text-blue-500 font-semibold mb-2" v-html="content.title"></div>
            <div class="w-full" v-html="content.content"></div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:afterFinalSection="{ pageData }">
      <div v-if="pageData.extraSection4" class="w-full py-16" :class="pageData.extraSection4.bgColor">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <div class="w-full" :class="pageData.extraSection4.textDirection ? pageData.extraSection4.textDirection : 'w-full text-center'" v-html="pageData.extraSection4.content"></div>
          <ImageSection v-if="pageData.extraSection4.imageSection" class="mt-8" :content="pageData.extraSection4.imageSection">
            <template #content="{ content }">
              <div class="flex flex-col items-center text-center md:px-24 lg:px-32" :class="[content.contentWidth, content.textDirection]">
                <h2 class="text-white text-3xl md:text-4xl lg:text-5xl mb-96" v-html="content.title"></h2>
                <div class="flex w-full justify-end">
                  <div class="flex justify-center w-1/2">
                    <PrimaryButton v-if="content.redirectButtonText" class="text-sm mt-80 lg:text-md" size="xl" :href="content.redirectButtonUrl">{{ content.redirectButtonText }}</PrimaryButton>
                  </div>
                </div>
              </div>
            </template>
          </ImageSection>
        </div>
      </div>
    </template>
  </CarrierPlan>
</template>
