<script setup lang="ts">
import { PrimaryButton } from '@hfc/ui-core';
import { CarrierPageData } from '@/page/domain/CarrierPageData';
import TextAndImage from '@/page/infrastructure/components/TextAndImage.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getQuoteUrl } = UseUrlHelper();

defineProps<{
  carrierId: string;
  carrierData: Partial<CarrierPageData>;
}>();
</script>

<template>
  <TextAndImage content-direction="right" div-class="px-6">
    <template #text>
      <div class="flex flex-col h-full items-center justify-center text-center space-y-6 mt-10 lg:mt-0">
        <h1 v-html="carrierData.title"></h1>
        <h2 class="font-light" v-html="carrierData.content"></h2>
        <PrimaryButton class="text-sm lg:text-md uppercase" size="lg" :href="carrierData.redirectUrl ? carrierData.redirectUrl : getQuoteUrl()">GET MY FREE QUOTE NOW!</PrimaryButton>
      </div>
    </template>
    <template #image>
      <div class="flex flex-1 justify-center items-center">
        <div class="w-56 lg:w-72">
          <carrier-logo :carrier-id="carrierId" vertical="true" no-link="true" custom-class="w-full" />
        </div>
      </div>
    </template>
  </TextAndImage>
</template>