import { CarrierPage } from '@/page/domain/CarrierPage';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl, getQuoteUrl } = UseUrlHelper();

export const valleyPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: 'Valley <br> Health Insurance Coverage',
    content: 'Find the Lowest Prices Available on Valley Medical Insurance Plans or Get Discounts Through Our Covered California Options'
  },
  aboutInfo: {
    visible: true,
    title: 'About Valley Health Plan',
    content: `<p>Santa Clara County has been served by <a class="underline" href="https://www.valleyhealthplan.org/home" target="_blank">Valley Health Plan</a> (VHP) for over 30 years of serving Santa Clara county. They are a commercial HMO (Health Maintenance Organization) owned and operated locally by the county of Santa Clara.</p><br>
    <p>Valley Health Plan has an appropriate tag line of “Speaking the language of health.” As their CEO, Bruce Butler says, “We pride ourselves on having personal service and a location where members can walk in to speak with us.”</p><br>
    <p>They are honored to be chosen by Covered California; the California State Exchange as one of eleven health plans to offer insurance to the public. They offer coverage to those in pricing region 7; Santa Clara county.</p>`
  },
  sectionInfo: {
    visible: true,
    title: 'Take Note',
    content: `<p>Valley Health Plan is licensed and accredited on several levels.</p><br>
    <div class="flex flex-col">
      <div class="flex flex-row">
        <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Knox-Keene licensed
      </div>
      <div class="flex flex-row">
        <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><a class="underline" href="https://www.ncqa.org/" target="_blank">NCQA</a> (National Committee for Quality Assurance) Interim-Accredited HMO
      </div>
    </div>`,
    imageUrl: '/assets/img/carriers/old-woman-and-doctor.webp',
    textDirection: 'text-left',
  },
  networkInfo: {
    visible: true,
    title: 'Network',
    content: `<p>VHP members have access to a broad range of primary and specialty care doctors, select local pharmacies, and to Safeway and Walgreen pharmacies nationally</p><br>
    <p class="mb-2">Network Hospitals include:</p>
    <div class="flex flex-col">
      <div class="flex flex-row">
        <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Lucile Packard Children’s Hospital
      </div>
      <div class="flex flex-row">
        <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Santa Clara Valley Medical center
      </div>
      <div class="flex flex-row">
        <div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Stanford Hospital and Clinics
      </div>
    </div><br>
    <p>For a complete list of providers and if they are accepting new patients, please visit Valley Health Plan's provider list by clicking <a class="underline" href="https://vhpservices.sccgov.org/" target="_blank">here</a></p>`,
    imageUrl: '/assets/img/carriers/doctors-conference.webp',
    redirectButtonText: 'GET MY FREE QUOTE NOW!',
    redirectUrl: getQuoteUrl(),
    textDirection: 'text-left',
    contentDirection: 'right',
    bgColor: 'bg-gray-100'
  },
  finalSection: {
    visible: true,
    contentDirection: 'right',
    title: 'The Future is Here',
    content: `<p>Valley Health Plan is tracking with the needs of its members by offering a 24/7 Nurse Advice phone line where an experienced nurse can answer questions that range from an urgent to general health care need.</p><br>
    <p><a class="underline" href="https://members.navitus.com/en-US/Logon/Logon.aspx?ReturnUrl=%2fen-US%2fSession%2fSecured-Pages%2fHome.aspx" target="_blank">Navitus</a>, Valley Health Plan’s Pharmacy Benefit Manager, allows members to access formulary and cost information, do a pharmacy search, access medication history, obtain mail-order support, and initiate a request for a non-formulary drug.</p><br>
    <p>There are many Health Education Wellness Programs members can take advantage of <a class="underline" href="https://www.valleyhealthplan.org/sites/m/hw/classes/Pages/Classes.aspx" target="_blank">including free classes</a> in Chronic Disease Prevention and Management, fitness classes including yoga, jazzercise, Pilates, and Zumba.</p>`,
    imageSection: {
      title: 'Contact <span class="font-bold">Health for California</span> to Learn More About the <span class="font-bold">Valley Health</span> Plan',
      contentWidth: 'w-1/2',
      justifyContent: 'items-start',
      textDirection: 'text-left',
      imageUrl: '/assets/img/carriers/old-men-and-doctor.webp',
      redirectButtonUrl: getBaseUrl('/contact-us'),
      redirectButtonText: 'Contact Us'
    },
  }
};
