<script setup lang="ts">
import { computed, ref } from 'vue';

const contentOpen = ref<boolean>(false);

const accordionBg = computed(() => contentOpen.value ? 'bg-white' : 'bg-slate-200');
const spanIcon = computed(() => contentOpen.value ? 'minus' : 'plus');
const spanIconColor = computed(() => contentOpen.value ? 'text-yellow-500' : 'text-blue-500');

const beforeEnter = (el: Element): void => {
  const element = el as HTMLElement;
  element.style.maxHeight = '0';
  element.style.opacity = '0';
};

const enter = (el: Element): void => {
  const element = el as HTMLElement;
  element.style.maxHeight = element.scrollHeight + 'px';
  element.style.opacity = '1';
  element.style.transition = 'max-height 0.2s ease, opacity 0.3s ease';
};

const afterEnter = (el: Element): void => {
  const element = el as HTMLElement;
  element.style.maxHeight = '';
  element.style.opacity = '';
};

const beforeLeave = (el: Element): void => {
  const element = el as HTMLElement;
  element.style.maxHeight = element.scrollHeight + 'px';
  element.style.opacity = '1';
};

const leave = (el: Element): void => {
  const element = el as HTMLElement;
  element.style.maxHeight = '0';
  element.style.opacity = '0';
  element.style.transition = 'max-height 0.3s ease, opacity 0.2s ease';
};
</script>

<template>
  <div class="flex">
    <div class="flex-1 rounded shadow-md shadow-slate-300 p-4 duration-300" :class="accordionBg">
      <div class="flex justify-between cursor-pointer" @click="contentOpen = !contentOpen">
        <span class="font-bold">
          <slot name="title"></slot>
        </span>
        <button class="rounded-full">
          <hfc-icon :icon="spanIcon" :class="spanIconColor" />
        </button>
      </div>
      <transition
        name="faq"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
      >
        <div v-show="contentOpen" class="mt-6">
          <slot name="content"></slot>
        </div>
      </transition>
    </div>
  </div>
</template>
