<script setup lang="ts">
import { computed } from 'vue';
import { BoxData } from '@/page/domain/CarrierPageData';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl } = UseUrlHelper();

const props = defineProps<{
  content: Partial<BoxData>
}>();

const boxStyles = computed(() => `${props.content.boxHeight || 'h-40'} ${props.content.boxWidth || 'w-full'} ${props.content.boxColor || 'bg-white rounded-lg shadow-lg'}`);
const imgStyles = computed(() => `${props.content.imageStyles || 'w-16 h-16 mx-auto'}`);
const titleStyles = computed(() => `${props.content.titleColor || 'text-blue-500'} ${props.content.titleSize || 'text-lg'}`);
const contentStyles = computed(() => `${props.content.contentColor || 'text-black'} ${props.content.contentSize || 'text-md'}`);
const listStyles = computed(() => `${props.content.listColor || 'text-black'} ${props.content.listTextSize || 'text-md'}`);

</script>
<template>
  <div class="flex flex-col justify-center items-center mb-6 m-2" :class="boxStyles">
    <component v-if="content.component" class="w-16 mx-auto" :is="content.component"></component>
    <img v-if="content.imageUrl" :class="imgStyles" :src="getCdnUrl(content.imageUrl)">
    <div class="flex flex-col w-full justify-center text-center">
      <h5 v-if="content.title" v-html="content.title" :class="titleStyles"></h5>
      <div v-if="content.content" v-html="content.content" :class="contentStyles"></div>
      <div v-if="content.list" class="flex flex-col whitespace-nowrap text-start p-4" :class="listStyles">
        <div v-for="item in content.list">
          <hfc-icon icon="check" />&nbsp;{{ item }}
        </div>
      </div>
    </div>

  </div>
</template>