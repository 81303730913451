import { CarrierList } from '@hfc/ui-core';
import { CarrierRouteCollection } from '@/page/infrastructure/router/CarrierRouteCollection';
import LaCare from '@/page/infrastructure/views/carrierPlans/LaCare.vue';
import Aetna from '@/page/infrastructure/views/carrierPlans/Aetna.vue';
import Anthem from '@/page/infrastructure/views/carrierPlans/Anthem.vue';
import BlueShield from '@/page/infrastructure/views/carrierPlans/BlueShield.vue';
import CCHP from '@/page/infrastructure/views/carrierPlans/CCHP.vue';
import HealthNet from '@/page/infrastructure/views/carrierPlans/HealthNet.vue';
import IEHP from '@/page/infrastructure/views/carrierPlans/IEHP.vue';
import Kaiser from '@/page/infrastructure/views/carrierPlans/Kaiser.vue';
import Molina from '@/page/infrastructure/views/carrierPlans/Molina.vue';
import Sharp from '@/page/infrastructure/views/carrierPlans/Sharp.vue';
import Valley from '@/page/infrastructure/views/carrierPlans/Valley.vue';
import Western from '@/page/infrastructure/views/carrierPlans/Western.vue';
import Medical from '@/page/infrastructure/views/carrierPlans/Medical.vue';

export const carrierRouter = new CarrierRouteCollection([{
  href: '/la-care-health-plan',
  title: 'L.A. Care Health Coverage Options | Health for CA',
  description: 'Learn about the affordable L.A. Care health insurance plans with discounts through Covered California. Get your free quote today and access quality care!',
  carrierId: CarrierList.LACare,
  breadcrumbLabel: 'L.A. Care Health Plan',
  component: LaCare
}, {
  href: '/aetna',
  title: 'Aetna Insurance: Guide to Health Plans | Health for CA',
  description: 'Discover Aetna Insurance, a trusted provider offering comprehensive health plans. Get expert help and a free quote to find the right coverage for you!',
  carrierId: CarrierList.Aetna,
  breadcrumbLabel: 'Aetna Health Plan',
  component: Aetna
}, {
  href: '/anthem',
  title: 'Anthem Blue Cross Health Insurance | Health for CA',
  description: 'Get the best Anthem Blue Cross health insurance coverage in California! Compare plans and get a free quote to find the perfect fit for you and your family.',
  carrierId: CarrierList.Anthem,
  breadcrumbLabel: 'Anthem Blue Cross',
  component: Anthem
}, {
  href: '/blue-shield',
  title: 'Discover Blue Shield Health Insurance | Health for CA',
  description: 'Get quality health insurance coverage with Blue Shield of California. Enjoy comprehensive benefits, telemedicine access, and wellness programs tailored for you.',
  carrierId: CarrierList.BlueShield,
  breadcrumbLabel: 'Blue Shield',
  component: BlueShield
}, {
  href: '/chinese-community-health-plan',
  title: 'Chinese Community Health Insurance Plans | Health for CA',
  description: 'Discover affordable Chinese Community Health Insurance plans tailored for you. Explore discounts through Covered California and access quality care today!',
  carrierId: CarrierList.CCHP,
  breadcrumbLabel: 'Chinese Community Health Plan',
  component: CCHP
}, {
  href: '/health-net',
  title: 'Health Net Insurance Plans for California | Health for CA',
  description: 'Discover Health Net\'s comprehensive health insurance coverage options for individuals, families, and businesses in California. Get a quote today!',
  carrierId: CarrierList.HealthNet,
  breadcrumbLabel: 'Health Net',
  component: HealthNet
}, {
  href: '/iehp',
  title: 'IEHP Insurance Options for Your Needs | Health for CA',
  description: 'Inland Empire Health Plan (IEHP), a wellness-focused, Medi-Cal managed care plan serving residents in the Inland Empire (IE) region. Learn more or enroll today!',
  carrierId: CarrierList.IEHP,
  breadcrumbLabel: 'Covered California Health Insurance Companies',
  component: IEHP
}, {
  href: '/kaiser',
  title: 'Kaiser Permanente Health Insurance Plans | Health for CA',
  description: 'Explore affordable Kaiser Permanente health insurance plans in California. Get quotes and find the best coverage options to meet your health needs today!',
  carrierId: CarrierList.Kaiser,
  breadcrumbLabel: 'Kaiser Permanente',
  component: Kaiser
}, {
  href: '/molina-healthcare',
  title: 'About Molina Health Insurance Plans | Health for CA',
  description: 'Molina Health Insurance plans serve low-income families across 15 states. Learn more and explore discounts, extensive provider networks, and online tools.',
  carrierId: CarrierList.Molina,
  breadcrumbLabel: 'Molina Insurance',
  component: Molina
}, {
  href: '/sharp',
  title: 'Sharp Medical Insurance Coverage Options | Health for CA',
  description: 'Learn about the Sharp Health Insurance plans in Southern California. Access top-notch care, wellness programs, and telehealth services for your family.',
  carrierId: CarrierList.Sharp,
  breadcrumbLabel: 'Sharp',
  component: Sharp
}, {
  href: '/valley-health-plan',
  title: 'Valley Health Plan Coverage Options | Health for CA',
  description: 'Discover affordable Valley Health Insurance plans in Santa Clara County. Learn about the personal service, 24/7 nurse advice, & access to wellness programs.',
  carrierId: CarrierList.Valley,
  breadcrumbLabel: 'Valley Health Plan',
  component: Valley
}, {
  href: '/western-health-advantage',
  title: 'Western Health Advantage Health Coverage | Health for CA',
  description: 'Explore Western Health Advantage for affordable HMO plans in Northern California. Access a vast network of doctors & enjoy great service. Get covered today!',
  carrierId: CarrierList.Western,
  breadcrumbLabel: 'Western Health Advantage',
  component: Western
}, {
  href: '/medi-cal',
  title: 'Medi-Cal California Medicaid | Health for California',
  description: 'Medi-Cal is the version of Medicaid available to qualified individuals in CA. It can help you pay for doctor\'s visits, medication, and more. Contact us!',
  carrierId: CarrierList.Medical,
  breadcrumbLabel: 'Medi-cal',
  component: Medical
}
]);
