
import { ServicesApiResponse, Ploc } from '@hfc/ui-core';
import { FooterMemory } from '@/shared/infrastructure/dataSource/FooterMemory';
import { HeaderMemory } from '@/shared/infrastructure/dataSource/HeaderMemory';
import { GetFooterItemsUseCase } from '@/shared/application/GetFooterItemsUseCase';
import { GetFooterLinksUseCase } from '@/shared/application/GetFooterLinksUseCase';
import { GetHeaderItemsUseCase } from '@/shared/application/GetHeaderItemsUseCase';
import { FooterItem, LinkItem } from '@/shared/domain/FooterItem';
import { MenuItem } from '@/shared/domain/MenuItem';

const footerItemsRepository = new FooterMemory();
const getFooterItems = new GetFooterItemsUseCase(footerItemsRepository);
const getFooterExtraLinks = new GetFooterLinksUseCase(footerItemsRepository);

const headerItemsRepository = new HeaderMemory();
const getHeaderItems = new GetHeaderItemsUseCase(headerItemsRepository);

export interface AppStateInterface {
  loadingData: boolean
}

const initialState: AppStateInterface = {
  loadingData: false,
};

export class AppStore extends Ploc<AppStateInterface> {

  public constructor () {
    super(initialState);
  }

  public async getFooterExtraLinks (): Promise<ServicesApiResponse<Array<LinkItem>>> {
    this.changeState({ ...this.state, loadingData: false });
    const response = await getFooterExtraLinks.run();
    this.changeState({ ...this.state, loadingData: true });

    return response;
  }

  public async getFooterItems (): Promise<ServicesApiResponse<Array<FooterItem>>> {
    this.changeState({ ...this.state, loadingData: false });
    const response = await getFooterItems.run();
    this.changeState({ ...this.state, loadingData: true });

    return response;
  }

  public async getHeaderItems (preset: string): Promise<ServicesApiResponse<Array<MenuItem>>> {
    this.changeState({ ...this.state, loadingData: false });
    const response = await getHeaderItems.run(preset);
    this.changeState({ ...this.state, loadingData: true });

    return response;
  }

}
