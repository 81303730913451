import { MetalPlanPageSection } from '@/page/domain/MetalPlanPageSection';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl, getQuoteUrl } = UseUrlHelper();
export const silver94PageData: Partial < MetalPlanPageSection > = {
  initialBanner: {
    visible: true,
    title: 'Covered California',
    subtitle: 'Enhanced Plan',
    subtitleColor: 'text-slate-400',
  },
  highlightSection: {
    visible: true,
    content: `<p>If you already know you qualify for the Silver 94 plan, then congratulations! This plan is hands down the best, but comes with a catch. <b>You have to qualify for it</b>.<br><br> <p>Of all the Covered California metal plans, the Silver 94 offers the lowest out-of- pocket costs for medical services. For example, office visits are a flat fee of $5 or $8 for a specialist. Generic drugs are $3. Most x-rays are $8, lab tests are $8. The most you would pay in a year if the worst happened with the Silver 94 plan is <b>$1,150 for an individual or $2,300 for a family</b>, compared to $4.500/$9000 under the <a href="${getBaseUrl('/covered-california/plans/platinum-90')}">Platinum</a> "top-of-the-line" Plan. To see if you are eligible for an Enhanced Silver 94 Plan, click <a href="${getQuoteUrl()}">Affordable Health Insurance for California</a> for a free quote.</p>`,
    redirectText: 'See All Silver Plans',
    redirectUrl: getBaseUrl('/covered-california/plans/silver')
  },
  infoBanner: {
    visible: true,
    title: 'How You Qualify for a Silver 94 Plan',
    content: `<p>Not everyone qualifies for a Silver 94 Plan. To qualify, you must <a class="text-white underline" href="${getQuoteUrl()}">sign up through Covered California</a>. Also, you must meet <a class="text-white underline" href="${getBaseUrl('/covered-california/income-limits')}">Covered California income requirements</a>. Eligibility is determined using your family income, household size, age, and where you live. Generally, you may qualify if your income is just <b>above 138% to 150%</b> of the <a class="text-white underline" href="${getBaseUrl('/federal-poverty-level')}">Federal Poverty Level</a>, and you are not already offered affordable employer sponsored health insurance. Children <b>up to age 18 do not qualify</b> for the enhanced Silver 94 Plan but may qualify for Medi-Cal or can enroll on a full priced plan.</p>`,
  },
  textSection: {
    visible: true,
    title: 'Is the Silver 94 Right for me?',
    contentMode: 'right',
    subContent: [
      '<p>If you qualify for the Silver 94, look no further, this is going to be the best for your money.</p>',
    ],
  },
  metalPlansSection: {
    visible: true,
    title: 'What Types of Health Plans are Available',
    redirectUrl: '/covered-california/plans'
  },
  googleReviewsSection: {
    visible: true,
    title: 'Customer Reviews',
    imageUrl: '/assets/img/bg/guys_working.webp'
  },
  certifiedBanner: {
    visible: true,
  },
  twoColumnSection: {
    visible: true,
    title: 'A Little More to Think About',
    content: '<p>Keep in mind that the <b>Silver 94 Plan</b> is based on your income. So, if you anticipate earning more in the near future, chances are, you may no longer qualify for the Silver 94 Plan when your income goes up. Be prepared, and know what some of the other metal plans are like so you can plan. But take advantage of the great benefits the Silver 94 offers you while you do qualify.</p><br> <p>If you are considering a PPO plan, and you have a favorite doctor who is not part of your insurance company\'s network, be aware that your cost for the doctor\'s services may be a lot higher than if the doctor were in- network. This is true even on the Silver 94 "lowest-cost" Plan. For example, if you were admitted to the hospital and your physician was out-of-network, you may need to pay a deductible, plus an additional 50%, if applicable. Again, this is only if the doctor was out-of-network. In contrast, if your doctor were in-network, your cost under the Silver 94 Plan would be <b>10% of the bill up to a $1,150 maximum</b> (individual out- of-pocket maximum). As you can see, a whole lot different.</p>',
    imageUrl: '/assets/img/bg/silver_94.webp'
  },
  finalBanner: {
    visible: true,
    title: 'A LITTLE MORE TO THINK ABOUT',
    imageUrl: '/assets/img/bg/doctor_and_patient_faded.png',
    redirectUrl: '/covered-california/plans/hmo-or-ppo'
  }
};
