import { UseCase } from '@/shared/application/UseCase';
import { MenuItem } from '@/shared/domain/MenuItem';
import { ServicesApiResponse } from '@hfc/ui-core';
import { HeaderRepository } from '@/shared/domain/HeaderRepository';

export class GetHeaderItemsUseCase implements UseCase<string, ServicesApiResponse<Array<MenuItem>>> {

  private repository: HeaderRepository;

  public constructor (repository: HeaderRepository) {
    this.repository = repository;
  }

  public async run (preset: string): Promise<ServicesApiResponse<Array<MenuItem>>> {
    return await this.repository.getHeaderItems(preset);
  }

}
