import { UseCase } from '@/shared/application/UseCase';
import { ServicesApiResponse } from '@hfc/ui-core';
import { LinkItem } from '@/shared/domain/FooterItem';
import { FooterRepository } from '@/shared/domain/FooterRepository';

export class GetFooterLinksUseCase implements UseCase<string, ServicesApiResponse<Array<LinkItem>>> {

  private repository: FooterRepository;

  public constructor (repository: FooterRepository) {
    this.repository = repository;
  }

  public async run (): Promise<ServicesApiResponse<Array<LinkItem>>> {
    return await this.repository.getFooterExternalLinks();
  }

}
