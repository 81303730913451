<script setup lang="ts">
import { UseCarrierDataStore } from '@/page/infrastructure/composition/UseCarrierDataStore';
import CarrierPlan from '@/page/infrastructure/views/CarrierPlan.vue';
import SectionInfo from '@/page/infrastructure/components/carrier/SectionInfo.vue';
import ListContent from '@/page/infrastructure/components/carrier/ListContent.vue';
import { CarrierList } from '@hfc/ui-core';
import ImageSection from '@/page/infrastructure/components/carrier/ImageSection.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import YellowCross from '@/page/infrastructure/components/carrier/YellowCross.vue';

const { getCdnUrl } = UseUrlHelper();

const props = defineProps<{
  carrierId: CarrierList;
}>();

const metalTableInfo = [
  {
    title: 'Bronze 60 Plan',
    premiums: 'Anthem pays about 60% of your medical costs',
    deductible: '$6,300 for individuals <br>$12,600 for families',
    idealFor: 'Suitable for anyone who needs minimal medical care',
    topTip: 'You have more upfront costs for specialists. However, if you have no dependents and expect a few visits to the doctor this year, you can feel confident choosing the Bronze Plan.'
  },
  {
    title: 'Silver 70 Plan',
    premiums: 'Cost of premiums is balanced with out-of-pocket costs<br> Anthem pays about 70% of your medical costs',
    deductible: '$5,400 for individuals <br>$10,800 for families',
    idealFor: 'Ideal for those who want moderate coverage at an affordable price',
    topTip: 'You\'ll still have to pay out-of-pocket for hospital visits or outpatient surgeries on this plan.'
  },
  {
    title: 'Gold 80 Plan',
    premiums: 'Hight premiums but lower out-of-pocket costs<br> Anthem pays about 80% of your medical costs',
    deductible: 'None',
    idealFor: 'Suitable for anyone who needs more medical services',
    topTip: 'While you may be paying more than the Bronze and Silver Plans, you\'ll have a lower deductible every the time you visit the hospital or see a specialist, which can save you more money over time.'
  },
  {
    title: 'Platinum 90 Plan',
    premiums: 'High premiums with the lowest out-of-pocket costs<br> Anthem pays about 90% of your medical costs',
    deductible: 'None',
    idealFor: 'None',
    topTip: 'This coverage includes regular physician visits, emergency room visits and preventive care. And the best part is you get to skip the deductible and keep more money in your pocket. The Platinum Plan is perfect for families seeking great coverage while reducing out-of-pocket expenses.'
  }
];

const { carrierData } = UseCarrierDataStore(props.carrierId);
</script>
<template>
  <CarrierPlan v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId">
    <template v-slot:afterSectionInfo="{ pageData }">
      <div v-if="pageData.extraSection1" class="w-full py-16" :class="pageData.extraSection1.bgColor">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative px-6">
          <h2 class="mb-6" v-html="pageData.extraSection1.title"></h2>
          <div :class="pageData.extraSection1.textDirection ? pageData.extraSection1.textDirection : 'text-center'" v-html="pageData.extraSection1.content"></div>
          <ListContent v-if="pageData.extraSection1.listContent" :list-content="pageData.extraSection1.listContent"></ListContent>
          <ImageSection v-if="pageData.extraSection1.imageSection" class="mt-6" :content="pageData.extraSection1.imageSection">
            <template #content="{ content }">
              <div class="flex flex-col sm:flex-row">
                <div class="flex flex-row sm:w-1/2 justify-center">
                  <img class="w-32 sm:w-96" :src="getCdnUrl('/assets/img/carriers/doctor-hands.webp')" alt="Health For California"/>
                </div>
                <div class="flex flex-col sm:w-1/2 ml-4 items-center sm:items-start justify-center">
                  <YellowCross class="w-10 md:w-16" />
                  <h2 class="text-sm sm:text-xl lg:text-2xl" v-html="content.title"></h2>
                </div>

              </div>
            </template>
          </ImageSection>
        </div>
      </div>
      <div v-if="pageData.extraSection2" class="w-full py-16" :class="pageData.extraSection2.bgColor">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-6" v-html="pageData.extraSection2.title"></h2>
          <div :class="pageData.extraSection2.textDirection ? pageData.extraSection2.textDirection : 'text-center'" v-html="pageData.extraSection2.content"></div>
          <div class="w-full overflow-auto">
            <table class="min-w-full overflow-x-scroll rounded-lg">
              <thead>
                <tr class="h-10 bg-slate-300">
                  <th class="border border-slate-100"></th>
                  <th v-for="item in metalTableInfo" v-html="item.title" class="w-72 border border-slate-100"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="w-24 p-4 font-bold bg-slate-300 border border-slate-100 min-w-[150px]">Premiums</td>
                  <td v-for="item in metalTableInfo" v-html="item.premiums" class="px-4 py-2 border border-slate-100 min-w-[150px]"></td>
                </tr>
                <tr>
                  <td class="w-24 p-4 font-bold bg-slate-300 border border-slate-100 min-w-[150px]">Deductible</td>
                  <td v-for="item in metalTableInfo" v-html="item.deductible" class="px-4 py-2 border border-slate-100 min-w-[150px]"></td>
                </tr>
                <tr>
                  <td class="w-24 p-4 font-bold bg-slate-300 border border-slate-100 min-w-[150px]">Ideal For</td>
                  <td v-for="item in metalTableInfo" v-html="item.idealFor" class="px-4 py-2 border border-slate-100 min-w-[150px]"></td>
                </tr>
                <tr>
                  <td class="w-24 p-4 font-bold bg-slate-300 border border-slate-100 min-w-[150px]">Top Tip</td>
                  <td v-for="item in metalTableInfo" v-html="item.topTip" class="px-4 py-2 border border-slate-100 min-w-[150px]"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:NetworkInfo="{ carrierData }">
      <SectionInfo v-if="carrierData" :carrier-data="carrierData" />
    </template>
    <template v-slot:afterFinalSection="{ pageData }">
      <SectionInfo v-if="pageData.extraSection3" :carrier-data="pageData.extraSection3" />
    </template>
  </CarrierPlan>
</template>
