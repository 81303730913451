<script setup lang="ts">
import { RouterView, useRoute } from '@hfc/ui-core';
import { computed } from 'vue';
import DefaultPage from '@/shared/infrastructure/layouts/DefaultPage.vue';
import HomePage from '@/shared/infrastructure/layouts/HomePage.vue';
import vHighlight from '@/shared/infrastructure/composables/highlighter';

const route = useRoute();

const layouts = [
  {
    name: 'DefaultPage',
    component: DefaultPage
  },
  {
    name: 'HomePage',
    component: HomePage
  }
];

const layout = computed(() => layouts.find(layout => layout.name === route.meta.layout)?.component || DefaultPage);
</script>

<template>
  <component :is="layout" v-highlight>
    <RouterView />
  </component>
</template>
