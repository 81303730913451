<script setup lang="ts">
import SectionInfo from '@/page/infrastructure/components/carrier/SectionInfo.vue';
import { UseCarrierDataStore } from '@/page/infrastructure/composition/UseCarrierDataStore';
import CarrierPlan from '@/page/infrastructure/views/CarrierPlan.vue';
import { CarrierList } from '@hfc/ui-core';

const props = defineProps<{
  carrierId: CarrierList;
}>();

const { carrierData } = UseCarrierDataStore(props.carrierId);
</script>
<template>
  <CarrierPlan v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId">
    <template v-slot:NetworkInfo="{ carrierData, carrierId }">
      <SectionInfo v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId"></SectionInfo>
    </template>
  </CarrierPlan>
</template>
