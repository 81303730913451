import { FindPageUseCase } from '@/page/application/FindPageUseCase';
import { SettingsUseCase } from '@/page/application/GetSettingsUseCase';
import { ApplicationSetting } from '@/page/domain/ApplicationSetting';
import { PageMemory } from '@/page/infrastructure/dataSource/PageMemory';
import { CarrierMemory } from '@/page/infrastructure/dataSource/CarrierMemory';
import { CarrierPage } from '@/page/domain/CarrierPage';
import { GetCarrierDataUseCase } from '@/page/application/GetCarrierDataUseCase';
import { MetalPlanMemory } from '@/page/infrastructure/dataSource/MetalPlanMemory';
import { MetalPlanData } from '@/page/domain/MetalPlanData';
import { GetMetalPlanUseCase } from '@/page/application/GetMetalPlanUseCase';
import { MetalPlanPageSection } from '@/page/domain/MetalPlanPageSection';
import { MetalPlanPageMemory } from '@/page/infrastructure/dataSource/MetalPlanPageMemory';
import { GetMetalPlanPageUseCase } from '@/page/application/GetMetalPlanPageUseCase';
import { GlobalSetting, PHONE_TYPES } from '@/page/domain/GlobalSetting';
import { Page } from '@/page/domain/Page';
import { SettingsApi } from '@/page/infrastructure/dataSource/SettingsApi';
import { Ploc, SfApiResponse } from '@hfc/ui-core';

const repository = new PageMemory();
const findPage = new FindPageUseCase(repository);

const settingsApi = new SettingsApi();
const getGlobalSettings = new SettingsUseCase(settingsApi);

const carrierRepository = new CarrierMemory();
const getCarrierData = new GetCarrierDataUseCase(carrierRepository);

const metalPlanApi = new MetalPlanMemory();
const getMetalPlans = new GetMetalPlanUseCase(metalPlanApi);

const metalPlanPageApi = new MetalPlanPageMemory();
const getMetalPagePlans = new GetMetalPlanPageUseCase(metalPlanPageApi);

export interface PageStateInterface {
  currentPage: Page | null
  currentPageLoaded: boolean
  globalSettings: GlobalSetting | null
  appsEnabled: Array<ApplicationSetting>,
  phoneForCurrentRoute: string,
  officePhoneNumber: string,
  loadingGlobalSettings: boolean
  metalPlans: Array<MetalPlanData>
}

const initialState: PageStateInterface = {
  currentPage: null,
  currentPageLoaded: false,
  globalSettings: null,
  appsEnabled: [],
  phoneForCurrentRoute: '',
  officePhoneNumber: '',
  loadingGlobalSettings: false,
  metalPlans: []
};

export class PageStore extends Ploc<PageStateInterface> {

  public constructor () {
    super(initialState);
    this.getGlobalSettings();
  }

  public async getGlobalSettings (): Promise<void> {
    this.changeState({ ...this.state, loadingGlobalSettings: true });
    const response = await getGlobalSettings.run();
    if (response.success && response.data) {
      this.changeState({ ...this.state, globalSettings: response.data });
    }
    this.setOfficePhoneNumber();
    this.setEnabledApplications();
    this.changeState({ ...this.state, loadingGlobalSettings: false });
  }

  public setEnabledApplications (): void {
    if (this.state.globalSettings !== null) {
      this.changeState({ ...this.state, loadingGlobalSettings: true });
      const enabledApplications = Object.entries(this.state.globalSettings.applications).filter(([_, app]) => app.enabled).map(([key, app]) => ({ ...app, key }));
      this.changeState({ ...this.state, appsEnabled: enabledApplications });
      this.changeState({ ...this.state, loadingGlobalSettings: false });
    }
  }

  public setPhoneEnabledForRoute (route: string): void {
    if (this.state.globalSettings !== null) {
      this.changeState({ ...this.state, loadingGlobalSettings: true });
      const phoneList = Object.values(this.state.globalSettings.phone);
      const enabledPhone = phoneList.find(phone => phone.routesEnabled.find(regex => route.match(regex)));
      this.changeState({ ...this.state, phoneForCurrentRoute: enabledPhone ? enabledPhone.number : '' });
      this.changeState({ ...this.state, loadingGlobalSettings: false });
    }
  }

  public setOfficePhoneNumber (): void {
    if (this.state.globalSettings !== null) {
      this.changeState({ ...this.state, loadingGlobalSettings: true });
      this.changeState({ ...this.state, officePhoneNumber: this.state.globalSettings.phone[PHONE_TYPES.OFFICE].number });
      this.changeState({ ...this.state, loadingGlobalSettings: false });
    }
  }

  public changeLoadedState (): void {
    this.changeState({ ...this.state, currentPageLoaded: !this.state.currentPageLoaded });
  }

  public async getBySlug (slug: string): Promise<void> {
    this.changeState({ ...this.state, currentPageLoaded: false });
    const response = await findPage.run(slug);
    if (response.success && response.data) {
      this.changeState({ ...this.state, currentPage: response.data });
    }
    this.changeState({ ...this.state, currentPageLoaded: true });
  }

  public async getCarrierData (carrierId: string): Promise<SfApiResponse<Partial<CarrierPage>>> {
    this.changeState({ ...this.state, currentPageLoaded: false });
    const response = await getCarrierData.run(carrierId);
    this.changeState({ ...this.state, currentPageLoaded: true });

    return response;
  }

  public async getMetalPlans (): Promise<void> {
    this.changeState({ ...this.state, currentPageLoaded: false });
    const response = await getMetalPlans.run();
    if (response.success && response.data) {
      this.changeState({ ...this.state, metalPlans: response.data });
    }
    this.changeState({ ...this.state, currentPageLoaded: true });
  }

  public async getMetalPlanPageSections (planName: string): Promise<SfApiResponse<Partial<MetalPlanPageSection>>> {
    this.changeState({ ...this.state, currentPageLoaded: false });
    const response = await getMetalPagePlans.run(planName);
    this.changeState({ ...this.state, currentPageLoaded: true });

    return response;
  }

}
