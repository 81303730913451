<script lang="ts" setup>
import { inject } from 'vue';
import { PrimaryButton, usePlocState } from '@hfc/ui-core';
import QuestionsAndPhotos from '@/page/infrastructure/components/QuestionsAndPhotos.vue';
import ServicesSection from '@/page/infrastructure/components/ServicesSection.ce.vue';
import CertifiedBanner from '@/page/infrastructure/components/CertifiedBanner.vue';
import BannerSection from '@/page/infrastructure/components/BannerSection.vue';
import LicenseContent from '@/page/infrastructure/components/LicenseContent.vue';
import { PageStore } from '@/page/infrastructure/store/PageStore';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import DownArrowCustom from '@/page/infrastructure/components/DownArrowCustom.vue';

const { getQuoteUrl, getBaseUrl, getCdnUrl } = UseUrlHelper();
const pageStore = inject<PageStore>('PageStore') as PageStore;
const pageState = usePlocState(pageStore);

const sections = [
  {
    id: 'individuals-and-families',
    title: 'Individuals and Families',
    images: ['/assets/img/faq/girl_with_phone.webp', '/assets/img/faq/couple_with_baby.webp'],
    questions: [
      {
        answer: 'Do I have to get health insurance?',
        response: `Starting January 1, 2013, most American citizens and legal residents must get qualified health insurance.<br><br>
        On December 22, 2017, President Donald Trump signed into law the Tax Cuts and Jobs
        Act of 2017, which eliminated the <a href="${getBaseUrl('/covered-california/health-care-mandate')}">health care mandate</a> nationally starting January 1,
        2019. However, the state of California adopted their own health insurance requirement
        starting January 1, 2020.<br><br>
        Though health insurance coverage is not mandated in most of the country it has been
        required in California since January 1, 2013 to the present with the exception of 2019.<br><br>
        Currently, health care is mandated in the state of California and there is a penalty for
        not being insured.`,
      },
      {
        answer: 'Do I qualify for a subsidy?',
        response: `Generally, people will qualify for a <a href="${getBaseUrl('/covered-california/subsidy-tax-credits')}">government subsidy</a> if their gross family income is no more than 400% of the <a href="${getBaseUrl('/federal-poverty-level')}">Federal Poverty Level (“FPL”)</a>. However, due to increased
        government assistance, many Californians are receiving some level of discounts all the
        way up to around 600% of FPL.`,
      },
      {
        answer: 'How do I calculate my subsidy?',
        response: `Complete this online form to <a href="${getBaseUrl('/individual-and-family-quote')}">calculate your subsidy</a>. By entering basic information, you
        can calculate your subsidy and get quotes on health plans in as little as 30 seconds.
        You may also want to check out the <a href="${getBaseUrl('/covered-california/income-limits')}">Covered California Income Limits Chart</a> to see
        where you would land as far as eligibility for Medi-Cal, Covered California, and Silver
        Enhanced Cost Sharing Plans (CSR).`,
      },
      {
        answer: 'What if I’m not currently insured?',
        response: `Some Californians choose to go without medical coverage and just pay the penalty. If you are a higher income earner and you’re healthy, that may be a way of saving money.<br><br>
        Here’s the problem. If you do end up needing expensive care (hospitalization, surgery, expensive <a href="${getBaseUrl('/covered-california/prescription-drug-benefits')}">medications</a>, etc.) and you do not have any kind of health insurance, then you will have to pay the full price for the services you receive. Potentially, that could be tens or even hundreds of thousands of dollars.<br><br>
        Instead of going uninsured, we would recommend getting the cheapest plan available. That way you won’t be penalized, you will have <a href="${getBaseUrl('/affordable-care-act/preventive-care')}">free preventive care</a>, and you will have an out of pocket maximum (OOP Max). The OOP Max limits your exposure. It is the limit to how high your bill could get. In most cases, even the cheapest plan would keep your costs from going over $10,000. Click here for more information about <a href="${getBaseUrl('/blog/understanding-insurance-costs')}">understanding insurance costs</a> including the OOP Max.`,
      },
    ]
  },
  {
    id: 'general-questions',
    title: 'General Questions',
    images: ['/assets/img/faq/woman_and_doctor.webp'],
    questions: [
      {
        answer: 'Can I get health insurance now? When can I apply?',
        response: `You can apply during the <a href="${getBaseUrl('/covered-california-enrollment/open-enrollment')}">Open Enrollment Period</a> (November – January) without a
        <a href="${getBaseUrl('/covered-california/life-events')}">Qualifying Life Event (QLE)</a>. If you have a QLE, you can get health insurance at any
        time. Either way, your coverage could start as soon as the 1st of the next month.`,
      },
      {
        answer: 'I might be on a grandfathered plan. What should I do?',
        response: `You should <a href="${getBaseUrl('/individual-and-family-quote')}">get a quote</a> and compare pricing and benefits. If you get off of your
        grandfathered plan, you can never get back on. However, many <a href="${getBaseUrl('/covered-california/grandfathered-plans')}">grandfathered plans</a>
        have had major price increases. So, often it will save you money if you switch from the
        grandfathered plan to a <a href="${getBaseUrl('/covered-california/plans')}">Covered California plan</a>.<br><br>
        Also, you may want to speak to an <a href="${getBaseUrl('/covered-california/brokers/agents-vs-brokers')}">agent</a> about this by calling <a class="font-bold" href="tel:1-877-752-4737">1-877-752-4737</a>. When
        you call, make sure you have the pricing and benefits of your grandfathered coverage.
        The agent will be able to help you figure out whether the grandfathered plan or a
        Covered California plan is going to give you the best <a target="blank" href="${'https://www.coveredca.com/support/before-you-buy/cost-of-health-insurance/'}">pricing</a> and benefits.`,
      },
    ],
  },
  {
    id: 'medicare',
    title: 'Medicare',
    images: ['/assets/img/faq/couple_standing.webp', '/assets/img/faq/couple_reading.webp'],
    questions: [
      {
        answer: 'What is the part A Deductible?',
        response: '$1632 for 2024.',
      },
      {
        answer: 'What is the part B Deductible?',
        response: '$240 for 2024.',
      },
      {
        answer: 'What is the part B Coinsurance?',
        response: '20%.',
      },
      {
        answer: 'What is a coinsurance?',
        response: `Coinsurance is a fancy word for percentage. So, you pay a certain percentage like 20%
        and Medicare or another insurance covers the remaining 80% as an example.`,
      },
      {
        answer: 'Can I keep Covered CA and skip Medicare?',
        response: `Most likely not. If you are a legal resident or citizen and have 10 years or more
        cumulative work history in the United States, then you should be "entitled" to <a href="${getBaseUrl('/medicare/original-medicare')}">Medicare
        Part A</a> and therefore you would be disqualified from Covered California.<br><br>
        You would also be disqualified from any individual or <a href="${getBaseUrl('/family-health-plans')}">family Health Insurance</a> in the
        United States. You might be able to delay enrollment in Medicare if you have Health
        Insurance through your employer, but that can be tricky. Call <a class="font-bold" href="tel:1-800-352-4784">1-800-352-4784</a> for more
        details.`,
      },
      {
        answer: 'Can I keep my group health insurance through my employer? Or my spouse\'s employer?',
        response: `Possibly! Confirm with the employer that there are 20 or more employees and that the
        coverage meets <a href="${getBaseUrl('/medicare/eligibility')}">Medicare's requirements</a> so that it is "Creditable". If both of those are
        true, you can delay enrollment in Part B, still get part A, and keep your employer
        coverage as primary.<br><br>
        Make sure to keep your Creditable Coverage Letters which you will receive each year
        from your employer. Without those letters you might face a <a target="blank" href="${'https://www.medicare.gov/basics/costs/medicare-costs/avoid-penalties'}">penalty</a> when you retire and
        switch to Medicare.`,
      },
      {
        answer: 'Can I have both group coverage and Medicare?',
        response: `Somewhat, yes. You can have Part A only and coverage through your employer. This is
        the most common scenario. You can get part B also and keep your employer coverage,
        but this is double coverage and not necessary for most people.<br><br>
        You cannot pick up a <a href="${getBaseUrl('/medicare/prescription-drugs-part-d')}">Part D</a>, Part C, or <a href="${getBaseUrl('/medicare/supplemental-plans')}">Supplement</a> and keep your employer coverage.
        They would not work together and could cancel each other out.`,
      },
      {
        answer: 'What is the cheapest option?',
        response: `<a href="${getBaseUrl('/medicare/medicare-advantage-part-c')}">Part C Medicare Advantage Plans</a> are often the least expensive. They usually include prescription drug coverage.<br><br>
        However, if the Advantage Plans in your area are all expensive, some of the cheaper Supplement and Drug Plans (Part D) might give you a lower monthly premium.`,
      }
    ]
  },
  {
    id: 'small-business',
    title: 'Small Business',
    images: ['/assets/img/faq/small_business.png'],
    questions: [
      {
        answer: 'Am I required to offer health insurance to my employees?',
        response: `You are not required to offer <a href="${getBaseUrl('/group-health-insurance')}"> small business health insurance</a> to your employees if you employ under 50 full-time equivalent employees.<br><br>
        A full-time employee is one who works a minimum of 30 hours a week. However, part-time employees who collectively work a minimum of 130 hours a month would be counted as equivalent to 1 full time employee. For every 130 hours of part time employee work per month, you would count that as 1 full time equivalent employee.<br><br>
        For example, let’s say you have 10 part-time employees who work 858 hours a month. Divide those 858 hours by 130. That would translate to 6.6 full-time equivalent workers.<br><br>
        The sum of your full-time employees and full-time equivalent employees would determine whether you qualify for small business (1-99 employees) or large business coverage (100+ employees). And, if you have over 50 full-time/equivalent employees, then you would be required to offer health insurance to your employees.`
      },
      {
        answer: 'Is there government funding for small business health insurance?',
        response: `Yes, this is a provision of Healthcare Reform and the <a href="${getBaseUrl('/group-health-insurance/healthcare-reform')}">Affordable Care Act</a> that was signed into law by President Barack Obama on March 23, 2010.<br><br>
        Small groups of 24 or fewer employees who enroll on the California State Exchange through <a href="${getBaseUrl('/covered-ca-small-business')}">Covered California Small Business (CCSB)</a> may qualify for a small business tax credit for up to two years. The tax credit is 50% of premium for "For-Profit Entities" and 35% of premium for "Non-Profit Entities”.`
      },
      {
        answer: 'Does my small business qualify for a tax credit?',
        response: `To qualify for a small group tax credit, the following must be true of your business:<br><br>
        <ul class="ml-10 list-disc">
        <li>The annual average wage must be less than $62,000.</li>
        <li>At least 50% of the employee-only premium must be paid by the employer.</li>
        <li>Group insurance must be purchased through the California Exchange Marketplace (CCSB).</li>
        </ul><br>
        Click <a target="blank" href="${'https://www.irs.gov/pub/irs-utl/3_simple_steps.pdf'}">Small Business Health Care Tax Credit</a> for more details.`
      },
      {
        answer: 'What if we already have group health insurance?',
        response: `If you qualify for the tax credit, then most likely it would be to your advantage to switch
        to a small business plan through Covered California.<br><br>
        The details matter, so you may want to discuss this with a small business agent by
        calling <a class="font-bold" href="tel:707-526-0448">707-526-0448</a>.<br><br>
        For those on a grandfathered plan, be aware that if you switch, you will not be able to
        go back to that plan. However, there have been large pricing increases on many
        grandfathered plans, so it still may make sense to switch to a CCSB plan or another off-
        exchange plan.<br><br>
        <a target="blank" href="${'https://www.quotit.net/Quotit/Apps/EproV2/ZipEntry?license_no=YJD5P4&brokerIdHash=938211279'}">Click here</a> to get quotes on small business plans (1-99 employees). For groups of 100
        or more, please call <a class="font-bold" href="tel:707-526-0448">707-526-0448</a> for specialized pricing.`
      }
    ]
  }
];

const scrollTo = (elementId: string): void => {
  const el = document.querySelector(`#${elementId}`) as HTMLElement;

  if (el) {
    scroll({ top: el.offsetTop - 103, behavior: 'smooth' });
  }
};
</script>

<template>
  <div class="flex flex-1 flex-col bg-white items-center">

    <BannerSection
      content-class="lg:h-[354px] pt-10 lg:pt-0"
      background-class="bg-gradient-to-r from-blue-900 to-blue-500 overflow-hidden"
      text-class="flex-col justify-center lg:w-4/5 space-y-8 lg:space-y-4"
      image-class="justify-end"
    >
      <template #text>
        <div class="text-white text-3xl font-semibold">Frequently Asked Questions</div>
        <div class="text-white font-normal">Find FAQs below about Healthcare Reform and the Affordable Care Act.
          View questions and answers related to California Health Insurance, Covered
          California, CCSB and the ACA.</div>
        <div class="flex flex-col lg:flex-row space-y-4 lg:space-y-0">
          <DownArrowCustom class="hidden xl:flex absolute transform scale-x-[-1] -ml-14 mt-5" />
          <button v-for="(section, index) in sections"
            :key="index"
            @click="scrollTo(section.id)"
            class="rounded bg-white hover:bg-yellow text-blue-500 font-semibold lg:mr-4 px-4 py-2">
            {{ section.title }}
          </button>
        </div>
      </template>
      <template #image>
        <img class="flex h-[384px] -mr-20 -mb-20 lg:mr-0 lg:mb-0 md:absolute lg:right-[-150px] xl:right-[-250px] lg:bottom-[-50px]" :src="getCdnUrl('/assets/img/faq/hfc_icon.webp')">
      </template>
    </BannerSection>

    <div class="flex w-full justify-end lg:-mt-10 mr-4 z-10 lg:max-w-[1920px]">
      <CertifiedBanner custom-class="w-full lg:w-auto lg:h-16 lg:rounded-lg" />
    </div>

    <div class="flex flex-col max-w-7xl mx-auto space-y-10 py-10 px-6">
      <QuestionsAndPhotos v-for="(section, index) in sections" :id="section.id" :title="section.title" :questions="section.questions" :images="section.images" :content-direction="index % 2 === 0 ? 'left' : 'right'" />

      <ServicesSection />

      <LicenseContent />
    </div>

    <BannerSection
      content-class="lg:h-[300px] pt-10 lg:pt-0"
      background-class="bg-gradient-to-r from-blue-900 to-blue-500"
      text-class="flex-col flex-1 justify-center space-y-4"
      image-class="flex-1"
      content-direction="left"
    >
      <template #text>
        <div class="text-white text-3xl font-semibold">Find Affordable Health Insurance</div>
        <div class="text-white font-normal">We make applying for health insurance feasible, fast and free! And that's the best part? We have a wide variety of plans. Getting a quote is very easy. Give us 30 seconds, and we we'll give tou the <span class="font-semibold">most affordable rates available from multiple providers</span> in your area. When you use us, you can rest assured that you're getting the best prices and the most streamline process.</div>
        <div class="flex">
          <PrimaryButton :href="getQuoteUrl()">
            Shop & compare <hfc-icon icon="arrow-right" class="text-white ml-1 md:ml-5" />
          </PrimaryButton>
        </div>
      </template>
      <template #image>
        <img class="lg:absolute -top-[24px] lg:h-[323px]" :src="getCdnUrl('/assets/img/faq/dad_and_girl.png')">
      </template>
    </BannerSection>

    <get-quote-banner class="w-full" v-if="pageState.appsEnabled" :applications="JSON.stringify(pageState.appsEnabled)"></get-quote-banner>
  </div>
</template>
